import { WagmiStore } from '@interfaces/types';
import { createContext, useContext } from 'react';
import React, { useState, useEffect, ReactNode } from 'react';

interface MyContextProps {
  wagmiStore: WagmiStore | null;
}

interface MyContextProviderProps {
  children: ReactNode;
}

const WalletContext = createContext<MyContextProps | undefined>(undefined);

export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWalletContext must be used within a WalletContextProvider');
  }
  return context;
};

const WalletContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {

  const [wagmiStore, setWagmiStore] = useState<WagmiStore | null>(() => {
    const storedWagmiData = localStorage.getItem('wagmi.store');

    try {
      return storedWagmiData ? JSON.parse(storedWagmiData) : null;
    } catch (error) {
      console.error('Error parsing stored wagmi.store data:', error);
      return null;
    }
  });

  return (
    <WalletContext.Provider value={{ wagmiStore }}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletContextProvider;