import React, { useEffect, useState } from 'react';
import './ShopItemCard.scss';
import IncrementDecrementInput from './IncrementDecrementInput';
import Icon_Deck_Common from '@assets/Icon_Deck_Common.webp';
import Icon_Deck_Uncommon from '@assets/Icon_Deck_Uncommon.webp';
import Icon_Deck_Rare from '@assets/Icon_Deck_Rare.webp';
import Icon_Chest from '@assets/Icon_Chest.webp';
import OffTag from '@assets/OffTag.webp';
import '../fonts.scss';
import { ShopItem } from '@interfaces/types';

interface ShopItemCardProps {
  item: ShopItem;
  enableAddToBasket: boolean;
  handleAddToBasket: (item: ShopItem) => void;
  incrementItem: (id: string) => void;
  decrementItem: (id: string) => void;
}

const ShopItemCard: React.FC<ShopItemCardProps> = (props: ShopItemCardProps) => {
  const [isAddedToBasket, setIsAddedToBasket] = useState(false);
  const [count, setCount] = useState(1);

  const handleCountChange = (newCount: number) => {
    setCount(newCount);

    if (newCount === 0) {
      setIsAddedToBasket(false);
    }
  };

  const getImage = () => {
    switch (props.item.title) {
      case "Common Pack":
        return Icon_Deck_Common;
      case "Uncommon Pack":
        return Icon_Deck_Uncommon;
      case "Rare Pack":
        return Icon_Deck_Rare;
      case "Beginner Bundle":
      case "Basic Bundle":
      case "Elite Bundle":
      case "Masters Bundle":
        return Icon_Chest;
      default:
        return Icon_Deck_Common;
    }
  }

  const handleAddToBasket = () => {
    props.handleAddToBasket(props.item);
    setIsAddedToBasket(true);
  }
  const image = getImage()

  function calculateCurrentPrice(originalPrice: number, discountPercentage: number): number {
    if (originalPrice < 0 || discountPercentage < 0 || discountPercentage > 100) {
      throw new Error("Invalid input. Original price and discount percentage must be non-negative, and discount percentage must be between 0 and 100.");
    }

    const discountAmount = (originalPrice * discountPercentage) / 100;
    const currentPrice = originalPrice - discountAmount;
    return currentPrice;
  }

  return (
    <div className="shop-item-card-container">
      <div className="shop-item-card-top-container">
        <label className='shop-item-card-title'>{props.item.title}</label>
        {props.item.discountInPercentage !== null && <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={OffTag} alt='OffTag' className='shop-item-card-off-tag' />
          <label className='shop-item-card-title' style={{ color: '#FDA92B' }}>{props.item.discountInPercentage}% OFF</label>
        </div>}
      </div>
      <div className="shop-item-card-content-container">
        <div className="shop-item-card-left-container">
          <img src={image} alt={`${props.item.title}`} />
        </div>
        <div className="shop-item-card-right-container">
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: '1rem' }}>
            {props.item.description.map(x => (<label className='shop-item-card-content'>{x}</label>))}

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '100%', paddingLeft: '1rem', height: '100%' }}>
            {props.item.discountInPercentage === null && <label style={{ fontFamily: 'PassionOneRegular', fontSize: '32px', width: '100%', marginTop: 'auto' }}>${props.item.price}</label>}
            {props.item.discountInPercentage !== null && <div style={{ alignItems: 'center', width: '100%', justifyContent: 'baseLine' }}>
              <label style={{ color: '#BABABA', fontFamily: 'PassionOneRegular', fontSize: '21px', marginRight: '10px', textDecoration: 'line-through' }}>${props.item.price}</label>
              <label style={{ color: '#FDA92B', fontFamily: 'PassionOneRegular', fontSize: '33px' }}>${calculateCurrentPrice(props.item.price, props.item.discountInPercentage)}</label>
            </div>}

            {/* {props.enableAddToBasket ? (
              isAddedToBasket ? (
                // <IncrementDecrementInput
                //   onCountChange={handleCountChange}
                //   incrementItem={props.incrementItem}
                //   decrementItem={props.decrementItem}
                //   item={props.item}
                // />
              ) : (
                <button
                  onClick={handleAddToBasket} className='add-button'
                >
                  ADD
                </button>
              )
            ) : (
              <IncrementDecrementInput
                onCountChange={handleCountChange}
                incrementItem={props.incrementItem}
                decrementItem={props.decrementItem}
                item={props.item}
              />
            )} */}
          </div>

        </div>
      </div>
    </div>
  );
};

export default ShopItemCard;
