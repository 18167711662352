import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { useAuth } from '../AuthContext';
import { kkPvp, pioneerQuest } from '@api/account';
import { KKPvpRanking, PioneerQuest, PioneerQuestResponse } from '@interfaces/types';
import './Events.scss';
import PioneerClaimsCard from '@components/PioneerClaimsCard';
import PaginatedTable from '@components/PaginatedTable';
import useMediaQuery from '@hooks/useMediaQuery';
import Loader from '@components/Loader';

function getCurrentMonth(): string {
    const currentDate = new Date();
    return currentDate.toLocaleString('default', { month: 'long' });
  }
  
  function getCurrentYear(): number {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

const Events: React.FC = () => {

    const isForDesktop = useMediaQuery();

    const pioneerQuestsHardcoded: PioneerQuest[] = [
        { month: "January", prize: "1x Uncommon Pack", quest: "Complete 20 Ranked PvP Matches", progress: "" },
        { month: "January", prize: "1x Rare Pack", quest: "Complete 50 Ranked PvP Matches", progress: "" },
        { month: "February", prize: "1x Uncommon Pack", quest: "Complete 20 Dungeons", progress: "" },
        { month: "February", prize: "1x Rare Pack", quest: "Complete 50 Dungeons", progress: "" },
        { month: "March", prize: "1x Uncommon Pack", quest: "Buy 20 Auctions", progress: "" },
        { month: "March", prize: "1x Rare Pack", quest: "Buy 50 Auctions", progress: "" },
        { month: "April", prize: "1x Uncommon Pack", quest: "Complete 20 Catacombs Dungeons", progress: "" },
        { month: "April", prize: "1x Rare Pack", quest: "Complete 50 Catacombs Dungeons", progress: "" },
        { month: "May", prize: "1x Uncommon Pack", quest: "Complete 20 Dungeons", progress: "" },
        { month: "May", prize: "1x Rare Pack", quest: "Complete 50 Dungeons", progress: "" },
        { month: "June", prize: "1x Uncommon Pack", quest: "Win 20 battles with any dynamic in deck", progress: "" },
        { month: "June", prize: "1x Rare Pack", quest: "Win 50 battles with any dynamic in deck", progress: "" },
        { month: "July", prize: "1x Uncommon Pack", quest: "Complete 20 Ranked PvP Matches", progress: "" },
        { month: "July", prize: "1x Rare Pack", quest: "Complete 50 Ranked PvP Matches", progress: "" },
        { month: "August", prize: "1x Uncommon Pack", quest: "Complete 20 Dungeons", progress: "" },
        { month: "August", prize: "1x Rare Pack", quest: "Complete 20 Dungeons", progress: "" },
        { month: "September", prize: "1x Uncommon Pack", quest: "Buy 50 Auctions", progress: "" },
        { month: "September", prize: "1x Rare Pack", quest: "Buy 20 Auctions", progress: "" },
        { month: "October", prize: "1x Uncommon Pack", quest: "Complete 20 Catacombs Dungeons", progress: "" },
        { month: "October", prize: "1x Rare Pack", quest: "Complete 50 Catacombs Dungeons", progress: "" },
        { month: "November", prize: "1x Uncommon Pack", quest: "Complete 20 Dungeons", progress: "" },
        { month: "November", prize: "1x Rare Pack", quest: "Complete 50 Dungeons", progress: "" },
        { month: "December", prize: "1x Uncommon Pack", quest: "Win 20 battles with any dynamic in deck", progress: "" },
        { month: "December", prize: "1x Rare Pack", quest: "Win 50 battles with any dynamic in deck", progress: "" },
    ];

    const { accountData } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pioneerQuests, setPioneerQuests] = useState<PioneerQuest[]>();
    const [kkPvpRanking, setKkPvpRanking] = useState<KKPvpRanking[]>([]);

    useEffect(() => {
        const getPioneerQuests = async () => {
            try {
                setIsLoading(true);
                const response = await pioneerQuest(accountData ? accountData.id : "") as unknown as PioneerQuestResponse[];

                pioneerQuestsHardcoded.forEach((quest) => {
                    const matchingProgress = response.find((progress) => progress.month === quest.month);
                    if (matchingProgress) {
                        quest.progress = matchingProgress.progress ? matchingProgress.progress : "0";
                    }
                });
                setPioneerQuests(pioneerQuestsHardcoded)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setIsLoading(false);
            }
        };

        const getkkPvp = async () => {
            try {
                setIsLoading(true);
                const response = await kkPvp();
                setKkPvpRanking(response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setIsLoading(false);
            }
        };
        getPioneerQuests();
        getkkPvp();
    }, []);

    const playerCurrentRank = () => {
        const player = kkPvpRanking.find(x => accountData?.displayname === x.name)

        if (player) {
            return `Your current position is ${player.position} with ${player.score} points. Your prize will be ${player.prize} KKT!`
        }
    }

    const renderPVPPrizes = () => (<div className='event-container' style={{ justifyContent: "flex-start" }}>
        <p className='event-title'>Monthly PvP Prizes - {getCurrentMonth()} {getCurrentYear()}</p>
        <p className='event-text'>
            Ranked PvP requires a full deck of 25 cards. You can mix races.<br /><br />
            Monthly rewards are now here with KKT to be won.<br /><br />
        </p>
        <p className='event-text' style={{color: "yellow"}}><b>{accountData && playerCurrentRank()}</b></p>
        <div className='paginated-table-container'>
            <div style={{ width: isForDesktop ? "70%" : "100%", marginTop: "25px" }}>
                <PaginatedTable data={kkPvpRanking} itemsPerPage={10} />
            </div>
        </div>
    </div>)

    const renderPioneerClaimsForDesktop = () => (<div className='event-container'>
        <p className='event-title'>Pioneer Claims</p>

        <div style={{ display: "flex" }}>
            <p className='event-text'>
                The Pioneer is the Kingdom Karnage Multiverse Founders Token (MFT), it is an epic character in game, and a great way to get extra characters.<br /><br />
                Each month a new quest is available to anyone who has a Pioneer in their inventory. <br /><br />
                Complete the quest any time during that month, and you will receive the relevant reward on the 1st of the next month automatically. <br />
                Your Pioneer cannot be in the auction house when rewards are sent out or you will not be eligible to receive the rewards.  <br /><br />
            </p>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <div className='pioneer-cards-wrapper'>
                {!isLoading && pioneerQuests && pioneerQuests.map((quest, index) => <PioneerClaimsCard key={index} quest={quest} />)}
            </div>
        </div>

    </div>)

    const renderPioneerClaimsForMobile = () => (<div className='event-container'>
        <p className='event-title'>Pioneer Claims</p>

        <div className='pioneer-subdiv'>
            <div style={{ marginTop: "2%" }}>
                <p className='event-text'>
                    The Pioneer is the Kingdom Karnage Multiverse Founders Token (MFT), it is an epic character in game, and a great way to get extra characters.<br /><br />
                    Each month a new quest is available to anyone who has a Pioneer in their inventory. <br /><br />
                    Complete the quest any time during that month, and you will receive the relevant reward on the 1st of the next month automatically. <br />
                    Your Pioneer cannot be in the auction house when rewards are sent out or you will not be eligible to receive the rewards .  <br /><br />
                </p>
            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
            {!isLoading && pioneerQuests && pioneerQuests.map(quest => <PioneerClaimsCard quest={quest} />)}
        </div>
    </div>)

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >Events</label>
            </div>
            {isLoading ? <Loader isLoading={isLoading} /> : <div className='events-wrapper'>
                {renderPVPPrizes()}

                {isForDesktop ? renderPioneerClaimsForDesktop() : renderPioneerClaimsForMobile()}
            </div>}
        </div>
    );
};

export default BackgroundTemplate(Events);
