import React, { } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import isotopicLogoWhite from '@assets/isotopicLogoWhite.png';
import seddona2 from '@assets/seddona2.png';
import './Events.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import Accordion from '@components/Accordion';
import IconLink from '@components/IconLink';
import { faBook, faShop, faX } from '@fortawesome/free-solid-svg-icons'
import { faAndroid, faApple, faDiscord, faFacebook, faInstagram, faMedium, faReddit, faSteam, faTelegram, faTiktok, faTwitch, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from '@mui/material';

const Links: React.FC = () => {
    const isForDesktop = useMediaQuery();

    const generateAccordionItems = () => {
        return [
            {
                title: (
                    <div className='accordion-content-div'>
                        <label className='accordion-title-label'>Media</label>
                    </div>
                ),
                content: <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faDiscord} link='https://discord.com/invite/ufkg8bCCnr' text='Discord' />
                    <IconLink icon={faFacebook} link='https://www.facebook.com/KepithorStudios/' text='Facebook' />
                    <IconLink icon={faInstagram} link='https://www.instagram.com/kingdomkarnage/' text='Instagram' />
                    <IconLink icon={faMedium} link='https://medium.com/kingdomkarnage' text='Medium' />
                    <IconLink icon={faReddit} link='https://www.reddit.com/r/Kingdom_Karnage/' text='Reddit' />
                    <IconLink icon={faTelegram} link='https://t.me/Kepithor' text='Telegram' />
                    <IconLink icon={faTiktok} link='https://www.tiktok.com/@kingdom_karnage' text='Tiktok' />
                    <IconLink icon={faTwitch} link='https://www.twitch.tv/kingdomkarnage' text='Twitch' />
                    <IconLink icon={faX} link='https://twitter.com/kingdom_karnage/' text='X/Twitter' />
                    <IconLink icon={faYoutube} link='https://www.youtube.com/channel/UCNZonwa7YrmLLWwLEmoDT-g' text='Youtube' />
                </div>
            },
            {
                title: (
                    <div className='accordion-content-div'>
                        <label className='accordion-title-label'>Play Kingdom Karnage</label>
                    </div>
                ),
                content: <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faSteam} link='https://store.steampowered.com/app/1305000/Kingdom_Karnage/' text='Steam' />
                    <IconLink icon={faAndroid} link='https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnage' text='Android' />
                    <IconLink icon={faApple} link='https://apps.apple.com/us/app/kingdom-karnage/id6443679225' text='Apple' />
                    <Link href={"https://isotopic.io/game/?game=Kingdom_Karnage"} style={{ display: "flex", flexDirection: "column", margin: "20px", width: "50px", justifyContent: "center", alignItems: "center" }} target="_blank">
                        <img src={isotopicLogoWhite} color='white' style={{ height: "48px", width: "48px" }} />
                        <label>Isotopic</label>
                    </Link>
                    <Link href={"https://www.seddona.com/games/KingdomKarnage"} style={{ display: "flex", flexDirection: "column", margin: "20px", width: "50px", justifyContent: "center", alignItems: "center" }} target="_blank">
                        <img src={seddona2} color='white' style={{ height: "48px", width: "48px" }} />
                        <label>Seddona</label>
                    </Link>
                    <IconLink icon={faBook} link='https://kepithorstudios.gitbook.io/kingdom-karnage/' text='Guide' />

                </div>
            },
            {
                title: (
                    <div className='accordion-content-div'>
                        <label className='accordion-title-label'>Play Kingdom Karnage: Clicker</label>
                    </div>
                ),
                content: <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faAndroid} link='https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnageClicker' text='Android' />
                    <IconLink icon={faApple} link='https://testflight.apple.com/join/y2bNjrkT' text='Apple' />
                </div>
            },
            {
                title: (
                    <div className='accordion-content-div'>
                        <label className='accordion-title-label'>Play Kingdom Karnage: Heroes</label>
                    </div>
                ),
                content: <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faAndroid} link='https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnageHeroes' text='Android' />
                    <IconLink icon={faApple} link='https://testflight.apple.com/join/vlQkRXky' text='Apple' />
                    <IconLink icon={faBook} link='https://kepithorstudios.gitbook.io/kingdom-karnage-heroes/' text='Guide' />

                </div>
            },
            {
                title: (
                    <div className='accordion-content-div'>
                        <label className='accordion-title-label'>Useful Links</label>
                    </div>
                ),
                content: <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faTelegram} link='https://t.me/KingdomKarnage_MarketMonitor' text='Market' />
                    <IconLink icon={faTelegram} link='https://t.me/KKOverseer' text='Overseer' />
                </div>
            }
        ];
    };

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >Links</label>
            </div>
            <div className='account-accordion-wrapper'>
                <Accordion items={generateAccordionItems()} activeOne={0} />
            </div>
        </div>
    );
};

export default BackgroundTemplate(Links);
