import { updateKycStatus } from '@api/account';
import { Account } from '@interfaces/types';
import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../AuthContext';
import { toast } from 'react-toastify';

interface Props {
    onButtonClick: () => void;
    forwardedRef?: React.RefObject<HTMLButtonElement>;
}

const KycButtonWrapper: React.FC<Props> = ({ onButtonClick, forwardedRef }) => {
    const buttonRef = forwardedRef || useRef<HTMLButtonElement>(null);
    const { accountData, refreshAccountData } = useAuth();

    useEffect(() => {
        loadBlockpassWidget();
    }, [])

    const loadBlockpassWidget = () => {
        const blockpass = new (window as any).BlockpassKYCConnect(
            'kingdom_karnage_bbe3b',
            {
                refId: accountData?.id,
            }
        )

        blockpass.startKYCConnect()

        blockpass.on('KYCConnectSuccess', () => {
            toast.success("Data have been sent.")
        })

        blockpass.on("KYCConnectCancel", async () => {
            if (accountData) {
                await updateKycStatus(accountData.id, accountData.session, accountData.hash, "");
                refreshAccountData();
                toast.error("Kyc canceled.")
            }
        });

        blockpass.on("KYCConnectClose", () => {
            toast.success("Kyc finished.")
        });
    }

    const handleClick = () => {
        onButtonClick();
    };

    return (
        <Button ref={buttonRef} id="blockpass-kyc-connect" style={{ display: "none" }} onClick={handleClick}>
            Verify with Blockpass
        </Button>
    );
};

export default KycButtonWrapper;
