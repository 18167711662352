import { FC, useEffect, useState } from 'react';
import './HorizontalScroller.scss';
import { CharacterData, Coffins, MidasData } from '@interfaces/types';
import MidasList from './MidasList';
import { Box, Button } from '@mui/material';
import vampireCoffin from '@assets/VampireCoffin.gif';
import { useAuth } from '../AuthContext';
import { linkedWallets, nftMint } from '../api/account';

interface CoffinsHorizontalScrollerProps {
    items: Coffins[];
    linkedWallet: string;
    onSuccessChange: (value: boolean) => void;
}

const CoffinsHorizontalScroller: FC<CoffinsHorizontalScrollerProps> = ({ items, linkedWallet, onSuccessChange }) => {
    const { accountData, logout } = useAuth();


    const onButtonPress = async (id: string) => {
        try {
            if (accountData) {
                const response = await nftMint(accountData.id, accountData.session, accountData.hash, linkedWallet, "Vampire Coffin", "0", id);

                if (response.response === "success") {
                    onSuccessChange(true);
                }
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className="horizontal-scroller">
            <div className="scroller-content">
                {items.map(x => {
                    return (
                        <div key={x.coffin_id} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <Box
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    bgcolor: '#242958',
                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                                style={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", transition: 'box-shadow 0.3s ease-in-out' }}
                            >
                                <img style={{ width: "auto" }} src={vampireCoffin} alt="Example GIF" />
                            </Box>
                            <Button key={x.coffin_id} disabled={x.coffin_used === "1"} onClick={() => onButtonPress(x.coffin_id)}>Open</Button>
                        </div>
                    )
                })}
            </div>
        </div>)
};

export default CoffinsHorizontalScroller;
