import React, { useState } from 'react';
import './MarketItem.scss';
import KKTicker from '@assets/KKTicker.webp';
import '../fonts.scss';
import { CardQuality, MarketItemDataListed } from '@interfaces/types';
import { Box, ThemeProvider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../helpers/formating';
import useMediaQuery from '../hooks/useMediaQuery';

export interface MarketItemProps {
    item: MarketItemDataListed;
    handleUnlist: () => void;
}

const MarketItemListed: React.FC<MarketItemProps> = (props: MarketItemProps) => {

    const isForDesktop = useMediaQuery();
    const escapedTitle = encodeURIComponent(props.item.image_name);
    const [isMouseInside, setIsMouseInside] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsMouseInside(true);
    };

    const handleMouseLeave = () => {
        setIsMouseInside(false);
    };

    const getRarityMain = () => {
        switch (props.item.quality) {
            case CardQuality.Common:
                return "#717273";
            case CardQuality.Uncommon:
                return "#228B22";
            case CardQuality.Rare:
                return "#4169E1"
            case CardQuality.Epic:
                return "#800080";
            case CardQuality.Legendary:
                return "#FF7518";
            default:
                return "#717273";
        }
    }

    const getRarityDark = () => {
        switch (props.item.quality) {
            case CardQuality.Common:
                return "#808080";
            case CardQuality.Uncommon:
                return "#355E3B";
            case CardQuality.Rare:
                return "#0F52BA"
            case CardQuality.Epic:
                return "#630330";
            case CardQuality.Legendary:
                return "#FF5F15";
            default:
                return "#808080";
        }
    }

    return (
        <>
            {isForDesktop && <div style={{ display: "flex", flexDirection: "column", margin: "20px" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            width: 100,
                            height: 100,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            bgcolor: '#242958',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                        style={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", transition: 'box-shadow 0.3s ease-in-out' }}
                    >
                        <img src={`/assets/${escapedTitle}.webp`} alt="Description" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, margin: props.item.asset_type === "card" ? "0px" : "20px" }} />

                        {props.item.level !== 0 && <div style={{ position: 'absolute', top: '7px', left: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div style={{ color: 'white', fontWeight: "500", fontSize: '14px' }}>Lvl: {props.item.level}</div>
                        </div>}

                        <div style={{ position: 'absolute', top: '7px', right: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div style={{ color: 'white', fontWeight: "500", fontSize: '14px' }}>x{props.item.amount}</div>
                        </div>
                    </Box>

                </ThemeProvider>

                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            bgcolor: 'primary.main',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)'
                        }}
                        style={{ width: "200px", height: "65px", marginTop: "5px", display: "flex", flexDirection: "column" }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
                            <div>
                                {props.item.title.length < 20 && <p style={{ justifyContent: "flex-start", fontSize: "16px" }}>{props.item.title}</p>}
                                {props.item.title.length >= 20 && <p style={{ justifyContent: "flex-start", fontSize: "13px" }}>{props.item.title}</p>}
                            </div>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "5px", paddingRight: "5px" }}>
                            <p className='market-item-cost-text'>{formatNumber(props.item.price)}  <img className='market-item-kkt-logo' src={KKTicker} alt={`${KKTicker}`} /> </p>
                            {isMouseInside && (
                                <button
                                    type='reset'
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        right: 0,
                                        marginBottom: "-10px"
                                    }}
                                    onClick={props.handleUnlist}
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            )}
                        </div>
                    </Box>
                </ThemeProvider>
            </div>}

            {!isForDesktop && <div style={{ display: "flex", flexDirection: "column", margin: "5px" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            width: 60,
                            height: 60,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            bgcolor: '#242958',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                        style={{ width: "140px", height: "140px", display: "flex", flexDirection: "column", transition: 'box-shadow 0.3s ease-in-out' }}
                    >
                        <img src={`/assets/${escapedTitle}.webp`} alt="Description" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, margin: props.item.asset_type === "card" ? "0px" : "20px" }} />

                        {props.item.level !== 0 && <div style={{ position: 'absolute', top: '7px', left: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div style={{ color: 'white', fontWeight: "500", fontSize: '14px' }}>Lvl: {props.item.level}</div>
                        </div>}

                        <div style={{ position: 'absolute', top: '7px', right: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div style={{ color: 'white', fontWeight: "500", fontSize: '14px' }}>x{props.item.amount}</div>
                        </div>
                    </Box>

                </ThemeProvider>

                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            bgcolor: 'primary.main',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)'
                        }}
                        style={{ width: "140px", height: "65px", marginTop: "5px", display: "flex", flexDirection: "column" }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
                            <div>
                                {props.item.title.length < 20 && <p style={{ justifyContent: "flex-start", fontSize: "13px" }}>{props.item.title}</p>}
                                {props.item.title.length >= 20 && <p style={{ justifyContent: "flex-start", fontSize: "10px" }}>{props.item.title}</p>}
                            </div>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "5px", paddingRight: "5px" }}>
                            <p className='market-item-cost-text'>{formatNumber(props.item.price)}  <img className='market-item-kkt-logo' src={KKTicker} alt={`${KKTicker}`} /> </p>
                            {isMouseInside && (
                                <button
                                    type='reset'
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        right: 0,
                                        marginBottom: "-10px"
                                    }}
                                    onClick={props.handleUnlist}
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            )}
                        </div>
                    </Box>
                </ThemeProvider>
            </div>}
        </>

    );
};

export default MarketItemListed;
