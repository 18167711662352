import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CardQuality, MarketItemDataNew, SelectOption } from '@interfaces/types';
import './ShopOld.scss';
import MarketItem from '@components/MarketItem';
import { useAuth } from '../AuthContext';
import './MarketSell.scss';
import { getPlayerAssets } from '../api/account';
import { useMyContext } from '../MyContext';
import { fillCards, fillItems } from '../helpers/marketItems';
import useMediaQuery from '@hooks/useMediaQuery';
import SelectComponent from '@components/SelectComponent';
import { Button, SelectChangeEvent } from '@mui/material';
import InputComponent from '@components/InputComponent';

const assetOptions: SelectOption[] = [
    { value: 'Cards', label: 'Cards' },
    { value: 'Items', label: 'Items' },
];

const MarketSell: React.FC = () => {

    const itemsPerPage = 15;
    const isForDesktop = useMediaQuery();

    const { accountData } = useAuth();
    const { characterData, itemsData } = useMyContext();
    const [playerCardAssets, setPlayerCardAssets] = useState<MarketItemDataNew[]>([]);
    const [playerItemAssets, setPlayerItemAssets] = useState<MarketItemDataNew[]>([]);
    const [selectedQuality, setSelectedQuality] = useState<CardQuality>(() => {
        return (localStorage.getItem('marketSellSelectedQuality') as CardQuality) || CardQuality.All;
    });
    const [selectedAsset, setSelectedAsset] = useState<string>(() => {
        return localStorage.getItem('marketSellSelectedAsset') || 'Cards';
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleQualityChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const selectedValue = event.target.value;
        setSelectedQuality(selectedValue as CardQuality);
        setCurrentPage(1);
        localStorage.setItem('marketSellSelectedQuality', selectedValue);
    };

    const handleAssetChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const selectedValue = event.target.value;
        setSelectedAsset(selectedValue);
        setCurrentPage(1);
        localStorage.setItem('marketSellSelectedAsset', selectedValue);
    };

    const handleClearFilterButtonClick = () => {
        setSelectedQuality(CardQuality.All);
        setSelectedAsset("Cards");
        setSearchQuery("");
        setCurrentPage(1)
        localStorage.setItem('marketSellSelectedQuality', CardQuality.All);
        localStorage.setItem('marketSellSelectedAsset', "Cards");
    };

    useEffect(() => {
        const getPlayersAssetsData = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        const response = await getPlayerAssets(accountData.id);
                        const cards = fillCards(response, characterData)
                        const items = fillItems(response, itemsData);
                        setPlayerCardAssets(cards);
                        setPlayerItemAssets(items);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        getPlayersAssetsData();
    }, [characterData, itemsData, selectedAsset, searchQuery]);

    let totalPages: number = 0;

    const handlePageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    const filteredCardsByQuality = selectedQuality === CardQuality.All
        ? playerCardAssets
        : playerCardAssets.filter(item => item.quality === selectedQuality);

    const filteredItemsByQuality = selectedQuality === CardQuality.All
        ? playerItemAssets
        : playerItemAssets.filter(item => item.quality === selectedQuality);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let currentItems: MarketItemDataNew[] = [];
    switch (selectedAsset) {
        case "Cards":
            const filteredCards = filteredCardsByQuality.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
            currentItems = useMemo(() => {
                return filteredCards.slice(startIndex, endIndex);
            }, [filteredCards, startIndex, endIndex]);
            totalPages = Math.ceil(filteredCards.length / itemsPerPage);
            break;
        case "Items":
            const filteredItems = filteredItemsByQuality.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
            currentItems = useMemo(() => {
                return filteredItems.slice(startIndex, endIndex);
            }, [filteredItems, startIndex, endIndex]);
            totalPages = Math.ceil(filteredItems.length / itemsPerPage);
            break;
        default:
            break;
    }
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const mapEnumToSelectOptions = (enumObj: any): SelectOption[] => {
        const enumOptions = Object.keys(enumObj).map((key) => ({
            value: enumObj[key],
            label: key,
        }));
        return enumOptions;
    };
    return (
        <div className='market-sell-container'>
            <div className='market-sell-filter-wrapper'>

                {isForDesktop && <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "250px" }}>
                            <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />
                        </div>
                        <div style={{ marginLeft: "20px", width: "250px" }}>
                            <SelectComponent items={assetOptions} label='Asset type' value={selectedAsset} handleChange={handleAssetChange} />
                        </div>
                        <div style={{ marginLeft: "20px", width: "250px" }}>
                            <InputComponent label='Search' value={searchQuery} handleChange={handleSearchChange} />
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </div>}

                {!isForDesktop && <>
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                        <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />
                    </div>
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                        <SelectComponent items={assetOptions} label='Asset type' value={selectedAsset} handleChange={handleAssetChange} />
                    </div>
                    <InputComponent label='Search' value={searchQuery} handleChange={handleSearchChange} />
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </>}
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", justifyContent: "center" }}>
                {selectedAsset === "Cards" && currentItems.map((x, index) => (
                    <MarketItem key={index} item={x} isCardItem={true} pricePerItem={0} isForSale={true} />
                ))}
                {selectedAsset === "Items" && currentItems.map((x, index) => (
                    <MarketItem key={index} item={x} isCardItem={false} pricePerItem={0} isForSale={true} />
                ))}
            </div>

            {currentItems.length > 0 && <div className="pagination" style={{ marginBottom: "50px" }}>
                <button
                    className='pagination-button'
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &laquo;
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className='pagination-button '
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    &raquo;
                </button>
            </div>}
        </div>
    );
};


export default MarketSell;