import { FC } from 'react';
import './HorizontalScroller.scss';
import { CharacterData, MidasData } from '@interfaces/types';
import MidasList from './MidasList';

interface HorizontalScrollerProps {
    items: MidasData[];
    xp: string[];
    isCardDisabled: boolean;
    selectMidasReq: (midasTableId: string) => Promise<void>;
}

const HorizontalScroller: FC<HorizontalScrollerProps> = ({ items, xp, isCardDisabled, selectMidasReq }) => {
    const getLabelColor = (item: CharacterData): string => {
        if (item.Quality === "Common") return "#A4A4A4";
        if (item.Quality === "Uncommon") return "#68DF2F";
        if (item.Quality === "Rare") return "#33C4F1";
        if (item.Quality === "Epic") return "#7C4FFF";
        return "#E26D00";
    };

    return (
        <div className="horizontal-scroller">
            <div className="scroller-content">
                {items.map((item, index) => (
                    <div key={index} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <label style={{ fontFamily: "PassionOneRegular", fontSize: "30px", color: getLabelColor(item) }}>{xp[index]} XP</label>
                        <MidasList key={index} CharacterData={[item]} isCardDisabled={isCardDisabled} selectMidasReq={selectMidasReq} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HorizontalScroller;
