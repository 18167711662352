import { CharacterData } from '@interfaces/types';
import axios, { AxiosResponse } from 'axios';

const API_URL = 'https://kingdomkarnage.com/get_character_data.php';

const api = axios.create({
  baseURL: API_URL,
});

export const getCharacterDataWithoutContext = async (): Promise<CharacterData[]> => {
  try {
    const response = await api.get<CharacterData[]>('');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCharacterData = async (): Promise<AxiosResponse<CharacterData[]>> => {
  try {
    const response = await api.get<CharacterData[]>('');
    return response;
  } catch (error) {
    throw error;
  }
};


export const getIPAddress = async () => {
  try {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data;
  } catch (error) {
    throw error;
  }
};