import React, { useState } from 'react';

import image6 from '../assets/image6.webp';
import KKLogo from '../assets/KKLogo.webp';
import './Login.scss';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/Loader';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

interface LoginProps {
    ipAddress: string;
}

const Login: React.FC<LoginProps> = ({ ipAddress }) => {
    const navigate = useNavigate();
    const { login, login2fa } = useAuth();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [newModalInput, setNewModalInput] = useState('');



    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const onConfirmNewModal = async () => {
        const result = await login2fa(ipAddress, email, password, newModalInput);
        if (result.response = "loginSuccessFul") {
            navigate('/');
        }
        setDialogOpen(false);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const result = await login(ipAddress, email, password, "false");

            if (result.response === "loginSuccessFul") {
                setIsLoading(false);
                navigate('/');
            } else if (result.error === 'Unauthorized') {
                setIsLoading(false);
                setDialogOpen(true);
            } else {
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Login failed:', error);
        }
    };
    return (
        <div className="login-container " style={{ backgroundImage: `url(${image6})` }}>
            <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="new-modal-title"
                aria-describedby="new-modal-description"
            >
                <DialogTitle id="new-modal-title">{"2fa"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="new-modal-description">
                        Enter the security code generated by your mobile authenticator app.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Security code"
                        type="text"
                        fullWidth
                        value={newModalInput}
                        onChange={(e) => setNewModalInput(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} autoFocus>
                        Close
                    </Button>
                    <Button onClick={onConfirmNewModal} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <img className='login-logo' src={KKLogo} alt="KKLogo" />
            {!isLoading && <div className="login">
                <form className='form'>
                    <div className="form-group">
                        <label className='label'>Email:</label>
                        <input className='input'
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className='label'>Password:</label>
                        <input className='input'
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="links">
                        <p>
                            <Link className="forgot-pass" to="/forgot-password">FORGOT PASSWORD</Link>
                        </p>
                    </div>

                    <button type="button" onClick={handleLogin} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        LOGIN
                    </button>
                </form>
                <div className="links">
                    <p>
                        Don't have an account?
                    </p>
                    <p>
                        <Link className="forgot-pass" to="/register">Create Account</Link>
                    </p>
                </div>
            </div>}
            {isLoading && <Loader isLoading={isLoading} />}
        </div>

    );
};

export default Login;