import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './MidasVault.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { getCoffins, getPlayerAssets, linkedWallets } from '@api/account';
import { Coffins, Key, VampireNft } from '@interfaces/types';
import Loader from '@components/Loader';
import bloodtoken from '@assets/bloodtoken.webp';
import { Box, Divider } from '@mui/material';
import CoffinsHorizontalScroller from '@components/CoffinsHorizontalScroller';
import BloodKeyHorizontalScroller from '@components/BloodKeyHorizontalScroller';
import { fillItems } from '../helpers/marketItems';
import { useMyContext } from '../MyContext';
import VampireHorizontalScroller from '@components/VampireHorizontalScroller';


const Vampires: React.FC = () => {
    const isForDesktop = useMediaQuery();
    const { accountData, logout } = useAuth();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [linkedWallet, setLinkedWallet] = useState<string>("");
    const [bloodTokenAmount, setBloodTokenAmount] = useState<number>(0);
    const [coffins, setCoffins] = useState<Coffins[]>([]);
    const [keys, setKeys] = useState<Key[]>([]);
    const [liliths, setLiliths] = useState<VampireNft[]>([]);
    const [satrinas, setSatrinas] = useState<VampireNft[]>([]);
    const { characterData, itemsData } = useMyContext();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isSuccessVampBox, setIsSuccessVampBox] = useState(false);

    const handleSuccessChange = (value: boolean) => {
        setIsSuccess(value);
    };

    const handleSuccessVampBoxChange = (value: boolean) => {
        setIsSuccessVampBox(value);
    };

    useEffect(() => {
        const getPlayersAssetsData = async () => {
            setIsLoading(true);
            if (accountData) {
                try {
                    if (accountData) {
                        // const response = await getCoffins("13570")
                        const response = await getCoffins(accountData.id)

                        if (response.response === "success") {
                            setCoffins(response.coffins)
                            const keys = response.keys.filter(x => x.key_used !== "1");
                            setKeys(keys)
                            setLiliths(response.lilith_nfts);
                            setSatrinas(response.satrina_nfts);
                        } else {
                            console.error('Error fetching data');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false);

                }
            }
        }
        const fetchData = async () => {

            try {
                if (accountData) {
                    const response = await linkedWallets(accountData.id);
                    response.map((linkedWallet, index) => (
                        Object.entries(linkedWallet).map(([address, value]) => {
                            if (value === "1") {
                                setLinkedWallet(address)
                            }
                        })
                    ))
                }
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        const getPlayers = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        const response = await getPlayerAssets(accountData.id);
                        const items = fillItems(response, itemsData);

                        const bllb = items.filter(x => x.game_reference === "bloodtoken");
                        setBloodTokenAmount(bllb[0].amount)
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        getPlayers();

        fetchData();
        getPlayersAssetsData();
    }, []);

    useEffect(() => {
        const getPlayersAssetsData = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        // const response = await getCoffins("13570")
                        const response = await getCoffins(accountData.id)

                        if (response.response === "success") {
                            setCoffins(response.coffins)
                            const keys = response.keys.filter(x => x.key_used !== "1");
                            setKeys(keys)
                            setLiliths(response.lilith_nfts);
                            setSatrinas(response.satrina_nfts);
                        } else {
                            console.error('Error fetching data');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                }
            }
        }
        const getPlayers = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        const response = await getPlayerAssets(accountData.id);
                        const items = fillItems(response, itemsData);

                        const bllb = items.filter(x => x.game_reference === "bloodtoken");
                        setBloodTokenAmount(bllb[0].amount)
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        getPlayersAssetsData();
        getPlayers();

    }, [isSuccess, isSuccessVampBox]);

    const renderContent = () => (
        <>
            {isForDesktop && (
                <>
                    <Divider style={{ color: "rgb(107, 122, 144)", maxWidth: "1000px", fontSize: "1.3rem", fontWeight: "700", letterSpacing: "0.08rem", width: "80%" }} textAlign="left">BLOOD TOKENS</Divider>
                    <div style={{ width: "80%", maxWidth: "1000px", justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "fit-content" }}>

                            <Box
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    bgcolor: '#242958',
                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                                style={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", transition: 'box-shadow 0.3s ease-in-out' }}
                            >
                                <img style={{ width: "auto" }} src={bloodtoken} alt="Example GIF" />

                            </Box>
                            <label style={{ fontFamily: "PassionOneRegular", fontSize: "22px", marginTop: "5px", color: "rgb(255, 153, 0)" }}>Amount: {bloodTokenAmount}</label>

                        </div>
                    </div>


                    <Divider style={{ color: "rgb(107, 122, 144)", maxWidth: "1000px", fontSize: "1.3rem", fontWeight: "700", letterSpacing: "0.08rem", width: "80%" }} textAlign="left">VAMPIRE COFFINS</Divider>
                    <div style={{ width: "80%", maxWidth: "1000px", justifyContent: "center", marginTop: "20px" }}>
                        <CoffinsHorizontalScroller items={coffins} onSuccessChange={handleSuccessChange} linkedWallet={linkedWallet} />
                    </div>

                    <Divider style={{ color: "rgb(107, 122, 144)", marginTop: "20px", maxWidth: "1000px", fontSize: "1.3rem", fontWeight: "700", letterSpacing: "0.08rem", width: "80%" }} textAlign="left">BLOOD KEYS</Divider>
                    <div style={{ width: "80%", maxWidth: "1000px", justifyContent: "center", marginTop: "20px" }}>
                        <BloodKeyHorizontalScroller items={keys} linkedWallet={linkedWallet} onSuccessChange={handleSuccessVampBoxChange} />
                    </div>

                    <Divider style={{ color: "rgb(107, 122, 144)", marginTop: "20px", maxWidth: "1000px", fontSize: "1.3rem", fontWeight: "700", letterSpacing: "0.08rem", width: "80%" }} textAlign="left">MY VAMPIRES</Divider>
                    <div style={{ width: "80%", maxWidth: "1000px", justifyContent: "center", marginTop: "20px" }}>
                        <VampireHorizontalScroller items={[...liliths, ...satrinas]} />
                    </div>
                </>
            )
            }
            {!isForDesktop && (
                <>
                    <Divider style={{ color: "rgb(107, 122, 144)", maxWidth: "1000px", fontSize: "0.6rem", fontWeight: "700", letterSpacing: "0.08rem", width: "100%" }} textAlign="left">VAMPIRE COFFINS</Divider>
                    <div style={{ width: "90%", justifyContent: "center", marginTop: "5px", marginBottom: "5px" }}>
                        <CoffinsHorizontalScroller items={coffins} onSuccessChange={handleSuccessChange} linkedWallet={linkedWallet} />
                    </div>

                    <Divider style={{ color: "rgb(107, 122, 144)", maxWidth: "1000px", fontSize: "0.6rem", fontWeight: "700", letterSpacing: "0.08rem", width: "100%" }} textAlign="left">BLOOD KEYS</Divider>
                    <div style={{ width: "90%", justifyContent: "center", marginTop: "5px", marginBottom: "5px" }}>
                        <BloodKeyHorizontalScroller items={keys} linkedWallet={linkedWallet} onSuccessChange={handleSuccessVampBoxChange} />

                    </div>

                    <Divider style={{ color: "rgb(107, 122, 144)", maxWidth: "1000px", fontSize: "0.6rem", fontWeight: "700", letterSpacing: "0.08rem", width: "100%" }} textAlign="left">MY VAMPIRES</Divider>
                    <div style={{ width: "90%", justifyContent: "center", marginTop: "5px", marginBottom: "5px" }}>
                        <VampireHorizontalScroller items={[...liliths, ...satrinas]} />

                    </div>
                </>
            )}
        </>

    )

    return (
        <div className="page-content" style={{ position: "relative" }}>
            <div className='page-title-container' style={{ zIndex: 1 }}>
                <label className='page-title'>Vampires</label>
            </div>

            {isLoading ? <Loader isLoading={isLoading} /> : renderContent()}


        </div>)
};

export default BackgroundTemplate(Vampires);
