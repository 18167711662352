import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

interface SessionAndHashDialogProps {
    isOpen: boolean;
}

const SessionAndHashDialog: React.FC<SessionAndHashDialogProps> = (props: SessionAndHashDialogProps) => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const onLoginPress = () => {
        logout();
        navigate("/login");
    }
    return (
        <React.Fragment>
            <Dialog
                open={props.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Session Error"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Session expired, please login again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onLoginPress}>Login</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default SessionAndHashDialog;