import React, { useState } from 'react';
import { toast } from 'react-toastify';
import image6 from '../assets/image6.webp';
import KKLogo from '../assets/KKLogo.webp';
import './Login.scss';
import { forgetPassword } from '@api/account';

const ForgotPassword = () => {
    const [email, setEmail] = useState<string>('');

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleOnClick = async () => {
        try {
            const response = await forgetPassword(email);

            if(response.response === "sent"){
                setEmail("");
                toast.success(" Recovery email has been sent.");
            } else {
                setEmail("");
                toast.success(" Recovery email has been sent.");
            }

          } catch (error) {
            console.error('Error fetching data:', error);
          }
    };
    return (
        <div className="login-container " style={{ backgroundImage: `url(${image6})` }}>
            <img className='login-logo' src={KKLogo} alt="KKLogo" />
            <div className="login" style={{height: "330px"}}>
                <form className='form'>
                    <div className="form-group">
                        <label className='label'>Email:</label>
                        <input className='input'
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <button type="button" style={{display: "flex",width: "auto", marginTop: "15px", paddingLeft: "15px", paddingRight: "15px" }} onClick={handleOnClick}>
                        PASSWORD RESET
                    </button>
                </form>
            </div>
        </div>

    );
};

export default ForgotPassword;