import { FC } from 'react';
import './HorizontalScroller.scss';
import { CharacterData, Coffins, Key, MidasData } from '@interfaces/types';
import MidasList from './MidasList';
import { Button } from '@mui/material';
import bloodKey from '@assets/BloodKey.gif';
import { useAuth } from '../AuthContext';
import { openVampBox } from '../api/account';

interface BloodKeyHorizontalScrollerProps {
    items: Key[];
    linkedWallet: string;
    onSuccessChange: (value: boolean) => void;
}

const BloodKeyHorizontalScroller: FC<BloodKeyHorizontalScrollerProps> = ({ items, linkedWallet, onSuccessChange }) => {
    const { accountData, logout } = useAuth();


    const onButtonPress = async (keyNumber: string) => {
        try {
            if (accountData) {
                const response = await openVampBox(accountData.id, accountData.session, accountData.hash, linkedWallet, keyNumber);

                if (response.response === "success") {
                    onSuccessChange(true);
                }
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    return (
        <div className="horizontal-scroller">
            <div className="scroller-content">
                {items.map(x => {
                    return (
                        <div style={{ display: "flex", flexDirection: "column", margin: "10px", width: "200px", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ width: "auto" }} src={bloodKey} alt="Example GIF" />
                            <Button disabled={x.key_used === "1"} onClick={() => onButtonPress(x.key_id)}>Open</Button>

                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default BloodKeyHorizontalScroller;
