import React, { useEffect, useState } from 'react';
import './Modal.scss';
import { getKktValueInDollars } from '../api/account';
import { useAuth } from '../AuthContext';
import KKTicker from '@assets/KKTicker.webp';
import '../pages/Account.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import UIMidasTempleMidasStatue from '@assets/UIMidasTempleMidasStatue.png';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%",
            height: "500px"
        }
    }
}));

interface PurchaseMidasFromStoreModalProps {
    isOpen: boolean;
    onBuy: () => Promise<void>;
    onClose: () => void;
}

const PurchaseMidasFromStoreModal: React.FC<PurchaseMidasFromStoreModalProps> = ({ isOpen, onBuy, onClose }) => {
    const { accountData } = useAuth();
    const [priceInKkt, setPriceInKkt] = useState<string>("");

    const calculatePriceInKkt = (kktValue: number) => {
        const updatedOptions = (parseFloat("50") / kktValue).toFixed(2);
        setPriceInKkt(updatedOptions);
    };

    useEffect(() => {
        const kktValueInDollars = async () => {
            if (accountData) {
                try {
                    const response = await getKktValueInDollars(accountData ? accountData.id : "");
                    calculatePriceInKkt(parseFloat(response.kkt_value));

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        kktValueInDollars();
    }, []);
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please confirm your purchase."}
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "15px" }}>
                         <img style={{ height: "245px", width: "245px" }} src={UIMidasTempleMidasStatue} alt='UIMidasTempleMidasStatue' />
                     </div>
                    <label style={{ fontFamily: "PassionOneRegular", fontSize: "1.5rem", display: "flex" }}>{priceInKkt} <img style={{ height: "2rem" }} src={KKTicker} alt={`${KKTicker}`} /></label>
                 <label style={{ fontFamily: "Ruda", fontSize: "10px", fontStyle: "italic", marginBottom: "10px" }}>($50 in KKT)</label>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={onBuy}>Buy</Button>
            </DialogActions>
        </Dialog>
    )
};

export default PurchaseMidasFromStoreModal;
