import { MarketItemDataNew, PlayerAsset, CharacterData, CardQuality, ItemsData, MyAuction, MarketItemDataListed, GetAuctionResponse, MidasData } from "@interfaces/types";

export function stringToCardQuality(value: string): CardQuality {
    switch (value?.toLowerCase()) {
        case 'common':
            return CardQuality.Common;
        case 'uncommon':
            return CardQuality.Uncommon;
        case 'rare':
            return CardQuality.Rare;
        case 'epic':
            return CardQuality.Epic;
        case 'legendary':
            return CardQuality.Legendary;
        default:
            return CardQuality.Common;
    }
}

export function cardQualityToString(quality: CardQuality): string {
    switch (quality) {
        case CardQuality.Common:
            return 'Common';
        case CardQuality.Uncommon:
            return 'Uncommon';
        case CardQuality.Rare:
            return 'Rare';
        case CardQuality.Epic:
            return 'Epic';
        case CardQuality.Legendary:
            return 'Legendary';
        default:
            return 'Common';
    }
}

export function padWithZero(input: string): string {
    const number = parseInt(input, 10);
    if (isNaN(number)) {
        return input;
    }
    return number < 10 ? `0${number}` : input;
}

const fillMarketItemDataCard = (playerCard: PlayerAsset, characterData: CharacterData[] | null): MarketItemDataNew | undefined => {
    if (playerCard.account_type !== "normal" || playerCard.asset_type !== "card") {
        return undefined;
    }

    const matchingCharacter = characterData?.find(character => playerCard.asset_id === character.id);

    if (matchingCharacter) {
        return {
            asset_id: playerCard.asset_id,
            amount: parseInt(playerCard.amount, 10),
            game_reference: matchingCharacter.GameReference,
            level: parseInt(matchingCharacter.Level, 10),
            quality: stringToCardQuality(matchingCharacter.Quality),
            title: matchingCharacter.Title,
            asset_type: playerCard.asset_type,
            token_index: playerCard.token_index,
            image_name: matchingCharacter.Title
        };
    }

    return undefined;
};

export const fillChainData = (playerCard: PlayerAsset, characterData: CharacterData[] | null): MarketItemDataNew | undefined => {
    if (playerCard.account_type !== "chain") {
        return undefined;
    }

    const matchingCharacter = characterData?.find(character => playerCard.asset_id === character.id);

    if (matchingCharacter) {
        return {
            asset_id: playerCard.asset_id,
            amount: parseInt(playerCard.amount, 10),
            game_reference: matchingCharacter.GameReference,
            level: parseInt(matchingCharacter.Level, 10),
            quality: stringToCardQuality(matchingCharacter.Quality),
            title: matchingCharacter.Title,
            asset_type: playerCard.asset_type,
            token_index: playerCard.token_index,
            image_name: matchingCharacter.Title
        };
    }

    return undefined;
};

const fillKingMidas = (playerCard: PlayerAsset, characterData: CharacterData[] | null): MarketItemDataNew => {
    const game_reference = `kingmidas${playerCard.quality.toLowerCase()}_${padWithZero(playerCard.level)}`;
    const matchingCharacter = characterData?.find(character => character.GameReference === game_reference);
    const midasId = matchingCharacter?.id || "";

    return {
        asset_id: midasId,
        amount: parseInt(playerCard.amount, 10),
        game_reference: game_reference,
        asset_type: "card",
        level: parseInt(playerCard.level, 10),
        quality: stringToCardQuality(playerCard.quality),
        title: `King Midas #${playerCard.token_number}`,
        token_index: playerCard.token_index,
        image_name: "King Midas"
    };
};

const fillTheWyden = (playerCard: PlayerAsset, characterData: CharacterData[] | null): MarketItemDataNew | undefined => {
    if (playerCard.asset_type !== "wyden" || playerCard.in_game !== "1" || playerCard.dead !== "0") {
        return undefined;
    }

    const game_reference = `thewyden_${padWithZero(playerCard.level)}`;
    const matchingCharacter = characterData?.find(character => character.GameReference === game_reference);
    const wydenId = matchingCharacter?.id || "";

    return {
        asset_id: wydenId,
        amount: parseInt(playerCard.amount, 10),
        game_reference: game_reference,
        asset_type: "card",
        level: parseInt(playerCard.level, 10),
        quality: stringToCardQuality("Legendary"),
        title: `The Wyden`,
        token_index: playerCard.token_index,
        image_name: "The Wyden"
    };
};

export const fillCards = (playerAssets: PlayerAsset[], characterData: CharacterData[] | null): MarketItemDataNew[] => {
    const marketItemDataCard = playerAssets.map(playerCard => fillMarketItemDataCard(playerCard, characterData)).filter(Boolean) as MarketItemDataNew[];
    const kingMidases = playerAssets.filter(item => item.asset_type === "king_midas").map(playerCard => fillKingMidas(playerCard, characterData)).filter(Boolean) as MarketItemDataNew[];
    const wydens = playerAssets.map(playerCard => fillTheWyden(playerCard, characterData)).filter(Boolean) as MarketItemDataNew[];

    return [...marketItemDataCard, ...kingMidases, ...wydens];
};

const fillKingMidasForMidasCard = (playerCard: PlayerAsset, characterData: CharacterData[] | null): MidasData => {
    const game_reference = `kingmidas${playerCard.quality.toLowerCase()}_${padWithZero(playerCard.level)}`;
    const matchingCharacter = characterData?.find(character => character.GameReference === game_reference);
    const midasId = matchingCharacter?.id || "";

    if (matchingCharacter) {
        return {
            Ability1: matchingCharacter.Ability1,
            Ability2: matchingCharacter.Ability2,
            Ability3: matchingCharacter.Ability3,
            Countdown: matchingCharacter.Countdown,
            Damage: matchingCharacter.Damage,
            GameReference: game_reference,
            Health: matchingCharacter.Health,
            id: matchingCharacter.id,
            Level: matchingCharacter.Level,
            midas_table_id: playerCard.table_id,
            Quality: matchingCharacter.Quality,
            Title: matchingCharacter.Title,
            tradable: "",
            Type: matchingCharacter.Type,
            active: playerCard.active
        };
    }
    return {} as MidasData;
};


export const fillMidasCards = (playerAssets: PlayerAsset[], characterData: CharacterData[] | null): MidasData[] => {
    const kingMidases = playerAssets.map(playerCard => fillKingMidasForMidasCard(playerCard, characterData)).filter(Boolean) as MidasData[];
    return [...kingMidases];
};

const fillMarketItemDataItem = (playerItem: PlayerAsset, itemData: ItemsData[] | null): MarketItemDataNew | undefined => {
    if (playerItem.account_type === "bound" || playerItem.asset_type !== "item") {
        return undefined;
    }

    const matchingCharacter = itemData?.find(item => playerItem.asset_id === item.id);

    if (matchingCharacter) {
        return {
            asset_id: playerItem.asset_id,
            amount: parseInt(playerItem.amount, 10),
            game_reference: matchingCharacter.gamereference,
            level: parseInt(matchingCharacter.level, 10),
            quality: stringToCardQuality(matchingCharacter.quality),
            title: matchingCharacter.title,
            asset_type: playerItem.asset_type,
            token_index: playerItem.token_index,
            image_name: matchingCharacter.gamereference
        };
    }

    return undefined;
};

const fillPioneerRing = (playerItem: PlayerAsset, itemData: ItemsData[] | null): MarketItemDataNew | null => {
    if (playerItem.in_game !== "1") {
        return null;
    }
    const game_reference = `pioneerspride${playerItem.quality.toLowerCase()}`;
    const matchingCharacter = itemData?.find(item => item.gamereference === game_reference);
    const ringId = matchingCharacter?.id || "";

    return {
        asset_id: ringId,
        amount: parseInt(playerItem.amount, 10),
        game_reference: game_reference,
        asset_type: "item",
        level: NaN,
        quality: stringToCardQuality(playerItem.quality),
        title: `Pioneer Ring`,
        token_index: playerItem.token_index,
        image_name: game_reference
    };
};

const fillLuckyTrinket = (playerItem: PlayerAsset, itemData: ItemsData[] | null): MarketItemDataNew | null => {
    if (playerItem.in_game !== "1") {
        return null;
    }
    const game_reference = `luckytrinket${playerItem.quality.toLowerCase()}_${padWithZero(playerItem.level)}`;
    const image_name = `luckytrinket${playerItem.quality.toLowerCase()}`;
    const matchingTrinket = itemData?.find(item => item.gamereference === game_reference);
    const trinketId = matchingTrinket?.id || "";

    if (matchingTrinket) {
        return {
            asset_id: trinketId,
            amount: parseInt(playerItem.amount, 10),
            game_reference: game_reference,
            asset_type: "item",
            level: parseInt(matchingTrinket.level, 10),
            quality: stringToCardQuality(playerItem.quality),
            title: matchingTrinket.title,
            token_index: playerItem.token_index,
            image_name: image_name
        };
    }
    return null;
};

const fillCataKeys = (playerItem: PlayerAsset, itemData: ItemsData[] | null): MarketItemDataNew | null => {
    if (playerItem.in_game !== "1") {
        return null;
    }

    const matchingKey = itemData?.find(item => item.gamereference === "catacombskey");
    const keyId = matchingKey?.id || "";

    if (matchingKey) {
        return {
            asset_id: keyId,
            amount: parseInt(playerItem.amount, 10),
            game_reference: "catacombskey",
            asset_type: "item",
            level: parseInt(playerItem.level, 10),
            quality: stringToCardQuality(matchingKey.quality),
            title: `${matchingKey.title} ${playerItem.token_number}`,
            token_index: playerItem.token_index,
            image_name: "Catacombs Key"
        };
    }
    return null;
};



const fillTampleKeys = (playerItem: PlayerAsset, itemData: ItemsData[] | null): MarketItemDataNew | null => {
    if (playerItem.bound === "1") {
        return null;
    }

    const game_reference = `midastemplekey${playerItem.key_type}`;
    const matchingKey = itemData?.find(item => item.gamereference === game_reference);
    const keyId = matchingKey?.id || "";

    if (matchingKey) {
        return {
            asset_id: keyId,
            amount: parseInt(playerItem.amount, 10),
            game_reference: game_reference,
            asset_type: "item",
            level: parseInt(playerItem.level, 10),
            quality: stringToCardQuality(matchingKey.quality),
            title: `${matchingKey.title} ${playerItem.token_number}`,
            token_index: playerItem.table_id,
            image_name: game_reference
        };
    }
    return null;
};

export const fillItems = (playerAssets: PlayerAsset[], itemData: ItemsData[] | null): MarketItemDataNew[] => {
    const marketItemDataCard = playerAssets
        .map(playerCard => fillMarketItemDataItem(playerCard, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    const pioneerRings = playerAssets
        .filter(item => item.asset_type === "pioneer_ring")
        .map(playerCard => fillPioneerRing(playerCard, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    const luckyTrinkets = playerAssets
        .filter(item => item.asset_type === "lucky_trinket")
        .map(playerItem => fillLuckyTrinket(playerItem, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    const cataKeys = playerAssets
        .filter(item => item.asset_type === "cata_key")
        .map(playerItem => fillCataKeys(playerItem, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    const templateKeys = playerAssets
        .filter(item => item.asset_type === "temple_key")
        .map(playerItem => fillTampleKeys(playerItem, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    return [...marketItemDataCard, ...pioneerRings, ...luckyTrinkets, ...cataKeys, ...templateKeys];
};

// My auctions

const fillMyAuctionItemDataCard = (playerCard: MyAuction, characterData: CharacterData[] | null): MarketItemDataListed | undefined => {
    if (playerCard.type !== "card") {
        return undefined;
    }

    const matchingCharacter = characterData?.find(character => playerCard.asset_id === character.id);

    if (matchingCharacter) {
        return {
            auction_id: playerCard.id,
            asset_id: playerCard.asset_id,
            amount: parseInt(playerCard.auction_amount, 10),
            game_reference: matchingCharacter.GameReference,
            level: parseInt(matchingCharacter.Level, 10),
            quality: stringToCardQuality(matchingCharacter.Quality),
            title: matchingCharacter.Title,
            asset_type: playerCard.type,
            token_index: playerCard.auction_sell_index,
            price: playerCard.price,
            image_name: matchingCharacter.Title
        };
    }

    return undefined;
};

export const fillCardsMyAuction = (playerAssets: MyAuction[], characterData: CharacterData[] | null): MarketItemDataListed[] => {
    const marketItemDataCard = playerAssets.map(playerCard => fillMyAuctionItemDataCard(playerCard, characterData)).filter(Boolean) as MarketItemDataListed[];

    return [...marketItemDataCard];
};


const fillMyAuctionsItemDataItem = (playerItem: MyAuction, itemData: ItemsData[] | null): MarketItemDataListed | undefined => {
    if (playerItem.type !== "item") {
        return undefined;
    }

    const matchingCharacter = itemData?.find(item => playerItem.asset_id === item.id);

    if (matchingCharacter) {
        return {
            auction_id: playerItem.id,
            asset_id: playerItem.asset_id,
            amount: parseInt(playerItem.auction_amount, 10),
            game_reference: matchingCharacter.gamereference,
            level: parseInt(matchingCharacter.level, 10),
            quality: stringToCardQuality(matchingCharacter.quality),
            title: matchingCharacter.title,
            asset_type: playerItem.type,
            token_index: playerItem.auction_sell_index,
            price: playerItem.price,
            image_name: matchingCharacter.gamereference
        };
    }

    return undefined;
};

export const fillItemsMyAuction = (playerAssets: MyAuction[], itemData: ItemsData[] | null): MarketItemDataListed[] => {
    const marketItemDataCard = playerAssets
        .map(playerCard => fillMyAuctionsItemDataItem(playerCard, itemData))
        .filter(Boolean) as MarketItemDataListed[];
    return [...marketItemDataCard];
};

const fillMarketItemDataCardBuy = (playerCard: GetAuctionResponse, characterData: CharacterData[] | null): MarketItemDataNew | undefined => {
    if (playerCard.type !== "card") {
        return undefined;
    }

    const matchingCharacter = characterData?.find(character => playerCard.asset_id === character.id);
    if (matchingCharacter) {
        return {
            auction_id: playerCard.id,
            asset_id: playerCard.asset_id,
            amount: parseInt(playerCard.auction_amount, 10),
            game_reference: matchingCharacter.GameReference,
            level: parseInt(matchingCharacter.Level, 10),
            quality: stringToCardQuality(matchingCharacter.Quality),
            title: matchingCharacter.Title,
            asset_type: playerCard.type,
            token_index: playerCard.auction_sell_index,
            image_name: matchingCharacter.Title,
            price: parseInt(playerCard.price, 10),
            seller_id: playerCard.sellers_id,
        };
    }

    return undefined;
};

export const fillCardsBuy = (playerAssets: GetAuctionResponse[], characterData: CharacterData[] | null): MarketItemDataNew[] => {
    const marketItemDataCard = playerAssets.map(playerCard => fillMarketItemDataCardBuy(playerCard, characterData)).filter(Boolean) as MarketItemDataNew[];
    return [...marketItemDataCard];
};


const fillMarketItemDataItemBuy = (playerItem: GetAuctionResponse, itemData: ItemsData[] | null): MarketItemDataNew | undefined => {
    if (playerItem.type !== "item") {
        return undefined;
    }

    const matchingCharacter = itemData?.find(item => playerItem.asset_id === item.id);
    if (matchingCharacter) {
        let image_name = matchingCharacter?.gamereference;

        if (matchingCharacter?.gamereference.startsWith("luckytrinket")) {
            image_name = `luckytrinket${playerItem.rarity.toLowerCase()}`;
        }

        if (matchingCharacter?.gamereference === "codex2") {
            image_name = "codex2";
        }
        if (matchingCharacter?.gamereference === "codex3") {
            image_name = "codex3";
        }
        if (matchingCharacter?.gamereference === "codex4") {
            image_name = "codex4";
        }
        if (matchingCharacter?.gamereference === "codex5") {
            image_name = "codex5";
        }

        return {
            auction_id: playerItem.id,
            asset_id: playerItem.asset_id,
            amount: parseInt(playerItem.auction_amount, 10),
            game_reference: matchingCharacter.gamereference,
            level: parseInt(matchingCharacter.level, 10),
            quality: stringToCardQuality(matchingCharacter.quality),
            title: matchingCharacter.title,
            asset_type: playerItem.type,
            token_index: playerItem.auction_sell_index,
            image_name: image_name,
            price: parseInt(playerItem.price, 10),
            seller_id: playerItem.sellers_id

        };
    }

    return undefined;
};

export const fillItemsBuy = (playerAssets: GetAuctionResponse[], itemData: ItemsData[] | null): MarketItemDataNew[] => {
    const marketItemDataCard = playerAssets
        .map(playerCard => fillMarketItemDataItemBuy(playerCard, itemData))
        .filter(Boolean) as MarketItemDataNew[];

    return [...marketItemDataCard];
};