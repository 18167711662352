import React, { ChangeEvent } from 'react';
import { Button, InputAdornment, Paper, TextField } from '@mui/material';
// import './MidasVaultTransactionForm.css'; 
import KKTicker from '@assets/KKTicker.webp';


interface MidasVaultTransactionFormProps {
    title: string;
    tokenAmount: string;
    handleTokenAmountChange: (e: ChangeEvent<HTMLInputElement>) => void;
    percentageButtonAction: (percentage: number) => void;
    action: () => void;
}

const MidasVaultTransactionForm: React.FC<MidasVaultTransactionFormProps> = ({
    title,
    tokenAmount,
    handleTokenAmountChange,
    percentageButtonAction,
    action
}) => {
    return (
        <Paper elevation={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
            <TextField
                label={`Vault ${title}`}
                margin="normal"
                style={{ width: "100%" }}
                type="text"
                value={tokenAmount}
                onChange={handleTokenAmountChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={KKTicker} alt="KKTicker" style={{ width: "2.5rem" }} />
                        </InputAdornment>
                    ),
                }}
            />
            <div className="button-section">
                <button className="percentage-button" onClick={() => percentageButtonAction(25)}>25%</button>
                <button className="percentage-button" onClick={() => percentageButtonAction(50)}>50%</button>
                <button className="percentage-button" onClick={() => percentageButtonAction(75)}>75%</button>
                <button className="percentage-button" onClick={() => percentageButtonAction(100)}>100%</button>
            </div>
            <Button
                disabled={tokenAmount === "0" || tokenAmount === ""}
                variant="contained"
                style={{ width: "100%", height: "50px" }}
                onClick={action}
            >
                {title.toUpperCase()}
            </Button>
        </Paper>
    );
};

export default MidasVaultTransactionForm;
