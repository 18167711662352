import React from 'react';

interface SelectProps {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: { value: string; label: string }[];
  placeholder?: string;
  className?: string;
}

const FilterSelect: React.FC<SelectProps> = ({
  id,
  value,
  onChange,
  options,
  placeholder = '',
  className = '',
}) => {
  return (
    <select className={`filter-select ${className}`} id={id} value={value} onChange={onChange}>
      {placeholder && (
        <option value="">
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default FilterSelect;
