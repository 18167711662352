import QRCode from "qrcode";

export const generateQRCodeURL = (secret: string) => {
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(secret, (err: any, dataURL: any) => {
            if (err) {
                reject(err);
            } else {
                resolve(dataURL);
            }
        });
    });
}