import React, { } from 'react';
import './Modal.scss';
import KKTicker from '@assets/KKTicker.webp';
import '../pages/Account.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { RedGemShopModel } from '@pages/RedGemsShop';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%",
            height: "500px"
        }
    }
}));

interface PurchaseRedGemsModalProps {
    isOpen: boolean;
    buyingOption: RedGemShopModel;
    onBuy: (amount: string) => Promise<void>;
    onClose: () => void;
}

const PurchaseRedGemsModal: React.FC<PurchaseRedGemsModalProps> = ({ isOpen, buyingOption, onBuy, onClose }) => {
    const classes = useStyles();
    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Please confirm your purchase."}
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "15px" }}>
                        <img style={{ height: "245px" }} src={buyingOption.image} alt='buyingOption' />
                    </div>
                    <label style={{ fontFamily: "PassionOneRegular", fontSize: "1.5rem", display: "flex" }}>{buyingOption.priceInKkt} <img style={{ height: "2rem" }} src={KKTicker} alt={`${KKTicker}`} /></label>
                    <label style={{ fontFamily: "Ruda", fontSize: "14px", fontStyle: "italic", marginBottom: "10px" }}>(${buyingOption.priceInDollar} in KKT)</label>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => onBuy(buyingOption.option)}>Buy</Button>
            </DialogActions>
        </Dialog>
    )
};

export default PurchaseRedGemsModal;
