import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import LogoTextOnly from '@assets/LogoTextOnly.webp';
// import Submenu from './Submenu';
import { Link, Outlet } from 'react-router-dom';
import Dropdown from './Dropdown';
import { useAuth } from '../AuthContext';
import TemporaryDrawer from './TemporaryDrawer';
import WalletDrawer from './WalletDrawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faBars } from '@fortawesome/free-solid-svg-icons'
import { useNetwork, useAccount } from 'wagmi';
import MakeActiveWalletModal from './MakeActiveWalletModal';
import { bindWallet, linkedWallets, wallets } from '../api/account';
import { LinkedWalletsRespone } from '@interfaces/types';

const Navbar: React.FC = () => {

  const { accountData } = useAuth();
  const { chain } = useNetwork();
  const { address } = useAccount();
  const [open, setOpen] = useState(false);
  const [isActiveWalletDialogOpen, setIsActiveWalletDialogOpen] = useState(false);
  const [openWalletDrawer, setOpenWalletDrawer] = useState(false);
  const [linkedWalletsResponse, setLinkedWalletsResponse] = useState<LinkedWalletsRespone[]>([])
  const [initialRender, setInitialRender] = useState(true);

  const accountSubmenuItems = [
    { label: 'OVERVIEW', link: '/account' },
    { label: 'ACCOUNT SETTINGS', link: '/account-settings' },
    { label: 'TRANSACTIONS LOG', link: '/transactions-log' },
    { label: 'MIDAS VAULT', link: '/midas-vault' },
    { label: 'MANAGE MIDAS', link: '/midas' },
    { label: 'VAMPIRES', link: '/vampires' },

  ];

  const playSubmenuItems = [
    { isForPlay: true, label: 'IOS', link: 'https://apps.apple.com/us/app/kingdom-karnage/id6443679225' },
    { isForPlay: true, label: 'ANDROID', link: 'https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnage&pli=1' },
    { isForPlay: true, label: 'STEAM', link: 'https://store.steampowered.com/app/1305000/Kingdom_Karnage' },
    { isForPlay: true, label: 'ISOTOPIC', link: "https://isotopic.io/game/?game=Kingdom_Karnage" },
    { isForPlay: true, label: 'SEDDONA', link: "https://www.seddona.com/games/KingdomKarnage" },
  ];

  const learnSubmenuItems = [
    { label: 'FAQs', link: '/faq' },
    { label: 'CARD LIBRARY', link: '/card-library' },
    // { label: 'DYNAMIC CARDS', link: '/dynamic-cards' },
    { isForPlay: true, label: 'GUIDE', link: 'https://kepithorstudios.gitbook.io/kingdom-karnage' },
  ];

  const newsSubmenuItems = [
    { isForPlay: true, label: 'UPDATES', link: 'https://kepithorstudios.gitbook.io/kingdom-karnage/patch-notes' },
    { label: 'EVENTS', link: '/events' },
    { isForPlay: true, label: 'MEDIA', link: 'https://drive.google.com/drive/folders/1TUpaZosehIDdVCvXKw3E-KvLEkqEudtA' },
  ];

  const shopSubmenuItems = [
    { label: 'ASSETS SHOP', link: '/shop' },
    { label: 'KINGDOM KARNAGE SHOP', link: '/kingdom-karnage-shop' },
  ];

  const supportSubmenuItems = [
    { label: 'LINKS', link: '/links' },
    // { label: 'CONTACT US', link: '/contact' },
  ];

  const bindWalletRequest = async () => {
    if (accountData) {
      try {
        const response = await bindWallet(accountData.id, accountData.session, accountData.hash, address as unknown as string, "");

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const makeActiveWallet = async () => {
    if (accountData) {
      try {
        const response = await bindWallet(accountData.id, accountData.session, accountData.hash, address as unknown as string, "1");
        setIsActiveWalletDialogOpen(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getAllWallets = async () => {
    if (accountData) {
      try {
        const response = await linkedWallets(accountData.id);
        return response;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (initialRender) {
        setInitialRender(false);
        return;
      }
      if (address !== undefined) {
        bindWalletRequest();
        try {
          const bla = await getAllWallets();
          const currentActive = bla && bla
            .flatMap(obj => Object.keys(obj).find(key => obj[key] === "1"))
            .find(Boolean);

          if (currentActive !== undefined && currentActive !== address as string) {
            setIsActiveWalletDialogOpen(true)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [address]);

  const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(isOpen);
  };

  const toggleWalletDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpenWalletDrawer(isOpen);
  };

  return (
    <div>
      <nav className={`navbar custom-gradient`}>
        <div className="container mx-auto">
          <div className="flex justify-between items-center">

            <div className="md:hidden">
              <button className="text-white" onClick={toggleDrawer(true)}>
                <FontAwesomeIcon icon={faBars} color='white' size='lg' />
              </button>
            </div>

            <div className="logo">
              <Link to="/">
                <img src={LogoTextOnly} alt="LogoTextOnly" />
              </Link>
            </div>

            {accountData ? (
              <div className="md:hidden">
                <button onClick={toggleWalletDrawer(true)}>
                  <FontAwesomeIcon icon={faWallet} color={chain?.id === 56 ? 'white' : "red"} size='lg' />
                </button>
              </div>
            ) : null}

            {/* Larger screens */}
            <div className="hidden md:flex nav-links">

              {accountData ? <div className="menu-item">
                <Dropdown title="ACCOUNT" content={accountSubmenuItems} />
              </div> : null}

              <div className="menu-item">
                <Dropdown title="PLAY" content={playSubmenuItems} />
              </div>

              {accountData ? <div className="menu-item">
                <Dropdown title="MARKETPLACE" content={shopSubmenuItems} />
              </div> : null}

              <div className="menu-item">
                <Dropdown title="LEARN" content={learnSubmenuItems} />
              </div>

              <div className="menu-item">
                <Dropdown title="NEWS" content={newsSubmenuItems} />
              </div>

              <div className="menu-item">
                <Dropdown title="SUPPORT" content={supportSubmenuItems} />
              </div>


              {!accountData ? (
                <>
                  <div className="menu-item">
                    <Link className="dropbtn" to="/login">LOGIN</Link>
                  </div>
                </>
              ) : null}

              {/* {accountData ? (
                <div className="menu-item">
                  <Link className="dropbtn" to="/" onClick={logoutUser}>LOGOUT</Link>
                </div>
              ) : null} */}

              {accountData ? (
                <div className="menu-item menu-item-social">
                  <button onClick={toggleWalletDrawer(true)}>
                    <FontAwesomeIcon icon={faWallet} color={chain?.id === 56 ? 'white' : "red"} size='2x' />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <TemporaryDrawer isOpen={open} toggleDrawer={toggleDrawer} />
        <WalletDrawer isOpen={openWalletDrawer} toggleDrawer={toggleWalletDrawer} />
        <MakeActiveWalletModal isOpen={isActiveWalletDialogOpen} onClose={() => setIsActiveWalletDialogOpen(false)} onConfirm={makeActiveWallet} />

      </nav>
      <Outlet />
    </div>
  );
};


export default Navbar;