import React, { useEffect, useRef, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './MidasVault.scss';
import './ManageMidas.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { buyMidas, getPlayerAssets, selectMidas, vaultInfo } from '@api/account';
import { CharacterData, MidasData, PlayerAsset, VaultInfoResponse } from '@interfaces/types';
import Accordion from '@components/Accordion';
import { useMyContext } from '../MyContext';
import CardList from '@components/CardList';
import HorizontalScroller from '@components/HorizontalScroller';
import { fillMidasCards } from '../helpers/marketItems';
import { toast } from 'react-toastify';
import UIMidasTempleMidasStatue from '@assets/UIMidasTempleMidasStatue.png';
import PurchaseMidasFromStoreModal from '@components/PurchaseMidasFromStoreModal';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../helpers/formating';
import Loader from '@components/Loader';
import { getCharacterDataWithoutContext } from '@api/api';


const ManageMidas: React.FC = () => {
    const isForDesktop = useMediaQuery();
    const { accountData, logout } = useAuth();
    const [characterData, setCharacterData] = useState<CharacterData[]>([]);
    const navigate = useNavigate();
    const [myKingMidases, setMyKingMidases] = useState<PlayerAsset[]>([]);
    const [kingMidases, setKingMidases] = useState<false | MidasData[]>(false);
    const [myKingMidasesDisplaying, setMyKingMidasesDisplaying] = useState<MidasData[] | undefined>([]);
    const [vaultInfoResponse, setVaultInfoResponse] = useState<VaultInfoResponse>();
    const [activeMidas, setActiveMidas] = useState<MidasData[] | undefined>();
    const [isMidasSelected, setIsMidasSelected] = useState<boolean>(false);
    const [isMidasBought, setIsMidasBought] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const openModal = (): void => {
        setIsOpen(true);
    };

    const closeModal = (): void => {
        setIsOpen(false);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await vaultInfo(accountData ? accountData.id : "")
            setVaultInfoResponse(response)
            setIsLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const midasBuy = async() => {
        if (accountData) {
            try {
                const response = await buyMidas(accountData.id, accountData.hash, accountData.session);
                setIsOpen(false);
                if(response.response === "success"){
                    setIsMidasBought(true);
                    toast.success("Midas bought.")
                } else {
                    toast.error("Midas bought error.")
                }
            } catch (error) {
                console.error('Error buying midas:', error);
                toast.error("Midas bought error.")
            }
        }
    }

    const getPlayersAssetsData = async () => {
        if (accountData) {
            try {
                setIsLoading(true);

                const characterDataFetch = await getCharacterDataWithoutContext();
                setCharacterData(characterDataFetch);
                const response = await getPlayerAssets(accountData.id);

                const myMidases = response?.filter(x => x.asset_type === "king_midas")
                const mididi = fillMidasCards(myMidases, characterDataFetch)
                setMyKingMidases(myMidases)
                setMyKingMidasesDisplaying(mididi)
                setActiveMidas(mididi.filter(x => x.active === "1"))

                const kingMidases = characterDataFetch !== undefined && characterDataFetch?.filter(x => x.GameReference.startsWith("kingmidas") && x.GameReference !== "kingmidasstatue") as unknown as MidasData[];
                setKingMidases(kingMidases)

                setIsLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchData();
        getPlayersAssetsData();
    }, [])

    useEffect(() => {
        fetchData();
        getPlayersAssetsData();
    }, [isMidasSelected, isMidasBought])

    const selectMidasReq = async (midasTableId: string) => {
        setIsMidasSelected(false);
        if (accountData) {
            try {
                const result = await selectMidas(
                    accountData.id,
                    accountData.session,
                    accountData?.hash,
                    midasTableId
                );

                if (result.response === "success") {
                    setIsMidasSelected(true);
                    toast.success("Midas equiped.")
                } else if (result.response === "session and hash dont match with server") {
                    toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                    logout();
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Error.")
            }
        }
    };

    const playerXpPerDay = () => {
        const vaultBalance = vaultInfoResponse?.my_kkt_stashed as unknown as number;

        let xp = 0;

        if (vaultBalance >= 1) xp = 1
        if (vaultBalance >= 10) xp = 2
        if (vaultBalance >= 50) xp = 3
        if (vaultBalance >= 100) xp = 4
        if (vaultBalance >= 500) xp = 5
        if (vaultBalance >= 1000) xp = 10
        if (vaultBalance >= 5000) xp = 25
        if (vaultBalance >= 10000) xp = 50
        if (vaultBalance >= 50000) xp = 100
        if (vaultBalance >= 100000) xp = 200
        if (vaultBalance >= 500000) xp = 400
        if (vaultBalance >= 1000000) xp = 800

        return xp;
    }

    const xp = ["0", "3", "6", "10", "25", "50", "75", "100", "250", "500", "750", "1000", "3000", "6000", "10000", "30000", "60000", "100000"]
    const xpDisplay = ["0", "3", "6", "10", "25", "50", "75", "100", "250", "500", "750", "1,000", "3,000", "6,000", "10,000", "30,000", "60,000", "100,000"]
    const myMidasxp = myKingMidases.map(x => x.xp)

    const findRemainingXP = (xpValue: string | undefined): number => {
        const xpNumber = Number(xpValue);
        const index = xp.findIndex(level => Number(level) > xpNumber);

        if (index === -1) {
            return 0;
        }

        const nextXp = xp[index];
        const remainingXp = index === xp.length - 1 ? 0 : Number(nextXp) - xpNumber;

        return remainingXp;
    }

    function calculateShareOfPrizePool(): string {

        const goldenTouchPoints = vaultInfoResponse?.my_midas_points as unknown as number;
        const communityPointsTotal = vaultInfoResponse?.total_midas_points as unknown as number;
        const prizePoolAmount = vaultInfoResponse?.kkt_in_midas_pool as unknown as number;

        const percentage = (goldenTouchPoints / communityPointsTotal) * 100;
        const myShare = (percentage / 100) * prizePoolAmount;

        return myShare.toFixed(2);
    }

    const generateAccordionItems = () => myKingMidases.length > 1 ? [
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        My King Midases
                    </label>
                </div>
            ),
            content: (
                <div >
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                        <label>Equip the Midas you wish to receive XP gains from KKT you have stored in the Midas Vault.</label>
                    </div>
                    <HorizontalScroller items={myKingMidasesDisplaying ? myKingMidasesDisplaying : []} xp={myMidasxp} isCardDisabled={false} selectMidasReq={selectMidasReq} />
                </div>

            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        King Midas XP Progression
                    </label>
                </div>
            ),
            content: (
                <div>
                    <HorizontalScroller items={kingMidases ? kingMidases : []} xp={xpDisplay} isCardDisabled={true} selectMidasReq={selectMidasReq} />
                </div>
            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Buy King Midas
                    </label>
                </div>
            ),
            content: (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <label style={{ display: "flex", marginBottom: "5px", fontWeight: "bold", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                Why buy a King Midas?</label>
            <label style={{ display: "flex", marginBottom: "10px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                <br />
                King Midas offers several benefits including:
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Access to the vault where you can stash KKT. Everyone with KKT stashed can participate in votes about the game (Receive 1 vote per KKT stashed and receive KKT for each vote you make).
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • A playable character in-game who can be levelled up to legendary level 10. KKT stashed in the vault earns your Midas XP.
            </label>
            <label style={{ display: "flex", marginBottom: "15px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Golden Touch. Every time you get a killing blow with Midas you earn Golden Touch points. Each month we pay out a prize pool to Midas holders, the more GT points you have, the bigger your share.
            </label>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Button variant="contained" style={{ width: "100%", height: "50px" }} onClick={openModal}>Buy from store</Button>
            </div>
        </div>
            )
        }
    ]
        : [
            {
                title: (
                    <div className='card-library-accordion-title-wrapper'>
                        <label className='accordion-title-label'>
                            King Midas XP Progression
                        </label>
                    </div>
                ),
                content: (
                    <div >
                        <HorizontalScroller items={kingMidases ? kingMidases : []} xp={xpDisplay} isCardDisabled={true} selectMidasReq={selectMidasReq} />
                    </div>
                ),
            }
        ];

    const generateAccordionItemsMobile = () => myKingMidases.length > 1 ? [
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Midas XP
                    </label>
                </div>
            ),
            content: (
                <div className='mm-mobile-status-box'>
                    <div className='mm-desktop-status-box-text-wrapper'>
                        {newLocal("My vault KKT", `${formatNumber(vaultInfoResponse?.my_kkt_stashed)} KKT`)}
                        {newLocal("XP gain per day", `${playerXpPerDay()} XP`)}
                        {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) === 0 && newLocal("XP to next level", "0")}
                        {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) !== 0 && newLocal("XP to next level", `${findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp)} XP`)}

                    </div>
                </div>

            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Golden Touch
                    </label>
                </div>
            ),
            content: (
                <div className='mm-mobile-status-box'>
                    <div className='mm-desktop-status-box-text-wrapper'>
                        {newLocal("Prize pool amount", `${formatNumber(vaultInfoResponse?.kkt_in_midas_pool)} KKT`)}
                        {newLocal("Community points total", `${formatNumber(vaultInfoResponse?.total_midas_points)} PTS`)}
                        {newLocal("My golden touch points", `${formatNumber(vaultInfoResponse?.my_midas_points)} PTS`)}
                        {newLocal("My share of prize pool", `${formatNumber(calculateShareOfPrizePool())} KKT`)}
                    </div>
                </div>

            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        My King Midases
                    </label>
                </div>
            ),
            content: (
                <div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                        <label>Equip the Midas you wish to receive XP gains from KKT you have stored in the Midas Vault.</label>
                    </div>
                    <HorizontalScroller items={myKingMidasesDisplaying ? myKingMidasesDisplaying : []} xp={myMidasxp} isCardDisabled={false} selectMidasReq={selectMidasReq} />
                </div>

            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        King Midas XP Progression
                    </label>
                </div>
            ),
            content: (
                <div >
                    <HorizontalScroller items={kingMidases ? kingMidases : []} xp={xpDisplay} isCardDisabled={true} selectMidasReq={selectMidasReq} />
                </div>
            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Buy King Midas
                    </label>
                </div>
            ),
            content: (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <label style={{ display: "flex", marginBottom: "5px", fontWeight: "bold", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                Why buy a King Midas?</label>
            <label style={{ display: "flex", marginBottom: "10px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                <br />
                King Midas offers several benefits including:
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Access to the vault where you can stash KKT. Everyone with KKT stashed can participate in votes about the game (Receive 1 vote per KKT stashed and receive KKT for each vote you make).
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • A playable character in-game who can be levelled up to legendary level 10. KKT stashed in the vault earns your Midas XP.
            </label>
            <label style={{ display: "flex", marginBottom: "15px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Golden Touch. Every time you get a killing blow with Midas you earn Golden Touch points. Each month we pay out a prize pool to Midas holders, the more GT points you have, the bigger your share.
            </label>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Button variant="contained" style={{ width: "100%", height: "50px" }} onClick={openModal}>Buy from store</Button>
            </div>
        </div>
            )
        }
    ]
        : [
            {
                title: (
                    <div className='card-library-accordion-title-wrapper'>
                        <label className='accordion-title-label'>
                            Midas XP
                        </label>
                    </div>
                ),
                content: (
                    <div className='mm-mobile-status-box'>
                        <div className='mm-desktop-status-box-text-wrapper'>
                            {newLocal("My vault KKT", `${formatNumber(vaultInfoResponse?.my_kkt_stashed)} KKT`)}
                            {newLocal("XP gain per day", `${playerXpPerDay()} XP`)}
                            {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) === 0 && newLocal("XP to next level", "0")}
                            {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) !== 0 && newLocal("XP to next level", `${findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp)} XP`)}
                        </div>
                    </div>

                ),
            },
            {
                title: (
                    <div className='card-library-accordion-title-wrapper'>
                        <label className='accordion-title-label'>
                            Golden Touch
                        </label>
                    </div>
                ),
                content: (
                    <div className='mm-mobile-status-box'>
                        <div className='mm-desktop-status-box-text-wrapper'>
                            {newLocal("Prize pool amount", `${formatNumber(vaultInfoResponse?.kkt_in_midas_pool)} KKT`)}
                            {newLocal("Community points total", `${formatNumber(vaultInfoResponse?.total_midas_points)} PTS`)}
                            {newLocal("My golden touch points", `${formatNumber(vaultInfoResponse?.my_midas_points)} PTS`)}
                            {newLocal("My share of prize pool", `${formatNumber(calculateShareOfPrizePool())} KKT`)}
                        </div>
                    </div>

                ),
            },
            {
                title: (
                    <div className='card-library-accordion-title-wrapper'>
                        <label className='accordion-title-label'>
                            King Midas XP Progression
                        </label>
                    </div>
                ),
                content: (
                    <div>
                        <HorizontalScroller items={kingMidases ? kingMidases : []} xp={xpDisplay} isCardDisabled={true} selectMidasReq={selectMidasReq} />
                    </div>
                ),
            }
        ];

    const newLocal = (title: string, amount: string) => (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <label style={{ fontFamily: "Ruda", fontSize: "15px", flex: 1 }}>{title}</label>
            <label style={{ fontFamily: "PassionOneRegular", fontSize: "22px", color: "#F90", flex: 1 }}>{amount}</label>
        </div>
    );

    const newLocalMobile = (title: string, amount: string) => (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <label style={{ fontFamily: "Ruda", fontSize: "12px", flex: 1 }}>{title}</label>
            <label style={{ fontFamily: "PassionOneRegular", fontSize: "19px", color: "#F90", flex: 1 }}>{amount}</label>
        </div>
    );

    const nonMidasInteraction = () => {
        return <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <label style={{ fontFamily: "ruda", fontWeight: "bold", fontSize: "20px" }}>It would appear you do not own a King Midas!</label>
            <br />
            <label style={{ display: "flex", marginBottom: "5px", fontWeight: "bold", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                Why buy a King Midas?</label>
            <label style={{ display: "flex", marginBottom: "10px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>
                <br />
                King Midas offers several benefits including:
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Access to the vault where you can stash KKT. Everyone with KKT stashed can participate in votes about the game (Receive 1 vote per KKT stashed and receive KKT for each vote you make).
            </label>
            <label style={{ display: "flex", marginBottom: "5px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • A playable character in-game who can be levelled up to legendary level 10. KKT stashed in the vault earns your Midas XP.
            </label>
            <label style={{ display: "flex", marginBottom: "15px", justifyContent: "flex-start", alignItems: "flex-start", textAlign: "start", fontStyle: "italic" }}>

                • Golden Touch. Every time you get a killing blow with Midas you earn Golden Touch points. Each month we pay out a prize pool to Midas holders, the more GT points you have, the bigger your share.
            </label>
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Button variant="contained" style={{ width: "100%", height: "50px" }} onClick={openModal}>Buy from store</Button>
            </div>
        </div>;
    }

    const renderContent = () => {
        return <>
            {isForDesktop && <>
                <div className='mm-desktop-status-container' style={{ marginBottom: "30px" }}>
                    <div className='mm-desktop-status-box-container'>
                        <div style={{ display: "flex", width: "100%" }}>
                            <label style={{ fontFamily: "PassionOneRegular", fontSize: "25px" }}>Midas XP</label>
                        </div>

                        <div className='mm-desktop-status-box'>
                            <div className='mm-desktop-status-box-text-wrapper'>
                                {newLocal("My vault KKT", `${formatNumber(vaultInfoResponse?.my_kkt_stashed)} KKT`)}
                                {newLocal("XP gain per day", `${playerXpPerDay()} XP`)}
                                {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) === 0 && newLocal("XP to next level", "0")}
                                {findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp) !== 0 && newLocal("XP to next level", `${findRemainingXP(myKingMidases.find(x => x.active === "1")?.xp)} XP`)}
                            </div>
                        </div>
                    </div>

                    {activeMidas?.length !== 0 && <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", marginRight: "20px", marginLeft: "20px" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <label style={{ fontFamily: "PassionOneRegular", fontSize: "25px" }}>Equipped Midas</label>
                        </div>
                        <CardList CharacterData={activeMidas ? activeMidas : []} />
                    </div>}

                    {activeMidas?.length === 0 && <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", marginRight: "20px", marginLeft: "20px" }}>
                        <img style={{ height: "245px" }} src={UIMidasTempleMidasStatue} alt='UIMidasTempleMidasStatue' />
                    </div>}

                    <div className='mm-desktop-status-box-container'>
                        <div style={{ display: "flex", width: "100%" }}>
                            <label style={{ fontFamily: "PassionOneRegular", fontSize: "25px" }}>Golden Touch</label>
                        </div>
                        <div className='mm-desktop-status-box'>
                            <div className='mm-desktop-status-box-text-wrapper'>
                                {newLocal("Prize pool amount", `${formatNumber(vaultInfoResponse?.kkt_in_midas_pool)} KKT`)}
                                {newLocal("Community points total", `${formatNumber(vaultInfoResponse?.total_midas_points)} PTS`)}
                                {newLocal("My golden touch points", `${formatNumber(vaultInfoResponse?.my_midas_points)} PTS`)}
                                {newLocal("My share of prize pool", `${formatNumber(calculateShareOfPrizePool())} KKT`)}
                            </div>
                        </div>
                    </div>
                </div>
                {activeMidas?.length === 0 && <Paper elevation={11} style={{ display: "flex", flexDirection: "column", padding: "20px", width: "773px" }}>
                    {nonMidasInteraction()}
                </Paper>}
                <Accordion items={generateAccordionItems()} activeOne={0} />
            </>}

            {!isForDesktop && <>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {activeMidas?.length !== 0 && <div style={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "center", marginRight: "20px", marginLeft: "20px" }}>
                        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                            <label style={{ fontFamily: "Ruda", fontSize: "20px" }}>Equipped Midas</label>
                        </div>
                        <CardList CharacterData={activeMidas ? activeMidas : []} />
                    </div>}

                    {activeMidas?.length === 0 && <Paper elevation={11} style={{ display: "flex", flexDirection: "column", width: "90%", padding: "15px" }}>
                        {nonMidasInteraction()}
                    </Paper>}

                    <Accordion items={generateAccordionItemsMobile()} activeOne={0} />
                </div>
            </>}
        </>
    }

    return (
        <div className="page-content" style={{ position: "relative" }}>
            <div className='page-title-container' style={{ zIndex: 1 }}>
                <label className='page-title'>Manage Midas</label>
            </div>

            <PurchaseMidasFromStoreModal isOpen={isOpen} onBuy={midasBuy} onClose={closeModal} />

            {isLoading ? <Loader isLoading={true} /> : renderContent()}

        </div>
    );
};

export default BackgroundTemplate(ManageMidas);
