import { ButtonBase } from '@mui/material';
import React, { ReactNode } from 'react';

interface WrapperProps {
    isBlacklisted: boolean;
    children: ReactNode;
}

const BlacklistWrapper: React.FC<WrapperProps> = ({ isBlacklisted, children }) => {
    if (isBlacklisted) {
        return <ButtonBase disabled={isBlacklisted}>
            {children}
        </ButtonBase>
    }

    return <>{children}</>;
};

export default BlacklistWrapper;
