export interface CharacterData {
  id: string;
  GameReference: string;
  Level: string;
  Title: string;
  Type: string;
  Quality: string;
  Countdown: string;
  Damage: string;
  Health: string;
  Ability1: string;
  Ability2: string;
  Ability3: string;
  tradable: string;
}

export interface MidasData extends CharacterData {
  midas_table_id: string;
  active: string;
}

export interface VampireData extends CharacterData {
  xp: string;
}

export interface ItemsData {
  id: string;
  gamereference: string;
  level: string;
  title: string;
  type: string;
  quality: string;
  health: string;
  ability1: string;
  ability2: string;
  ability3: string;
  tradable: string;
}

export interface AccordionItem {
  title: React.ReactNode;
  content: React.ReactNode;
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface ShopItem {
  id: string;
  title: string;
  price: number;
  discountInPercentage: number | null;
  description: string[];
  count?: number;
}

// {"response":"registration successful","error":""}
// {"response":"email already registered","error":""}

export interface RegistrationResponse {
  response: string;
  error: string;
}


export interface Account {
  response: string;
  email: string;
  session: string;
  hash: string;
  id: string;
  error: string;
  failedlogins: string;
  blacklist: string;
  displayname: string;
  red_gems: string;
  is2fa_enabled: boolean;
  competition: string;
  failedtime: string;
  kyc_purchased: string;
  kyc_status: string;
  referred_by: string;
  referral_code: string;
  referral_magic: string;
  register_time: string;
  kk_pvp_rating: string;
  kk_dailyPvP: string;
  diamonds: string;
  pioneerspride_received: string;
  midas_points: string;
  dungeons_available: string;
  pvp_check_in_today: string;
  advert_check_in_today: string;
  pvp_check_in_progress: string;
  advert_check_in_progress: string;
  kkh_pvp_rating: string;
  kkh_tower_started: string;
  kkh_tower_stage: string;
  herocoin: string;
}

export interface WagmiStore {
  state: WagmiState;
}

export interface WagmiState {
  chains: object;
  data: WagmiData;
}

export interface WagmiData {
  account: string;
  chain: object;
}

export interface DepositKKTResponse {
  response: string;
  status: string;
}

export interface KktTransactions {
  response: string;
  transactions: Transaction[];
}

export interface Transaction {
  amount: string;
  date: string;
  datetime: string;
  direction: string;
  fee: string;
  status: string;
  transactionHash: string;
}

export interface KktBalance {
  balance: string;
  response: string;
}

export interface KktValueInDollars {
  kkt_value: string;
  blockpass_starts: string;
  response: string;
}

export interface WithdrawKKTResponse {
  response: string;
  transactionHash: string;
}

export interface PioneerQuest {
  month: string;
  prize: string;
  quest: string;
  progress: string;
}

export interface PioneerQuestResponse {
  month: string;
  progress: string;
}

export interface KKPvpResponse {
  data: KKPvpRanking[];
  response: string;
}

export interface KKPvpRanking {
  position: string;
  name: string;
  score: string;
  prize: string;
}

export interface KKPvpSearchResponse {
  data: KKPvpSearch[];
  response: string;
}

export interface KKPvpSearch {
  name: string;
  score: string;
}

export interface LogoutRepsponse {
  response: string;
  transactionHash: string;
}

export interface PlayerCardsResponse {
  id: string;
  card_id: string;
  amount: string;
  type: string;
}

export interface PlayerItemsResponse {
  id: string;
  item_id: string;
  amount: string;
  type: string;
}

export interface MarketItemData {
  id: string;
  GameReference: string;
  Level: number;
  Title: string;
  Race: RaceValues;
  Quality: CardQuality;
  PricePerItem: number;
  Amount: number;
}

export interface MarketItemDataNew {
  auction_id?: string;
  asset_id: string;
  asset_type: string;
  amount: number;
  quality: CardQuality;
  game_reference: string;
  level: number;
  title: string;
  token_index: string;
  image_name: string;
  price?: number;
  seller_id?: string;
}

export interface MarketItemDataListed {
  auction_id: string
  asset_id: string;
  asset_type: string;
  amount: number;
  quality: CardQuality;
  game_reference: string;
  level: number;
  title: string;
  token_index: string;
  price: string;
  image_name: string;
}

export interface TransactionLog {
  amount: string;
  date: string;
  datetime: string;
  new_balance: string;
  reason: string;
  seen: string;
  asset_id: string;
  type: string
}

export interface PlayerAsset {
  account_type: string;
  active: string;
  amount: string;
  asset_id: string;
  asset_type: string;
  bound: string;
  dead: string;
  in_game: string;
  key_type: string;
  kills: string;
  level: string;
  quality: string;
  table_id: string;
  title: string;
  token_index: string;
  token_number: string;
  used_today: string;
  xp: string;
}

export interface MyAuction {
  asset_id: string;
  auction_amount: string;
  auction_sell_index: string;
  id: string;
  price: string;
  type: string;
}

export interface SellResponse {
  asset_id: string;
  balance: string;
  hash_recorded: string;
  response: string;
  sessiontime: string;
  status: string;
}

export interface GetAuctionResponse {
  asset_id: string;
  auction_amount: string;
  auction_sell_index: string;
  id: string;
  price: string;
  rarity: string;
  sellers_id: string;
  type: string;
}

export interface ForgetPasswordResponse {
  error: string;
  link: string;
  response: string;
}

export interface VaultInfoResponse {
  answer1: string;
  answer2: string;
  answer3: string;
  answer4: string;
  my_kkt_stashed: string;
  my_midas_points: string;
  my_rewards: string;
  my_voting_power: string;
  question: string;
  total_kkt_stashed: string;
  total_voting_power: string;
  kkt_in_voting_pool: string;
  kkt_in_midas_pool: string;
  total_midas_points: string;
  vote_active: string;
  response: string;
  vote_results: VaultVoting[];
}

export interface VaultVoting {
  count: string;
  sum_rewards: string;
  sum_voting_power: string;
  vote_for: string;
}

export interface LinkedWalletsRespone {
  [address: string]: string;
}

export interface GetCoffinsResponse {
  coffins: Coffins[];
  keys: Key[];
  lilith_nfts: VampireNft[];
  satrina_nfts: VampireNft[]
  response: string;
}

export interface NftMintResponse {
  minted_url: string;
  response: string;
}

export interface Coffins {
  coffin_id: string;
  coffin_used: string;
}

export interface Key {
  key_id: string;
  key_used: string;
}

export interface VampireNft {
  id: string;
  title: string;
  level: string;
  xp: string;
}

export enum CardQuality {
  All = "",
  Common = "Common",
  Uncommon = "Uncommon",
  Rare = "Rare",
  Epic = "Epic",
  Legendary = "Legendary"
}

export enum RaceValues {
  All = "",
  Human = "Human",
  Undead = "Undead",
  Lycan = "Lycan",
  Elven = "Elven",
  Goblin = "Goblin",
  Structure = "Structure",
}
