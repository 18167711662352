import React, { } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './Events.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { Paper, Typography } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    const isForDesktop = useMediaQuery();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >Privacy Policy</label>
            </div>
            {isForDesktop && <Paper style={{ width: "70%", height: "100%", marginBottom: "40px", display: "flex", flexDirection: "column", padding: "20px", justifyContent: "flex-start" }} elevation={24}>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in UK privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                </Typography>
                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    What personal information do we collect from the people that visit our website or play our games?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    When registering on our site, as appropriate, you may be asked to enter your username, email address or other details to help you with your experience.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    When do we collect information?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    We collect information from you when you register on our site or enter information on our site.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    How do we use your information?
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    We may use the information we collect from you when you register, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                        <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                        <li>To improve our website in order to better serve you.</li>
                        <li>To allow us to better service you in responding to your customer service requests.</li>
                        <li>To administer a contest, promotion, survey or other site feature.</li>
                        <li>To send periodic emails regarding services.</li>
                    </ul>
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    How do we protect visitor information?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
                    We use regular Malware Scanning.
                    We use SSL certificates to encrypt data.

                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Do we use 'cookies'?
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognise your browser and capture and remember certain information. For instance, we use cookies to help us remember if you are logged in. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                    <br />
                    We use cookies to:
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                        <li>Understand and save user's preferences for future visits.</li>
                        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.</li>
                    </ul>

                    <br />
                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
                    <br />
                    If users disable cookies in their browser:
                    <br />
                    If you disable cookies off, some features will be disabled It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.

                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Third Party Disclosure
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
                    <br />
                    However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.

                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Third party links
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Advertising
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    We may use Advertising on our website and in our games.
                    <br />
                    Advertisers, as third party vendors, uses cookies to serve ads on our site and games. Advertising Networks use of cookies enables them to serve ads to our users based on their visit to our site and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy, or other advertising networks website.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    We have implemented Demographics and Interests Reporting
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    We along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website.
                    <br />

                    Opting out:
                    Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising initiative opt out page or permanently using the Google Analytics Opt Out Browser add on.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    UK Data Protection Act 1998
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to submit a request via email team(at)kepithorstudios.com.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    How does our site handle do not track signals?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Should you not accept the we use cookies prompt, cookies will not be used and you will not be able to use the site in its full capacity. You will be able to view non-member pages only.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Does our site allow third party behavioral tracking?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    It's also important to note that we allow third party behavioral tracking such as Google Analytics.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Fair Information Practices
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                    We will notify the users via in site notification within 7 business days.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Contacting Us
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    If there are any questions regarding this privacy policy you may contact us by emailing team(at)kepithorstudios.com.

                    <br />
                    <br />
                    Last Edited on 28/4/2022
                </Typography>

            </Paper>}

            {!isForDesktop && <Paper style={{ width: "95%", height: "100%", display: "flex", flexDirection: "column", padding: "20px", marginBottom: "40px" }} elevation={24}>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in UK privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    What personal information do we collect from the people that visit our website or play our games?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    When registering on our site, as appropriate, you may be asked to enter your username, email address or other details to help you with your experience.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    When do we collect information?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    We collect information from you when you register on our site or enter information on our site.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    How do we use your information?
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    We may use the information we collect from you when you register, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                        <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                        <li>To improve our website in order to better serve you.</li>
                        <li>To allow us to better service you in responding to your customer service requests.</li>
                        <li>To administer a contest, promotion, survey or other site feature.</li>
                        <li>To send periodic emails regarding services.</li>
                    </ul>
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    How do we protect visitor information?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
                    We use regular Malware Scanning.
                    We use SSL certificates to encrypt data.

                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Do we use 'cookies'?
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognise your browser and capture and remember certain information. For instance, we use cookies to help us remember if you are logged in. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
                    <br />
                    We use cookies to:
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                        <li>Understand and save user's preferences for future visits.</li>
                        <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.</li>
                    </ul>

                    <br />
                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
                    <br />
                    If users disable cookies in their browser:
                    <br />
                    If you disable cookies off, some features will be disabled It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.

                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Third Party Disclosure
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
                    <br />
                    However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.

                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Third party links
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Advertising
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    We may use Advertising on our website and in our games.
                    <br />
                    Advertisers, as third party vendors, uses cookies to serve ads on our site and games. Advertising Networks use of cookies enables them to serve ads to our users based on their visit to our site and other sites on the Internet. Users may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy, or other advertising networks website.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    We have implemented Demographics and Interests Reporting
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    We along with third-party vendors, such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions, and other ad service functions as they relate to our website.
                    <br />

                    Opting out:
                    Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising initiative opt out page or permanently using the Google Analytics Opt Out Browser add on.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    UK Data Protection Act 1998
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please write to submit a request via email team(at)kepithorstudios.com.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    How does our site handle do not track signals?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Should you not accept the we use cookies prompt, cookies will not be used and you will not be able to use the site in its full capacity. You will be able to view non-member pages only.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Does our site allow third party behavioral tracking?
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    It's also important to note that we allow third party behavioral tracking such as Google Analytics.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Fair Information Practices
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
                    We will notify the users via in site notification within 7 business days.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>

                    Contacting Us
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    If there are any questions regarding this privacy policy you may contact us by emailing team(at)kepithorstudios.com.

                    <br />
                    <br />
                    Last Edited on 28/4/2022
                </Typography>


            </Paper>}

        </div>
    );
};

export default BackgroundTemplate(PrivacyPolicy);
