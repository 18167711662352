import React, { useEffect, useState } from 'react';
import './Modal.scss';
import { getKktValueInDollars } from '../api/account';
import { useAuth } from '../AuthContext';
import '../pages/Account.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%",
        }
    }
}));

interface PurchaseMidasFromStoreModalProps {
    isOpen: boolean;
    onConfirm: () => Promise<void>;
    onClose: () => void;
}

const MakeActiveWalletModal: React.FC<PurchaseMidasFromStoreModalProps> = ({ isOpen, onConfirm, onClose }) => {
    const { accountData } = useAuth();
    const [priceInKkt, setPriceInKkt] = useState<string>("");

    const calculatePriceInKkt = (kktValue: number) => {
        const updatedOptions = (parseFloat("50") / kktValue).toFixed(2);
        setPriceInKkt(updatedOptions);
    };

    useEffect(() => {
        const kktValueInDollars = async () => {
            if (accountData) {
                try {
                    const response = await getKktValueInDollars(accountData ? accountData.id : "");
                    calculatePriceInKkt(parseFloat(response.kkt_value));

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        kktValueInDollars();
    }, []);
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Would you like to set your recently connected wallet to active?"}
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ padding: '10px' }}>
                        <strong>Important Notice:</strong> Please note that only one wallet can be active at a time. Active wallets are the ones scanned for NFTs during gameplay. If a selected wallet doesn't contain any NFTs, nothing will be displayed in the game.
                    </div>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
};

export default MakeActiveWalletModal;
