import { FC, useEffect, useState } from 'react';
import './HorizontalScroller.scss';
import { CharacterData, MidasData, VampireData, VampireNft } from '@interfaces/types';
import MidasList from './MidasList';
import { useMyContext } from '../MyContext';
import CardList from './CardList';
import { Button } from '@mui/material';

interface VampireHorizontalScrollerProps {
    items: VampireNft[];
}

const VampireHorizontalScroller: FC<VampireHorizontalScrollerProps> = ({ items }) => {
    const { characterData } = useMyContext();
    const [vampireData, setVampireData] = useState<CharacterData[]>([]);

    useEffect(() => {
        const matchedData: CharacterData[] = [];

        if (characterData) {
            items.forEach(nft => {
                const matchingCharacter = characterData.find(character =>
                    character.Title === nft.title && character.Level === nft.level
                );

                if (matchingCharacter) {
                    matchedData.push(matchingCharacter);
                }
            });
        }
        setVampireData(matchedData);
    }, []);

    const bll = items.map((item, index) => {
        if (characterData) {
            const matchingCharacter1 = characterData.find(character =>
                character.Title === item.title && character.Level === item.level
            );
    
            return (
                <div key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "10px" }}>
                    <label style={{ fontFamily: "PassionOneRegular", fontSize: "22px", color: "rgb(255, 153, 0)" }}>XP: {item.xp}</label>
                    <CardList CharacterData={matchingCharacter1 ? [matchingCharacter1] : []} />
                    {/* <Button style={{marginTop: "-40px"}} disabled>Upgrade</Button> */}
                </div>
            );
        }
        return <></>;
    });

    return (
        <div >
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center" }}>
                {bll}
            </div>
        </div>
    );
};

export default VampireHorizontalScroller;
