import React, { useState, useEffect } from 'react';
import './Accordion.scss';
import { AccordionItem } from '@interfaces/types';
import icnMinus from '@assets/icnMinus.webp';
import icnPlus from '@assets/icnPlus.webp';

interface AccordionProps {
  items: AccordionItem[];
  activeOne: number | null;
}

const Accordion: React.FC<AccordionProps> = ({ items, activeOne }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(activeOne);

  useEffect(() => {
    setActiveIndex(activeOne);
  }, [activeOne]);

  const handleClick = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item">
          <button
            className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleClick(index)}
          >
            {item.title}
            {activeIndex === index ? <img style={{ height: "2rem", paddingBottom: "5px" }} src={icnMinus} alt={`${icnMinus}`} /> : <img style={{ height: "2rem", paddingBottom: "5px" }} src={icnPlus} alt={`${icnPlus}`} />}
          </button>
          {activeIndex === index && (
            <div className="accordion-content">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
