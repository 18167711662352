import React, { useState } from 'react';
import image6 from '../assets/image6.webp';
import KKLogo from '../assets/KKLogo.webp';
import './Login.scss';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/Loader';
import { register } from '@api/account';

interface RegisterProps {
    ipAddress: string;
}

const Register: React.FC<RegisterProps> = ({ ipAddress }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [referalCode, setReferalCode] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleReferalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReferalCode(e.target.value);
    };

    const handleRegister = async () => {
        setIsLoading(true);
        try {
            await register(ipAddress, email, username, password, referalCode);

        } catch (error) {
            console.error('Login failed:', error);
        } finally {
            setIsLoading(false);
            navigate('/');
        }
    };
    return (
        <div className="login-container " style={{ backgroundImage: `url(${image6})` }}>
            <img className='login-logo' src={KKLogo} alt="KKLogo" />
            {!isLoading && <div className="login">
                <form className='form'>
                    <div className="form-group">
                        <label className='label'>Email:</label>
                        <input className='input'
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className='label'>Username:</label>
                        <input className='input'
                            type="username"
                            value={username}
                            onChange={handleUsernameChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className='label'>Password:</label>
                        <input className='input'
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="form-group">
                        <label className='label'>Referal code:</label>
                        <input className='input'
                            value={referalCode}
                            onChange={handleReferalCodeChange}
                        />
                    </div>

                    <button type="button" onClick={handleRegister} style={{paddingLeft: "15px", paddingRight: "15px"}}>
                        REGISTER
                    </button>
                </form>
            </div>}
            {isLoading && <Loader isLoading={isLoading} />}
        </div>
    );
};

export default Register;

