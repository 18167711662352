import React, {
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { CharacterData, ItemsData } from '@interfaces/types';
import { getCharacterData, getIPAddress } from '@api/api';
import { getItemData } from '@api/account';

interface MyContextType {
  characterData: CharacterData[] | null;
  setCharacterData: Dispatch<SetStateAction<CharacterData[] | null>>;
  itemsData: ItemsData[] | null;
  setItemsData: Dispatch<SetStateAction<ItemsData[] | null>>;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (context === undefined) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};

interface MyProviderProps {
  children: ReactNode;
}

const MyProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [characterData, setCharacterData] = React.useState<CharacterData[] | null>(null);
  const [itemsData, setItemsData] = React.useState<ItemsData[] | null>(null);

  useEffect(() => {
    getCharacterData()
      .then((response) => {
        setCharacterData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

      getItemData()
      .then((response) => {
        setItemsData(response);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    getIPAddress();
  }, []);

  return (
    <MyContext.Provider value={{ characterData, setCharacterData, itemsData, setItemsData }}>
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;