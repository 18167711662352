import './main.css';
import Navbar from '@components/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CardLibrary from '@pages/CardLibrary';
import CharacterDetails from '@pages/CharacterDetails';
import MyProvider from './MyContext';
import StartPage from '@pages/StartPage';
import ShopBasket from '@pages/ShopBasket';
import Login from '@pages/Login';
import ForgotPassword from '@pages/ForgotPassword';
import { AuthProvider } from './AuthContext';
import AccountPage from '@pages/AccountPage';
import { WagmiConfig } from 'wagmi';
import wagmiConfig from './config/web3ModalConfig';
import WalletContextProvider from './WalletContext';
import Events from '@pages/Events';
import Shop from '@pages/Shop';
import MarketItemDetails from '@pages/MarketItemDetails';
import SellItem from '@pages/SellItem';
import TransactionsLog from '@pages/TransactionsLog';
import { useEffect, useRef, useState } from 'react';
import { getIPAddress } from './api/account';
import Register from '@pages/Register';
import RedGemsShop from '@pages/RedGemsShop';
import MidasVault from '@pages/MidasVault';
import ManageMidas from '@pages/ManageMidas';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './assets';
import Faq from '@pages/Faq';
import Links from '@pages/Links';
import AccountSettings from '@pages/AccountSettings';
import Eula from '@pages/Eula';
import PrivacyPolicy from '@pages/PrivacyPolicy';
import TermsOfService from '@pages/TermsOfService';
import Vampires from '@pages/Vampires';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App: React.FC = () => {
  const isMounted = useRef(false);
  const [ipAddress, setIpAddress] = useState<string>("");

  useEffect(() => {
    const getkkPvp = async () => {
      try {
        const response = await getIPAddress();
        setIpAddress(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isMounted.current) {
      getkkPvp();
    }
    else {
      isMounted.current = true;
    }
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <WagmiConfig config={wagmiConfig}>
        <MyProvider>
          <WalletContextProvider>
            <AuthProvider>
              <BrowserRouter>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Navbar />} />
                  <Route index element={<StartPage />} />
                  <Route path="account" element={<AccountPage />} />
                  <Route path="account-settings" element={<AccountSettings />} />
                  <Route path="transactions-log" element={<TransactionsLog />} />
                  <Route path="midas-vault" element={<MidasVault />} />
                  <Route path="midas" element={<ManageMidas />} />
                  <Route path="shop" element={<Shop />} />
                  <Route path="vampires" element={<Vampires />} />
                  <Route path="kingdom-karnage-shop" element={<RedGemsShop />} />
                  <Route path="shop/basket" element={<ShopBasket />} />
                  {/*  <Route path="play-ios" element={<PlayIos />} />
    <Route path="play-android" element={<PlayAndroid />} />
    <Route path="play-steam" element={<PlaySteam />} />
    <Route path="faq" element={<Faq />} /> */}
                  <Route path="faq" element={<Faq />} />
                  <Route path="links" element={<Links />} />
                  <Route path="card-library" element={<CardLibrary />} />
                  {/* <Route path="dynamic-cards" element={<DynamicCards />} /> */}
                  <Route path="character-details/:escapedTitle" element={<CharacterDetails />} />
                  <Route path="item-details/:gameReference" element={<MarketItemDetails />} />
                  <Route path="sell-item/:gameReference" element={<SellItem />} />
                  <Route path="events" element={<Events />} />
                  {/* <Route path="guide" element={<Guide />} />
    <Route path="updates" element={<Updates />} />
    <Route path="events" element={<Events />} />
    <Route path="media" element={<Media />} />
    <Route path="links" element={<Links />} />
    <Route path="contact" element={<Contact />} />*/}
                  <Route path="login" element={<Login ipAddress={ipAddress} />} />
                  <Route path="register" element={<Register ipAddress={ipAddress} />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />

                  <Route path="eula" element={<Eula />} />
                  <Route path="privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="terms" element={<TermsOfService />} />

                </Routes>
              </BrowserRouter>
            </AuthProvider>
          </WalletContextProvider>
        </MyProvider>
      </WagmiConfig>
    </ThemeProvider>
  );
};

export default App;
