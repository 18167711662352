import React, { useEffect, useState } from 'react';
import './Modal.scss';
import { getKktValueInDollars } from '../api/account';
import { useAuth } from '../AuthContext';
import KKTicker from '@assets/KKTicker.webp';
import '../pages/Account.scss';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%",
            height: "320px"
        }
    }
}));
interface ChangeNickModalProps {
    isOpen: boolean;
    onClose: () => void;
    changeUsername: (newUsername: string) => Promise<void>;
}

const ChangeNickModal: React.FC<ChangeNickModalProps> = ({ isOpen, onClose, changeUsername }) => {
    const { accountData } = useAuth();
    const [priceInKkt, setPriceInKkt] = useState<string>("");
    const [newNick, setNick] = useState<string>(accountData ? accountData?.displayname : "");
    const [isValid, setIsValid] = useState<boolean>(true);
    const [currentUsername, setCurrentUsername] = useState<string>(accountData ? accountData?.displayname : "");

    const calculatePriceInKkt = (kktValue: number) => {
        const updatedOptions = (parseFloat("1") / kktValue).toFixed(2);
        setPriceInKkt(updatedOptions);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (accountData) {
                try {
                    const response = await getKktValueInDollars(accountData.id);
                    calculatePriceInKkt(parseFloat(response.kkt_value));
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };

        fetchData();
        setCurrentUsername(accountData ? accountData.displayname : "");
    }, [accountData]);

    // if (!isOpen) return null;

    const handleClick = async () => {
        await changeUsername(newNick);
    };

    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Are you sure, you want to change username?"}
            </DialogTitle>
            <DialogContent>
                <TextField margin="normal" label={"New username"} style={{ width: "100%" }} error={isValid ? false : true} helperText={isValid ? "" : "Invalid input. Only alphanumeric characters allowed and length should be between 1 and 35."} value={newNick} onChange={(e) => {
                    const inputValue = e.target.value;
                    const pattern = /^[a-zA-Z0-9]{1,35}$/;

                    if (pattern.test(inputValue) || inputValue === '') {
                        setNick(inputValue);
                        setIsValid(true);
                    } else {
                        setIsValid(false);
                    }
                }} />
                <label style={{ fontFamily: "PassionOneRegular", fontSize: "1.5rem", display: "flex" }}>{priceInKkt} <img style={{ height: "2rem" }} src={KKTicker} alt={`${KKTicker}`} /></label>
                <label style={{ fontFamily: "Ruda", fontSize: "10px", fontStyle: "italic", marginBottom: "10px" }}>($1 in KKT)</label>


            </DialogContent>
            <DialogActions>
                <Button disabled={!isValid || newNick === currentUsername || newNick === ""} onClick={handleClick}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
};

export default ChangeNickModal;
