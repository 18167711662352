import useMediaQuery from '@hooks/useMediaQuery';
import React, { useEffect, useState } from 'react';
import ImageButton from './ImageButton';
import Button_Blank2 from '@assets/Button_Blank2.webp';


interface IncrementDecrementProps {
    onCountChange: (count: number) => void;
    incrementItem: (id: string) => void;
    decrementItem: (id: string) => void;
    amount: number;
    maxAmount: number
    id: string
}

const IncrementDecrementInput: React.FC<IncrementDecrementProps> = ({ onCountChange, incrementItem, decrementItem, amount, id, maxAmount }) => {
    const isForDesktop = useMediaQuery();
    useEffect(() => {
        setCount(amount ? amount : 0)
    }, [amount])

    const [count, setCount] = useState(1);

    const handleIncrement = () => {
        if(count < maxAmount){
            setCount(count + 1);
            onCountChange(count + 1);
            incrementItem(id);
        }
    };

    const handleDecrement = () => {
        if (count > 0) {
            setCount(count - 1);
            onCountChange(count - 1);
            decrementItem(id);
        }
    };

    return (
        <>
            <div style={{ display: 'flex' }}>
                {forMobileLayout()}
            </div>
        </>
    );

    function forMobileLayout() {
        return <div style={{ display: 'flex' }}>
            <ImageButton src={Button_Blank2} handleItemClick={() => handleDecrement()} style={{ width: "50px", height: "50px" }} alt={"Button_Blank2"} text='-' />
            <label style={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "60px", fontSize: "20px" }}>{count}</label>
            <ImageButton src={Button_Blank2} handleItemClick={() => handleIncrement()} style={{ width: "50px", height: "50px" }} alt={"Button_Blank2"} text='+' />
        </div>;
    }
};

export default IncrementDecrementInput;
