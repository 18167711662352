import { ComponentType } from 'react';
import './BackgroundTemplate.scss';
import KKLogo from '@assets/KKLogo.webp';
import useMediaQuery from '../hooks/useMediaQuery';
import IconLink from '@components/IconLink';
import { faX } from '@fortawesome/free-solid-svg-icons'

import { faDiscord, faFacebook, faTelegram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


interface BackgroundTemplateProps { }

const BackgroundTemplate = <P extends BackgroundTemplateProps>(
  WrappedComponent: ComponentType<P>
) => {
  return (props: P) => {
    const isForDesktop = useMediaQuery();

    return (
      <div className="background-container">
        <WrappedComponent {...props} />

        {isForDesktop && <div style={{ bottom: 0, width: "100%" }}>
          <div style={{ width: "100%", display: "flex", height: "180px", paddingLeft: "50px", paddingRight: "50px", background: "linear-gradient(to bottom, #424347, #26282F)" }}>
            <div style={{ width: "30%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <img style={{ width: "170px" }} src={KKLogo} />
            </div>
            <div style={{ width: "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <Link to={'/privacypolicy'} style={{ margin: "10px", color: "#B2BEB5" }}>
                  {"PRIVACY POLICY"}
                </Link>
                <Link style={{ margin: "10px", color: "#B2BEB5" }} to={"/terms"}>
                  {'TERMS AND CONDITIONS'}
                </Link>
                <Link style={{ margin: "10px", color: "#B2BEB5" }} to={"/eula"} >
                  {"EULA"}
                </Link>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <div className="menu-item menu-item-social">
                  <IconLink icon={faFacebook} link='https://www.facebook.com/KepithorStudios/' text='' size='2x' />
                </div>

                <div className="menu-item menu-item-social">
                  <IconLink icon={faTelegram} link='https://t.me/Kepithor' text='' size='2x' />
                </div>

                <div className="menu-item menu-item-social">
                  <IconLink icon={faX} link='https://twitter.com/kingdom_karnage/' text='' size='2x' />
                </div>

                <div className="menu-item menu-item-social">
                  <IconLink icon={faDiscord} link='https://discord.com/invite/ufkg8bCCnr' text='' size='2x' />
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  };
};

export default BackgroundTemplate;
