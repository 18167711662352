import React, { useEffect, useState } from 'react';
import './TokenTransferForm.scss';
import { useAuth } from '../AuthContext';
import { useWalletContext } from '../WalletContext';
import { getKktValueInDollars, withdraw } from '@api/account';
import KKTicker from '@assets/KKTicker.webp';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const WithdrawTokenForm: React.FC = () => {
    const navigate = useNavigate();
    const { accountData, logout } = useAuth();
    const { wagmiStore } = useWalletContext();


    const [tokenAmount, setTokenAmount] = useState<string>('1');
    const [kktValueInDollars, setKktValueInDollars] = useState<string>('1');

    useEffect(() => {
        const kktValueInDollars = async () => {
            if (accountData) {
                try {
                    const response = await getKktValueInDollars(accountData ? accountData.id : "");
                    setKktValueInDollars(response.kkt_value)

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        kktValueInDollars();
    }, []);

    const withdrawKkt = async () => {
        const wagmiStore = localStorage.getItem('wagmi.store');

        if (accountData && wagmiStore) {
            const wagmiData = JSON.parse(wagmiStore);
            const wallet = wagmiData?.state?.data?.account as string;
            
            try {
                const response = await withdraw(accountData ? accountData.id : "", accountData ? accountData.session : "", accountData ? accountData?.hash : "", wallet, tokenAmount as unknown as string);

                if (response.response === "session and hash dont match with server") {
                    toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                    logout();
                    navigate('/login');
                }

                toast.success("Withdraw succesfull!");
                setTokenAmount("1");
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            toast.error("Something went wrong, try to reconnect wallet and login again!");
            setTokenAmount("1");
        }
    };


    const isButtonDisabled = () => {
        const amount = parseFloat(tokenAmount);
        const kktInDollars = parseFloat(kktValueInDollars);

        const amountInDollars = amount * kktInDollars;
        if (amountInDollars < 2 || Number.isNaN(amountInDollars)) {
            return true;
        }
        return false;
    }

    const calculateFee = (amount: number) => {
        const kktInDollars = parseFloat(kktValueInDollars);

        return Number((amount / kktInDollars).toFixed(3));
    }

    const calculateReceivedToken = () => {
        const amount = parseFloat(tokenAmount);
        const kktInDollars = parseFloat(kktValueInDollars);

        if (amount)
            return Number((amount - 1 / kktInDollars).toFixed(3));

        return 0;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "10px", position: "relative" }}>
            <label style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>Enter amount of KKT tokens:</label>
            <TextField margin="normal" style={{ width: "100%" }} value={tokenAmount} onChange={(e) => setTokenAmount(e.target.value)} />
            <p style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>Minimum to withdraw: {calculateFee(2)} <img style={{ height: "2rem", paddingBottom: "5px" }} src={KKTicker} alt={`${KKTicker}`} /></p>
            <p style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>Withdrawal fee: {calculateFee(1)} <img style={{ height: "2rem", paddingBottom: "5px" }} src={KKTicker} alt={`${KKTicker}`} /></p>
            <p style={{ marginTop: "10px", display: "flex", justifyContent: "flex-start" }}>You will receive in wallet: {calculateReceivedToken()} <img style={{ height: "2rem", paddingBottom: "5px" }} src={KKTicker} alt={`${KKTicker}`} /></p>
            <Button variant="contained" style={{ bottom: 0, position: "absolute", width: "100%", height: "56px" }} onClick={() => withdrawKkt()} disabled={isButtonDisabled()}>WITHDRAW</Button>
        </div>
    );
};

export default WithdrawTokenForm;
