import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './MidasVault.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { getKktBalance, vaultDeposit, vaultInfo, vaultWithdraw } from '@api/account';
import { toast } from 'react-toastify';
import { KktBalance, VaultInfoResponse } from '@interfaces/types';
import Accordion from '@components/Accordion';
import { useNavigate } from 'react-router-dom';
import MidasVaultTransactionForm from '@components/MidasVaultTransactionForm';
import BlacklistWrapper from '@hoc/BlacklistWrapper';
import { formatNumber } from '../helpers/formating';
import Loader from '@components/Loader';

const MidasVault: React.FC = () => {
    const isForDesktop = useMediaQuery();
    const { accountData, logout } = useAuth();
    const navigate = useNavigate();

    const [kktBalance, setKktBalance] = useState<KktBalance>();
    const [vaultInfoResponse, setVaultInfoResponse] = useState<VaultInfoResponse>();
    const [tokenAmountDeposit, setTokenAmountDeposit] = useState<string>("0");
    const [tokenAmountWithdraw, setTokenAmountWithdraw] = useState<string>("0");
    const [isLoading, setIsLoading] = useState<boolean>(true);


    const handleTokenAmountChange = (action: 'deposit' | 'withdraw') => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputValue = e.target.value;

        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (action === 'deposit') {
                setTokenAmountDeposit(inputValue);
            } else if (action === 'withdraw') {
                setTokenAmountWithdraw(inputValue);
            }
        }
    };

    const fetchAllData = async () => {
        try {
            setIsLoading(true);

            const [vaultInfoResponse, kktBalanceResponse] = await Promise.all([
                vaultInfo(accountData ? accountData.id : ""),
                getKktBalance(accountData ? accountData.id : ""),
            ]);

            setVaultInfoResponse(vaultInfoResponse);
            setKktBalance(kktBalanceResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    const deposit = async () => {
        if (accountData) {
            try {
                if (accountData) {
                    const response = await vaultDeposit(accountData.id,
                        accountData?.hash,
                        accountData.session,
                        tokenAmountDeposit);

                    if (response.response === "success") {
                        setTokenAmountDeposit("0");
                        toast.success("Succesfully deposited.")
                        fetchAllData();
                    } else if (response.response === "session and hash dont match with server") {
                        setTokenAmountDeposit("0");
                        toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                        logout();
                        navigate('/login');
                    } else {
                        toast.error("Deposit failed.")
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Deposit failed.")
            }
        }
    };

    const withdraw = async () => {

        if (accountData) {
            try {
                if (accountData) {
                    const response = await vaultWithdraw(accountData.id,
                        accountData?.hash,
                        accountData.session,
                        tokenAmountWithdraw);

                    if (response.response === "success") {
                        toast.success("Succesfully withdrawed.")
                        setTokenAmountWithdraw("0");
                        fetchAllData()
                    } else if (response.response === "session and hash dont match with server") {
                        toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                        setTokenAmountWithdraw("0");
                        logout();
                        navigate('/login');
                    } else {
                        toast.error("Withdraw failed")
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Withdraw failed.")
            }
        }
    };

    const calculateMyRewardsOnNextVote = () => {

        if (vaultInfoResponse) {

            const percentage: number = (parseInt(vaultInfoResponse.my_voting_power) / parseInt(vaultInfoResponse.total_voting_power)) * 100;
            const number = (percentage / 100) * parseInt(vaultInfoResponse?.kkt_in_voting_pool);

            if(Number.isNaN(number))
                return 0;

            return number;
        }
        return 0;
    }


    const percentageButtonDeposit = (percentage: number) => {
        const kkt = kktBalance?.balance as unknown as number;

        const valueFromPercentage = (percentage / 100) * kkt
        setTokenAmountDeposit(valueFromPercentage as unknown as string)
    }

    const percentageButtonWithdraw = (percentage: number) => {
        const kkt = vaultInfoResponse?.my_kkt_stashed as unknown as number;

        const valueFromPercentage = (percentage / 100) * kkt
        setTokenAmountWithdraw(valueFromPercentage as unknown as string)
    }

    const renderInfo = (title: string, amount: string) => (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <label style={{ fontFamily: "Ruda", fontSize: "15px", flex: 1 }}>{title}</label>
            <label style={{ fontFamily: "PassionOneRegular", fontSize: isForDesktop ? "22px" : "19px", color: "#F90", flex: 1 }}>{amount}</label>
        </div>
    );

    const generateAccordionItems = () => [
        {
            title: (
                <div className='accordion-content-div'>
                    <label className='accordion-title-label'>Vault Info</label>
                </div>
            ),
            content: <div className='midas-vault-accordian-content'>
                <div style={{ width: "100%", display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                    {renderInfo("Amount Stored in vault", `${vaultInfoResponse?.total_kkt_stashed !== undefined ? formatNumber(vaultInfoResponse?.total_kkt_stashed) : "-"} KKT`)}
                    {renderInfo("KKT in voting reward pools", `${vaultInfoResponse?.kkt_in_voting_pool !== undefined ? formatNumber(vaultInfoResponse?.kkt_in_voting_pool) : "-"} KKT`)}
                    {renderInfo("Community vault power", `${vaultInfoResponse?.total_voting_power !== undefined ? formatNumber(vaultInfoResponse?.total_voting_power) : "-"}`)}
                </div>
            </div>
        },
        {
            title: (
                <div className='accordion-content-div'>
                    <label className='accordion-title-label'>My Vault Info</label>
                </div>
            ),
            content: <div className='midas-vault-accordian-content'>
                <div style={{ width: "100%", display: 'flex', flexDirection: "column", justifyContent: "space-around" }}>
                    {renderInfo("My KKT balance", `${kktBalance?.balance !== undefined ? formatNumber(kktBalance?.balance) : "-"} KKT`)}
                    {renderInfo("My stored tokens", `${vaultInfoResponse?.my_kkt_stashed !== undefined ? formatNumber(vaultInfoResponse?.my_kkt_stashed) : "-"} KKT`)}
                    {renderInfo("My rewards at next vote", `${formatNumber(calculateMyRewardsOnNextVote().toFixed(2))} KKT`)}
                    {renderInfo("My vault power", `${vaultInfoResponse?.my_voting_power !== undefined ? formatNumber(vaultInfoResponse?.my_voting_power) : "-"}`)}
                </div>
            </div>
        },
        {
            title: (
                <div className='accordion-content-div'>
                    <label className='accordion-title-label'>Vault Deposit</label>
                </div>
            ),
            content: <div className='midas-vault-accordian-content'>
                <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                    <MidasVaultTransactionForm
                        title="deposit"
                        tokenAmount={tokenAmountDeposit}
                        handleTokenAmountChange={handleTokenAmountChange('deposit')}
                        percentageButtonAction={percentageButtonDeposit}
                        action={deposit}
                    />
                </BlacklistWrapper>
            </div>
        },
        {
            title: (
                <div className='accordion-content-div'>
                    <label className='accordion-title-label'>Vault Withdraw</label>
                </div>
            ),
            content: <div className='midas-vault-accordian-content'>
                <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                    <MidasVaultTransactionForm
                        title="withdraw"
                        tokenAmount={tokenAmountWithdraw}
                        handleTokenAmountChange={handleTokenAmountChange('withdraw')}
                        percentageButtonAction={percentageButtonWithdraw}
                        action={withdraw}
                    />
                </BlacklistWrapper>

            </div>
        },
    ];

    const renderContent = () => {
        return <>
            {isForDesktop && <div style={{ width: "80%", maxWidth: "900px", maxHeight: "600px", zIndex: 2 }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", border: "1px solid #2FCDFF", padding: "20px" }}>
                    <div style={{ width: "100%", display: 'flex', justifyContent: "space-around" }}>
                        {renderInfo("Amount Stored in vault", `${vaultInfoResponse?.total_kkt_stashed !== undefined ? formatNumber(vaultInfoResponse?.total_kkt_stashed) : "-"} KKT`)}
                        {renderInfo("KKT in voting reward pools", `${vaultInfoResponse?.kkt_in_voting_pool !== undefined ? formatNumber(vaultInfoResponse?.kkt_in_voting_pool) : "-"} KKT`)}
                        {renderInfo("Community vault power", `${vaultInfoResponse?.total_voting_power !== undefined ? formatNumber(vaultInfoResponse?.total_voting_power) : "-"}`)}
                    </div>
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "40px", border: "1px solid #2FCDFF", height: "380px", padding: "15px", }}>
                    <div style={{ width: "100%", display: 'flex', justifyContent: "space-around" }}>
                        {renderInfo("My KKT balance", `${kktBalance?.balance !== undefined ? formatNumber(kktBalance?.balance) : "-"} KKT`)}
                        {renderInfo("My stored tokens", `${vaultInfoResponse?.my_kkt_stashed !== undefined ? formatNumber(vaultInfoResponse?.my_kkt_stashed) : "-"} KKT`)}
                        {renderInfo("My rewards at next vote", `${calculateMyRewardsOnNextVote().toFixed(2)} KKT`)}
                        {renderInfo("My vault power", `${vaultInfoResponse?.my_voting_power !== undefined ? formatNumber(vaultInfoResponse?.my_voting_power) : "-"}`)}
                    </div>

                    <div style={{ display: "flex", width: "100%", height: "100%", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ display: "flex", minWidth: "300px", flexDirection: "row", justifyContent: "space-around", flex: 1 }}>
                            <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                                <MidasVaultTransactionForm
                                    title="deposit"
                                    tokenAmount={tokenAmountDeposit}
                                    handleTokenAmountChange={handleTokenAmountChange('deposit')}
                                    percentageButtonAction={percentageButtonDeposit}
                                    action={deposit}
                                />
                            </BlacklistWrapper>
                            <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                                <MidasVaultTransactionForm
                                    title="withdraw"
                                    tokenAmount={tokenAmountWithdraw}
                                    handleTokenAmountChange={handleTokenAmountChange('withdraw')}
                                    percentageButtonAction={percentageButtonWithdraw}
                                    action={withdraw}
                                />
                            </BlacklistWrapper>
                        </div>
                    </div>
                </div>

                {vaultInfoResponse?.vote_active === "1" && <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "40px", border: "1px solid #2FCDFF", padding: "20px" }}>
                    <div style={{ width: "100%", display: 'flex', justifyContent: "flex-start" }}>
                        <label style={{ fontFamily: "PassionOneRegular", fontSize: "large" }}>{vaultInfoResponse?.question}</label>
                    </div>
                </div>}
            </div>}

            {!isForDesktop && <div style={{ width: "100%", zIndex: 2, flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                    <Accordion items={generateAccordionItems()} activeOne={0} />
                </div>
            </div>}
        </>
    }

    return (
        <div className="page-content" style={{ position: "relative" }}>
            <div className='page-title-container' style={{ zIndex: 1 }}>
                <label className='page-title'>Midas Vault</label>
            </div>
            {isLoading ? <Loader isLoading={isLoading} /> : renderContent()}

        </div>
    );
};

export default BackgroundTemplate(MidasVault);
