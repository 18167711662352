import { buyKyc, clear2FaSecret, confirmPassword, getKktValueInDollars, set2FaSecret, updateKycStatus, verify2FaSecret } from '@api/account';
import KycButtonWrapper from '@hoc/KycButtonWrapper';
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, Switch, TextField, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useAuth } from '../AuthContext';
import { generateQRCodeURL } from '../helpers/generate_qr_code';
import BackgroundTemplate from '../hoc/BackgroundTemplate';

const AccountSettings: React.FC = () => {
  const { accountData, refreshAccountData } = useAuth();
  const [isWelcome2faDialogOpen, seIsWelcome2faDialogOpen] = useState(false);
  const [isTwoStepVerified, setTwoStepVerified] = useState(accountData?.is2fa_enabled);
  const [isPasswordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [isSettingUpAuthenticatorDialogOpen, setIsSettingUpAuthenticatorDialogOpen] = useState(false);
  const [isValidateAuthenticatorDialogOpen, setIsValidateAuthenticatorDialogOpen] = useState(false);
  const [isKycDialogOpen, setIsKycDialogOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [otpauth_url, setOtpauth_url] = useState("");
  const [otpBase32, setOtpBase32] = useState("");
  const [validateAuthenticatorDialogInput, setValidateAuthenticatorDialogInput] = useState('');
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    refreshAccountData();
  }, [])

  const handleParentButtonClick = async () => {
    if (!accountData) return;
  
    try {
      const response = await getKktValueInDollars(accountData.id);
  
      if (response.response !== "success") return;
  
      const isBlockpassLessThan10 = response.blockpass_starts as unknown as number < 10;
      const isKycPurchased = accountData.kyc_purchased === "1";
  
      if (isBlockpassLessThan10 || isKycPurchased) {
        buttonRef.current?.click();
        await updateKycStatus(accountData.id, accountData.session, accountData.hash, "incomplete");
        refreshAccountData();
      } else {
        setIsKycDialogOpen(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const handleKycButtonClick = () => {
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSwitchChange = async () => {
    if (!isTwoStepVerified) {
      seIsWelcome2faDialogOpen(true);
    } else {
      setPasswordDialogOpen(true);
    }
  };

  const handleWelcome2faDialogClose = () => {
    seIsWelcome2faDialogOpen(false);
  };

  const handlePasswordDialogClose = () => {
    setPasswordDialogOpen(false);
  };

  const handleSettingUpAuthenticatorDialogClose = async () => {
    if (accountData) {
      const response = await clear2FaSecret(accountData.id, accountData.session, accountData.hash)
      refreshAccountData();

    }
    setIsSettingUpAuthenticatorDialogOpen(false);
  };

  const handleValidateAuthenticatorDialogClose = async () => {
    if (accountData) {
      const response = await clear2FaSecret(accountData.id, accountData.session, accountData.hash)
      refreshAccountData();
    }
    setIsValidateAuthenticatorDialogOpen(false);
  };

  const handleKycDialogClose = async () => {
    setIsKycDialogOpen(false);
  };

  const onConfirmKycDialog = async () => {

    if (accountData) {
      try {
        const response = await buyKyc(accountData.id, accountData.session, accountData.hash);

        if (response.response === "success") {
          refreshAccountData();
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    setIsKycDialogOpen(false);
  };

  const onConfirmPasswordDialog = async () => {
    if (accountData) {
      const response = await confirmPassword(accountData?.email, password);

      if (response.response === "success") {
        toast.success("Password confirmed!");

        if (!isTwoStepVerified) {
          const set2FaResponse = await set2FaSecret(accountData.id, accountData.session, accountData.hash);

          const qrCodeResponse = await generateQRCodeURL(set2FaResponse.otpauth_url);
          setOtpauth_url(qrCodeResponse as string);
          setOtpBase32(set2FaResponse.base32 as string);
          setIsSettingUpAuthenticatorDialogOpen(true);
        } else {
          const clear2FaResponse = await clear2FaSecret(accountData.id, accountData.session, accountData.hash);
          if (clear2FaResponse.response === "success") {
            refreshAccountData();
            setTwoStepVerified(!isTwoStepVerified);
            toast.success("Two-step verification disabled!");
          } else {
            toast.error("Failed to disable two-step verification.");
          }
        }
      } else {
        console.error('Wrong password!');
        toast.error("Wrong password");
      }
    }
    setPassword("");
    setPasswordDialogOpen(false);
  };

  const onConfirmSettingUpAuthenticatorDialog = () => {
    setIsSettingUpAuthenticatorDialogOpen(false);
    setIsValidateAuthenticatorDialogOpen(true);
  };

  const onConfirmValidateAuthenticatorDialog = async () => {
    if (accountData) {
      const response = await verify2FaSecret(accountData.id, validateAuthenticatorDialogInput, accountData.session, accountData.hash);
      if (response.status = "successful") {
        refreshAccountData();
        setTwoStepVerified(!isTwoStepVerified)
        toast.success("Two-step verification enabled!");
      }
    }
    setIsValidateAuthenticatorDialogOpen(false);
  };

  const handleGetStarted = () => {
    seIsWelcome2faDialogOpen(false);
    setPasswordDialogOpen(true);
  };

  return (
    <div className='page-content'>
      <div className='page-title-container'>
        <label className='page-title'>Account Settings</label>
      </div>

      <Dialog
        open={isWelcome2faDialogOpen}
        onClose={handleWelcome2faDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Enable two-step verification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Two-step verification adds an extra layer of protection to your account. Whenever you sign in to the Kingdom Karnage website or link a new device, you’ll need to enter both your credentials and also a security code sent to your mobile phone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGetStarted} autoFocus>
            Get started
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isPasswordDialogOpen}
        onClose={handlePasswordDialogClose}
        aria-labelledby="password-dialog-title"
        aria-describedby="password-dialog-description"
      >
        <DialogTitle id="password-dialog-title">
          {isTwoStepVerified ? "Disable two-step verification" : "Enable two-step verification"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="password-dialog-description">
            For security, please enter your password
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmPasswordDialog} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isSettingUpAuthenticatorDialogOpen}
        onClose={handleSettingUpAuthenticatorDialogClose}
        aria-labelledby="second-modal-title"
        aria-describedby="second-modal-description"
      >
        <DialogTitle id="second-modal-title">{"Enable two-step verification"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="second-modal-description">
            An authenticator app lets you generate security codes on your phone. Download Google Authenticator on your phone.
          </DialogContentText>
          <List component="nav">
            <ListItem>
              Add a new time-based token.
            </ListItem>
            <ListItem>
              Use your app to scan the barcode below, or your setup key and configure manually.{' '}
            </ListItem>
            <ListItem>
              <CopyToClipboard text={otpBase32}>
                <Button color="primary">Copy to Clipboard</Button>
              </CopyToClipboard>
            </ListItem>
          </List>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={otpauth_url} alt="Your Image Alt Text" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSettingUpAuthenticatorDialogClose} autoFocus>
            Close
          </Button>
          <Button onClick={onConfirmSettingUpAuthenticatorDialog} autoFocus>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isValidateAuthenticatorDialogOpen}
        onClose={handleValidateAuthenticatorDialogClose}
        aria-labelledby="new-modal-title"
        aria-describedby="new-modal-description"
      >
        <DialogTitle id="new-modal-title">{"Enable two-step verification"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="new-modal-description">
            Enter the security code generated by your mobile authenticator app to make sure it’s configured correctly.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Security code"
            type="text"
            fullWidth
            value={validateAuthenticatorDialogInput}
            onChange={(e) => setValidateAuthenticatorDialogInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleValidateAuthenticatorDialogClose} autoFocus>
            Close
          </Button>
          <Button onClick={onConfirmValidateAuthenticatorDialog} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isKycDialogOpen}
        onClose={handleKycDialogClose}
        aria-labelledby="kyc-modal-title"
        aria-describedby="kyc-modal-description"
      >
        <DialogTitle id="new-modal-title">{"Proceed with kyc:"}</DialogTitle>
        <DialogContent>

          {accountData && <div>
            <DialogContentText id="kyc-modal-description">
              Daily limit has been reached, you will need to pay 2$ in KKT (3,143.43 KKT) to proceed.
              <br />
              Alternatively you can wait until tomorrow.
            </DialogContentText>

          </div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleKycDialogClose} autoFocus>
            Close
          </Button>
          <Button onClick={onConfirmKycDialog} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: "flex", justifyContent: "space-around", width: "80%", flexWrap: "wrap" }}>
        <Card sx={{ width: 345, margin: "20px" }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{ display: "flex", justifyContent: "flex-start" }}>
              Two-step verification
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ display: "flex", justifyContent: "flex-start", textAlign: "start" }}>
              Require a security key or code in addition to your password.
            </Typography>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch checked={isTwoStepVerified} onChange={handleSwitchChange} color="secondary" />
          </CardActions>
        </Card>

        <Card sx={{ width: 345, margin: "20px" }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              Know Your Customer
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{ display: "flex", justifyContent: "flex-start", textAlign: "start" }}>
              Verify your identify. Please note, official ID (e.g. passport) will be required.
            </Typography>
          </CardContent>
          <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>

            {accountData && accountData.kyc_status != "" ? <p style={{ color: "green", fontWeight: "800", marginRight: "10px" }}>{accountData.kyc_status.toUpperCase()}</p> :
              <Button onClick={handleParentButtonClick}>Verify</Button>}
            <KycButtonWrapper onButtonClick={handleKycButtonClick} forwardedRef={buttonRef} />
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

export default BackgroundTemplate(AccountSettings);
