import React, { useState } from 'react';
import './MarketItem.scss';
import KKTicker from '@assets/KKTicker.webp';
import '../fonts.scss';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { Box, ThemeProvider } from '@mui/material';
import { formatNumber } from '../helpers/formating';
import { RedGemShopModel } from '../pages/RedGemsShop';
import gem from '../assets/gem.webp';
import useMediaQuery from '../hooks/useMediaQuery';

export interface MarketItemProps {
    bla: RedGemShopModel;
}

const RedGemItem: React.FC<MarketItemProps> = (props: MarketItemProps) => {

    const navigate = useNavigate();
    const { accountData } = useAuth();
    const isForDesktop = useMediaQuery();

    const [hovered, setHovered] = useState(false);

    const getRarityMain = () => {
        switch (props.bla.option) {
            case "1":
                return "#717273";
            case "2":
                return "#4169E1"
            case "3":
                return "#FF7518";
            default:
                return "#717273";
        }
    }

    const getRarityDark = () => {
        switch (props.bla.option) {
            case "1":
                return "#808080";
            case "2":
                return "#0F52BA"
            case "3":
                return "#FF5F15";
            default:
                return "#808080";
        }
    }

    return (
        <>
            {isForDesktop && <div style={{ display: "flex", flexDirection: "column", margin: "20px" }} >
                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            width: 100,
                            height: 100,
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            bgcolor: '#242958',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                        style={{ width: "200px", height: "200px", display: "flex", flexDirection: "column", transition: 'box-shadow 0.3s ease-in-out' }}
                    >
                        <img src={props.bla.image} alt="Description" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, margin: "0px" }} />
                        <div style={{ position: 'absolute', top: '7px', left: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                            <div style={{ color: 'white', fontWeight: "500", fontSize: '14px', display: 'flex', flexDirection: 'row' }}> {props.bla.amount} <img style={{ marginLeft: '3px', height: '1.1rem' }} src={gem} alt={`${gem}`} /></div>
                        </div>
                    </Box>

                </ThemeProvider>

                <ThemeProvider
                    theme={{
                        palette: {
                            primary: {
                                main: hovered ? getRarityDark() : getRarityMain(),
                            },
                        },
                    }}
                >
                    <Box
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        sx={{
                            bgcolor: 'primary.main',
                            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)'
                        }}
                        style={{ width: "200px", height: "65px", marginTop: "5px", display: "flex", flexDirection: "column" }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px" }}>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "5px", paddingRight: "5px" }}>
                            <p className='market-item-cost-text'>{formatNumber(props.bla.priceInKkt as unknown as string)}  <img className='market-item-kkt-logo' src={KKTicker} alt={`${KKTicker}`} /> </p>

                        </div>
                    </Box>
                </ThemeProvider>
            </div>}

            {!isForDesktop && (
                <div style={{ display: 'flex', flexDirection: 'column', margin: '5px' }}>
                    <ThemeProvider
                        theme={{
                            palette: {
                                primary: {
                                    main: hovered ? getRarityDark() : getRarityMain(),
                                },
                            },
                        }}>
                        <Box
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            sx={{
                                width: 60,
                                height: 60,
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                                bgcolor: '#242958',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                                position: 'relative',
                                overflow: 'hidden',
                            }}
                            style={{ width: '140px', height: '140px', display: 'flex', flexDirection: 'column', transition: 'box-shadow 0.3s ease-in-out' }}>
                            <img src={props.bla.image} alt="Description" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10, margin: '0px' }} />

                            <div style={{ position: 'absolute', top: '7px', left: '7px', width: 'fit-content', padding: '2px 5px', borderRadius: '10px', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                                <div style={{ color: 'white', fontWeight: "500", fontSize: '14px', display: 'flex', flexDirection: 'row' }}> {props.bla.amount} <img style={{ marginLeft: '3px', height: '1.1rem' }} src={gem} alt={`${gem}`} /></div>
                            </div>
                        </Box>
                    </ThemeProvider>

                    <ThemeProvider
                        theme={{
                            palette: {
                                primary: {
                                    main: hovered ? getRarityDark() : getRarityMain(),
                                },
                            },
                        }}>
                        <Box
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}
                            sx={{
                                bgcolor: 'primary.main',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.5)',
                            }}
                            style={{ width: '140px', height: '50px', marginTop: '5px', display: 'flex', flexDirection: 'column', paddingTop: "5px" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", paddingLeft: '5px', paddingRight: '5px' }}>
                                <p className="market-item-cost-text">
                                    {formatNumber(props.bla.priceInKkt as unknown as string)} <img className="market-item-kkt-logo" src={KKTicker} alt={`${KKTicker}`} />{' '}
                                </p>
                            </div>
                        </Box>
                    </ThemeProvider>
                </div>
            )}
        </>

    );
};

export default RedGemItem;
