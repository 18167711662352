import React, { useEffect, useState } from 'react';
import { Transaction } from '@interfaces/types';
import './TransactionTable.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { toLocalDateTime } from '../helpers';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';

interface TransactionTableProps {
  transactions: Transaction[];
}

const TransactionTable: React.FC<TransactionTableProps> = ({ transactions }) => {

  const isForDesktop = useMediaQuery();

  const statusMapper = (status: string) => {
    if (status === "0")
      return "Processing";
    else if (status === "1")
      return "Completed";
    else if (status === "5")
      return "Error"
  }

  return (
    <>
      {isForDesktop && <div className='table-wrapper' style={{ marginBottom: "30px", marginTop: "30px" }}>
        <TableContainer component={Paper} elevation={24}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Fee</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>BscScan</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, index) => (
                <TableRow key={index} style={{ height: "34px" }}>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{toLocalDateTime(transaction.datetime)}</TableCell>
                  <TableCell>{transaction.direction}</TableCell>
                  <TableCell>{transaction.fee}</TableCell>
                  <TableCell>{transaction.status}</TableCell>
                  <TableCell>
                    <Link href={`https://bscscan.com/tx/${transaction.transactionHash}`} underline='hover' target="_blank">link</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>}

      {!isForDesktop &&
        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px", width: "80%" }}>
          {transactions.map((transaction, index) => (
            <div key={index} className='mobile-table'>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: "13px" }}>
                  <p>AMOUNT:</p>
                  <p>DATE:</p>
                  <p>DIRECTION:</p>
                  <p>FEE:</p>
                  <p>STATUS:</p>
                  <p>BSCSCAN:</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", fontSize: "13px" }}>
                  <p>{transaction.amount}</p>
                  <p>{transaction.date}</p>
                  <p>{transaction.direction}</p>
                  <p>{transaction.fee}</p>
                  <p>{statusMapper(transaction.status)}</p>
                  <a style={{ textDecoration: 'underline' }} href={`https://bscscan.com/tx/${transaction.transactionHash}`}>link</a>

                </div>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default TransactionTable;
