import { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { Link, useLocation } from 'react-router-dom';
import { CardQuality, CharacterData } from '@interfaces/types';
import CardList from '@components/CardList';
import { abilityDescriptions } from '@constants/constants';
import Accordion from '@components/Accordion';
import './CharacterDetails.scss';
import { useMyContext } from '../MyContext';
import { stringToCardQuality } from '../helpers/marketItems';
import { Button } from '@mui/material';

const CharacterDetails = () => {
  const location = useLocation();
  const { escapedTitle, Quality, GameReference } = location.state;
  const { characterData } = useMyContext();
  const [specificCharacter, setSpecificCharacter] = useState<CharacterData[]>();
  const [uniqueAbilities, setUniqueAbilities] = useState<string[]>();

  useEffect(() => {
    getCharacterAbilities();
  }, []);

  const getRarityColor = () => {
    const po = specificCharacter ? specificCharacter[0]?.Quality : "Common";
    const bla = stringToCardQuality(po)

    switch (bla) {
      case CardQuality.Common:
        return { color: "#A4A4A4" };
      case CardQuality.Uncommon:
        return { color: "#68DF2F" };
      case CardQuality.Rare:
        return { color: "#33C4F1" };
      case CardQuality.Epic:
        return { color: "#7C4FFF" };
      case CardQuality.Legendary:
        return { color: "#E26D00" };
      default:
        return { color: "#A4A4A4" };
    }
  }



  const decodedFileName = decodeURIComponent(escapedTitle ? escapedTitle : "");

  const getCharacterAbilities = () => {
    const transformedString = decodedFileName?.replace(/\s/g, '')?.toLowerCase();
    let filteredArray = characterData && characterData.filter((item) => {
      return item.GameReference.includes(transformedString ? transformedString : "") && item.Quality === Quality;
    });

    if (filteredArray?.length === 0 && GameReference !== "mike") {
      filteredArray = characterData && characterData.filter((item) => {
        return item.GameReference.includes(GameReference ? GameReference.slice(0, -3) : "") && item.Quality === Quality && item.Type !== "Structure";
      });
    }

    if (filteredArray?.length === 0) {
      filteredArray = characterData && characterData.filter((item) => {
        return item.GameReference.includes(GameReference ? GameReference : "") && item.Quality === Quality && item.Type !== "Structure";
      });
    }



    setSpecificCharacter(filteredArray ? filteredArray : []);

    const combinedAbilitiesSet: Set<string> = new Set();

    filteredArray && filteredArray.forEach((character) => {
      combinedAbilitiesSet.add(character.Ability1);
      combinedAbilitiesSet.add(character.Ability2);
      combinedAbilitiesSet.add(character.Ability3);
    });

    const uniqueAbilities: string[] = Array.from(combinedAbilitiesSet);
    setUniqueAbilities(uniqueAbilities);
  }

  const generateAccordionItems = () => {
    return ([{
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label className='accordion-title-label'>
            Abilities
          </label>
        </div>
      ),
      content: (
        uniqueAbilities ? (
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {uniqueAbilities.map((ability, index) => (
              abilityDescriptions[ability] !== "" && <li
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
                key={index}
              >
                <span className="font-Ruda" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  <span
                    style={{
                      borderBottom: '1px solid #0091BF',
                      display: 'inline',
                    }}
                  >
                    {ability}:
                  </span>
                </span>
                <span className="font-Ruda">
                  {abilityDescriptions[ability]}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No abilities available.</p>
        )
      )
    },
    {
      title: (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label className='accordion-title-label'>
            Card levels
          </label>
        </div>
      ),
      content: (
        <CardList CharacterData={specificCharacter ? specificCharacter : []} />
      )
    }
    ]);
  }

  return (
    <div className="page-content">
      <div className='page-title-container'>
        <label className='page-title' style={getRarityColor()}>{decodedFileName}</label>
      </div>
      <div className='image-frame'>
        <img
          src={`/assets/${decodedFileName}.webp`}
          alt={`${escapedTitle}`}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
      <Link to={`/shop`}
        state={{ escapedTitle, Quality, GameReference }}>
        <Button>
          Buy on market
        </Button>
      </Link>
      <Accordion items={generateAccordionItems()} activeOne={null} />
    </div>
  );
};

export default BackgroundTemplate(CharacterDetails);