import { SelectOption } from "@interfaces/types";

export const abilityDescriptions: Record<string, string> = {
    "": "",
    "Aggressive": "Deals 200% damage on first attack.",
    "Angered": "Gains +1/+0 when an ally takes damage.",
    "Armor 1": "Reduces physical damage taken by 1.",
    "Armor 2": "Reduces physical damage taken by 2.",
    "Armor 3": "Reduces physical damage taken by 3.",
    "Armor 4": "Reduces physical damage taken by 4.",
    "Piercing": "Attacks ignore armor.",
    "Berserk 1": "Attack increases by 1 when taking damage.",
    "Berserk 2": "Attack increases by 2 when taking damage.",
    "Berserk 3": "Attack increases by 3 when taking damage.",
    "Bloodlust": "Gains +1/+1 when an enemy dies.",
    "Brute": "When killing target gains health equal to target's health.",
    "Carnivore": "150% damage vs fleshies.",
    "Cleave": "Attacks 1x3 area.",
    "Cornered": "When in last lines of defense deals double damage.",
    "Devotion": "When healing gains +1 HP.",
    "Devour": "When killing target heals health equal to target's health.",
    "Disengage": "Moves backward after attacking.",
    "Drain Life 1": "Heals up to 1 HP when dealing damage.",
    "Drain Life 2": "Heals up to 2 HP when dealing damage.",
    "Drain Life 3": "Heals up to 3 HP when dealing damage.",
    "Eager": "50% chance to be in the first 5 cards drawn.",
    "Empower": "Killing blows increase attack power by the attack power of the victim.",
    "Fanatic 1": "Attack increases by 1 when dealing damage.",
    "Fanatic 2": "Attack increases by 2 when dealing damage.",
    "Fanatic 3": "Attack increases by 3 when dealing damage.",
    "Fear 1": "Attacks reduce target's damage by 1.",
    "Fear 2": "Attacks reduce target's damage by 2.",
    "Fear 3": "Attacks reduce target's damage by 3.",
    "Flying": "Can move over non-flying characters.",
    "Freeze": "Attacks immobilize the target for 1 turn.",
    "Fury": "Attacks two times.",
    "Guard": "Attacks rearmost target first.",
    "Guardian": "Takes damage in place of hero.",
    "Heroic": "Deals 200% damage if the target has more attack power.",
    "Knockback": "Knocks the target back 1 space.",
    "Malicious": "Attacks enemy creatures directly behind for 200% damage.",
    "Pack Leader": "While in play gives allied lycans +1/+0.",
    "Penetrate": "Attacks the target and character or hero behind the target.",
    "Pious": "Damage cannot be mitigated.",
    "Rampage": "When damaging a target also damages the target's summoning hero.",
    "Range 3": "Can attack up to 3 squares away.",
    "Range 4": "Can attack up to 4 squares away.",
    "Regeneration 1": "Regenerates 1 HP each turn.",
    "Regeneration 2": "Regenerates 2 HP each turn.",
    "Regeneration 3": "Regenerates 3 HP each turn.",
    "Resistance 1": "Reduces magical damage taken by 1.",
    "Resistance 2": "Reduces magical damage taken by 2.",
    "Resistance 3": "Reduces magical damage taken by 3.",
    "Retribution": "Attacks after being hit if in range.",
    "Rush": "Moves forward when able.",
    "Shock": "Attacks stun the target.",
    "Siphon Power 1": "Steals 1 Attack Power from the target when dealing damage.",
    "Siphon Power 2": "Steals 2 Attack Power from the target when dealing damage.",
    "Siphon Power 3": "Steals 3 Attack Power from the target when dealing damage.",
    "Spear": "Deals 150% damage to cavalry.",
    "Speed 3": "Can move 3 squares each turn.",
    "Speed 4": "Can move 4 squares each turn.",
    "Strike First": "Strikes first if attacked by an enemy in range.",
    "Triage 1": "Heals the most damaged ally by 1.",
    "Triage 2": "Heals the most damaged ally by 2.",
    "Triage 3": "Heals the most damaged ally by 3.",
    "Triage 4": "Heals the most damaged ally by 4.",
    "Undying": "Resurrects after dying (once only).",
    "Vengeful": "Gains +1/+1 when an ally dies.",
    "Warden": "Damage dealt heals the hero.",
    "Zeal": "When healing gains +1/+1.",
    "Golden Touch 1": "Earns $KKT with killing blows.",
    "Golden Touch 2": "Earns $KKT with killing blows.",
    "Golden Touch 3": "Earns $KKT with killing blows.",
    "Golden Touch 4": "Earns $KKT with killing blows.",
    "Golden Touch 5": "Earns $KKT with killing blows.",
    "Golden Touch 6": "Earns $KKT with killing blows.",
    "Golden Touch 7": "Earns $KKT with killing blows.",
    "Golden Touch 8": "Earns $KKT with killing blows.",
    "Golden Touch 9": "Earns $KKT with killing blows.",
    "Golden Touch 10": "Earns $KKT with killing blows.",
    "Vision 3": "Can see 3 squares ahead.",
    "Vision 4": "Can see 4 squares ahead.",
    "Vision 5": "Can see 5 squares ahead.",
    "Vision 6": "Can see 6 squares ahead.",
    "Vision 7": "Can see 7 squares ahead.",
    "Range 0": "Cannot attack",
  };

  export const rarityOptions: SelectOption[] = [
    { value: 'Common', label: 'Common' },
    { value: 'Uncommon', label: 'Uncommon' },
    { value: 'Rare', label: 'Rare' },
    { value: 'Epic', label: 'Epic' },
    { value: 'Legendary', label: 'Legendary' },
];

export const cardLevelOptions: SelectOption[] = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
];

export const abilityOptions: SelectOption[] = [
    { value: 'Aggressive', label: 'Aggressive' },
    { value: 'Armor', label: 'Armor' },
    { value: 'Berserk', label: 'Berserk' },
    { value: 'Bloodlust', label: 'Bloodlust' },
    { value: 'Brute', label: 'Brute' },
    { value: 'Carnivore', label: 'Carnivore' },
    { value: 'Cleave', label: 'Cleave' },
    { value: 'Cornered', label: 'Cornered' },
    { value: 'Devotion', label: 'Devotion' },
    { value: 'Devour', label: 'Devour' },
    { value: 'Disengage', label: 'Disengage' },
    { value: 'Drain Life', label: 'Drain Life' },
    { value: 'Eager', label: 'Eager' },
    { value: 'Empower', label: 'Empower' },
    { value: 'Fear', label: 'Fear' },
    { value: 'Fanatic', label: 'Fanatic' },
    { value: 'Freeze', label: 'Freeze' },
    { value: 'Fury', label: 'Fury' },
    { value: 'Golden Touch', label: 'Golden Touch' },
    { value: 'Guard', label: 'Guard' },
    { value: 'Guardian', label: 'Guardian' },
    { value: 'Heroic', label: 'Heroic' },
    { value: 'Knockback', label: 'Knockback' },
    { value: 'Malicius', label: 'Malicius' },
    { value: 'Pack Leader', label: 'Pack Leader' },
    { value: 'Penetrate', label: 'Penetrate' },
    { value: 'Piercing', label: 'Piercing' },
    { value: 'Pious', label: 'Pious' },
    { value: 'Rampage', label: 'Rampage' },
    { value: 'Range', label: 'Range' },
    { value: 'Regeneration', label: 'Regeneration' },
    { value: 'Resistance', label: 'Resistance' },
    { value: 'Rush', label: 'Rush' },
    { value: 'Shock', label: 'Shock' },
    { value: 'Siphon Power', label: 'Siphon Power' },
    { value: 'Speed', label: 'Speed' },
    { value: 'Spear', label: 'Spear' },
    { value: 'Strike First', label: 'Strike First' },
    { value: 'Triage', label: 'Triage' },
    { value: 'Undying', label: 'Undying' },
    { value: 'Vengeful', label: 'Vengeful' },
    { value: 'Vision', label: 'Vision' },
];

export const paymentOptions: SelectOption[] = [
    { value: 'crypto', label: 'Crypto' },
    { value: 'fiat', label: 'Fiat' },
];