import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiSelect-select": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            display: "flex",
            justifyContent: 'flex-start'
        }
    }
}));

interface AccordionProps {
    label: string;
    value: string;
    handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const InputComponent: React.FC<AccordionProps> = ({ label, value, handleChange }) => {
    const theme = useTheme();

    const classes = useStyles();

    return (
        <div>
            <InputLabel style={{ width: "100%", display: "flex", marginBottom: "-8px", justifyContent: 'flex-start' }}>{label}</InputLabel>
                <TextField margin="dense" style={{ width: "100%", backgroundColor: "#26282F" }} value={value} onChange={handleChange} />
        </div>
    );
};

export default InputComponent;
