import { CharacterData } from '@interfaces/types';
import React from 'react';
import './CardList.scss';
import { Link } from 'react-router-dom';

interface Props {
    CharacterData: CharacterData[],
    flexWrap?: boolean;
}

const shortTitles = [
    'aelene', 'bonereaper', 'bonesquire', 'boudica', 'darklord', 'deadeye',
    'elrond', 'frostlord', 'gael', 'humanmage', 'humanpriest', 'humansage',
    'pioneer', 'saevus', 'skellimas', 'soulthief', 'thewyden',
    'vilebonelord', 'shadowfang', 'silvermane', 'spiritwolf', 'stalker', 'ulrich',
    'skandir', 'skorpir', 'conri', 'farkas', 'lycandruid', 'lycanhunter',
    'nashoba', 'velkan', 'lilith', 'satrina', "humanrogue", "juniorstalker", "kingmidas",
    "humanknight", "lycanbrawler", "humanlancer", "scourgelord", "thortobinson"
];

const CARD_COLORS = {
    Common: 'White',
    Uncommon: 'Green',
    Rare: 'Blue',
    Epic: 'Purple',
    Legendary: 'Orange',
};

const CardList: React.FC<Props> = (props) => {
    const { flexWrap = true } = props;
    return (
        <div className="card-list" style={{ flexWrap: flexWrap ? 'wrap' : 'nowrap' }}>
            {props.CharacterData.map((card, index) => {
                const {
                    Quality,
                    Type,
                    Countdown,
                    Health,
                    Damage,
                    Level,
                    Title,
                    Ability1,
                    Ability2,
                    Ability3,
                    GameReference,
                } = card;
                const colour = CARD_COLORS[Quality as keyof typeof CARD_COLORS] || 'White';

                const escapedTitle = encodeURIComponent(Title);
                const isShortTitle = shortTitles.some((title) => GameReference.startsWith(title));
                const titleBottomPosition = isShortTitle ? '153px' : '147px';

                const linkPath = `/character-details/${escapedTitle}`;

                const dataToPass = {
                    Title,
                    Ability1,
                    Ability2,
                    Ability3,
                };

                return (
                    <Link
                        key={index}
                        id={`${index}`}
                        to={`/character-details/${escapedTitle}`}
                        state={{ escapedTitle, Quality, GameReference }}
                    >
                        <div
                            key={index}
                            id={`card${index}`}
                            style={{
                                position: 'relative',
                                width: '171px',
                                height: '283.5px',
                                float: 'left',
                            }}
                        >
                            <img
                                src={`/assets/Card_Front${colour}.webp`}
                                alt={`Card Front - ${colour}`}
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    width: '171px',
                                    height: '245.25px',
                                }}
                            />
                            <div
                                id={`characterImage${index}`}
                                style={{
                                    backgroundImage: `url(/assets/${escapedTitle}.webp)`,
                                    backgroundSize: '95px 95px',
                                    backgroundPosition: '39px 40px',
                                    backgroundRepeat: 'no-repeat',
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    width: '171px',
                                    height: '245.25px',
                                    WebkitMaskImage: 'url(/assets/mask.webp)',
                                    WebkitMaskSize: '171px 245.25px',
                                    WebkitMaskRepeat: 'no-repeat',
                                    WebkitMaskPosition: 'center',
                                    maskImage: 'url(/assets/mask.webp)',
                                    maskSize: '171px 245.25px',
                                    maskRepeat: 'no-repeat',
                                    maskPosition: 'center',
                                }}
                            ></div>
                            <img
                                src={`/assets/Card_Border${colour}.webp`}
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    left: '0px',
                                    width: '171px',
                                    height: '245.25px',
                                }}
                                alt={`Card Border ${colour}`}
                            />
                            <img
                                src={`/assets/${Type}Type.webp`}
                                style={{
                                    position: 'absolute',
                                    top: '10.8px',
                                    right: '8.1px',
                                    width: '24.3px',
                                    height: '23.95px',
                                }}
                                alt={`Attack Type ${Type}`}
                            />
                            <p
                                style={{
                                    position: 'absolute',
                                    top: '5.25px',
                                    left: '12px',
                                    fontSize: '20.25px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {Countdown}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '48px',
                                    right: '10px',
                                    fontSize: '19px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {Health}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '48px',
                                    left: '12px',
                                    fontSize: '19px',
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                {Damage}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '70px',
                                    left: '80px',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                            >
                                {Level}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: titleBottomPosition,
                                    left: '30px',
                                    width: '110px',
                                    textAlign: 'center',
                                    lineHeight: '14.2px',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    color: '#f9b929',
                                    letterSpacing: '1.35px',
                                    textShadow: '-0.675px -0.675px 0 #000, 0.675px -0.675px 0 #000, -0.675px 0.675px 0 #000, 0.675px 0.675px 0 #000',
                                }}
                            >
                                {Title}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '128.25px',
                                    width: '171px',
                                    textAlign: 'center',
                                    height: '14.85px',
                                    fontSize: '12px',
                                    color: 'white',
                                    letterSpacing: '1.35px',
                                }}
                            >
                                {Ability1}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '112.05px',
                                    width: '171px',
                                    textAlign: 'center',
                                    height: '16.2px',
                                    fontSize: '12px',
                                    color: 'white',
                                    letterSpacing: '1.35px',
                                }}
                            >
                                {Ability2}
                            </p>
                            <p
                                style={{
                                    position: 'absolute',
                                    bottom: '98.1px',
                                    width: '171px',
                                    textAlign: 'center',
                                    height: '16.2px',
                                    fontSize: '12px',
                                    color: 'white',
                                    letterSpacing: '1.35px',
                                }}
                            >
                                {Ability3}
                            </p>
                        </div>
                    </Link>
                );
            })}
        </div>
    );
};

export default CardList;
