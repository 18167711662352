import { Account, CharacterData, DepositKKTResponse, ForgetPasswordResponse, GetAuctionResponse, GetCoffinsResponse, ItemsData, KKPvpResponse, KKPvpSearchResponse, KktBalance, KktTransactions, KktValueInDollars, LinkedWalletsRespone, LogoutRepsponse, MyAuction, NftMintResponse, PlayerAsset, PlayerCardsResponse, SellResponse, TransactionLog, VaultInfoResponse, WithdrawKKTResponse } from '@interfaces/types';
import axios, { AxiosResponse } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeaders = () => ({
  'Content-Type': 'multipart/form-data',
});

export const getIPAddress = async (): Promise<string> => {
  try {
    const ipAddressObject = await api.get('https://api.ipify.org?format=json');
    const ipaddress = ipAddressObject.data.ip;

    return ipaddress;
  } catch (error) {
    throw error;
  }
};

const getCommonFormData = async () => {
  const formData = new FormData();
  formData.append('api_key', process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : '');

  return formData;
};

const makeApiRequest = async <T>(
  endpoint: string,
  formData: FormData
): Promise<T> => {
  try {
    const headers = getHeaders();
    const response = await api.post<T>(endpoint, formData, { headers });

    return response.data;
  } catch (error) {
    throw error;
  }
};

const makeApiRequestNic = async <T>(
  endpoint: string,
  formData: FormData
): Promise<T> => {
  try {
    const headers = getHeaders();
    const response = await axios.create({
      baseURL: "https://kepithorstudios.com/api/",
    }).post<T>(endpoint, formData, { headers });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmPassword = async (email: string, password: string): Promise<Account> => {
  try {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    return await makeApiRequest<any>('/confirm_password', formData);
  } catch (error) {
    throw error;
  }
};

export const set2FaSecret = async (id: string, session: string, hash: string,): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);

    return makeApiRequest<any>('/set_2fa_secret', formData);
  } catch (error) {
    throw error;
  }
};

export const bindWallet = async (id: string, session: string, hash: string, wallet: string, active: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('wallet', wallet);
    formData.append('active', active);

    return makeApiRequest<any>('/bind_wallet', formData);
  } catch (error) {
    throw error;
  }
};

export const clear2FaSecret = async (id: string, session: string, hash: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);

    return makeApiRequest<any>('/clear_2fa_secret', formData);
  } catch (error) {
    throw error;
  }
};

export const verify2FaSecret = async (id: string, otp: string, session: string, hash: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('otp', otp);
    formData.append('session', session);
    formData.append('hash', hash);

    return makeApiRequest<any>('/verify_2fa_key', formData);
  } catch (error) {
    throw error;
  }
};

export const get2FaSecret = async (session: string, hash: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('session', session);
    formData.append('hash', hash);

    return makeApiRequest<any>('/get_2fa_secret', formData);
  } catch (error) {
    throw error;
  }
};

export const login = async (ipaddress: string, email: string, password: string, isValidated: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('ipaddress', ipaddress);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('isValidated', isValidated);

    return await makeApiRequest<any>('/login', formData);
  } catch (error) {
    throw error;
  }
};

export const login2fa = async (ipaddress: string, email: string, password: string, otp: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('ipaddress', ipaddress);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('otp', otp);

    return await makeApiRequest<any>('/login2Fa', formData);
  } catch (error) {
    throw error;
  }
};

export const logout = async (id: string, hash: string, session: string): Promise<LogoutRepsponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);

    return await makeApiRequest<LogoutRepsponse>('/logout', formData);
  } catch (error) {
    throw error;
  }
};

export const getAccount = async (id: string): Promise<Account> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<Account>('/account', formData);
  } catch (error) {
    throw error;
  }
};

export const deposit = async (id: string, session: string, hash: string, wallet: string, amount: string, transactionHash: string): Promise<DepositKKTResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('wallet', wallet);
    formData.append('amount', amount);
    formData.append('transactionHash', transactionHash);

    return makeApiRequest<DepositKKTResponse>('/deposit_kkt', formData);
  } catch (error) {
    throw error;
  }
};

export const withdraw = async (id: string, session: string, hash: string, wallet: string, amount: string): Promise<WithdrawKKTResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('wallet', wallet);
    formData.append('amount', amount);

    return makeApiRequest<WithdrawKKTResponse>('/withdraw_kkt', formData);
  } catch (error) {
    throw error;
  }
};

export const getMyAuctions = async (id: string, session: string, hash: string): Promise<MyAuction[] | string> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);

    return makeApiRequest<MyAuction[] | string>('/auction_my_auctions', formData);
  } catch (error) {
    throw error;
  }
};

export const auctionCreate = async (id: string, session: string, hash: string, type: string, asset_id: string, auction_amount: string, auction_sell_index: string, gameReference: string, price: string, quality: string): Promise<SellResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('type', type);
    formData.append('asset_id', asset_id);
    formData.append('auction_amount', auction_amount);
    formData.append('auction_sell_index', auction_sell_index);
    formData.append('gamereference', gameReference);
    formData.append('price', price);
    formData.append('quality', quality)

    return makeApiRequest<SellResponse>('/auction_create', formData);
  } catch (error) {
    throw error;
  }
};

export const auctionBuy = async (id: string, session: string, hash: string, type: string, asset_id: string, auction_id: string, auction_amount: string, gameReference: string): Promise<SellResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);
    formData.append('type', type);
    formData.append('asset_id', asset_id);
    formData.append('auction_id', auction_id);
    formData.append('amount', auction_amount);
    formData.append('gamereference', gameReference);

    return makeApiRequest<SellResponse>('/auction_buy', formData);
  } catch (error) {
    throw error;
  }
};

export const auctionUnlist = async (id: string, session: string, hash: string, auction_id: string): Promise<SellResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('auction_id', auction_id);

    return makeApiRequest<SellResponse>('/auction_unlist', formData);
  } catch (error) {
    throw error;
  }
};

export const transactions = async (id: string): Promise<KktTransactions> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<KktTransactions>('/transactions', formData);
  } catch (error) {
    throw error;
  }
};

export const linkedWallets = async (id: string): Promise<LinkedWalletsRespone[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<LinkedWalletsRespone[]>('/wallets', formData);
  } catch (error) {
    throw error;
  }
};

export const getKktBalance = async (id: string): Promise<KktBalance> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<KktBalance>('/get_kkt_balance', formData);
  } catch (error) {
    throw error;
  }
};

export const getKktLog = async (id: string): Promise<TransactionLog[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<TransactionLog[]>('/get_kkt_log', formData);
  } catch (error) {
    throw error;
  }
};

export const getAssetsLog = async (id: string): Promise<TransactionLog[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<TransactionLog[]>('/get_asset_log', formData);
  } catch (error) {
    throw error;
  }
};

export const getPlayersCards = async (id: string): Promise<PlayerCardsResponse[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<PlayerCardsResponse[]>('/get_players_cards', formData);
  } catch (error) {
    throw error;
  }
};

export const getPlayersItems = async (id: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<any>('/get_players_items', formData);
  } catch (error) {
    throw error;
  }
};

export const getPlayersSpels = async (id: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<any>('/get_players_spells', formData);
  } catch (error) {
    throw error;
  }
};

export const getPlayerAssets = async (id: string): Promise<PlayerAsset[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<PlayerAsset[]>('/get_players_assets', formData);
  } catch (error) {
    throw error;
  }
};

export const getAuctions = async (id: string, rarity: string, searchString: string): Promise<GetAuctionResponse[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('rarity', rarity);
    // formData.append('race', race);
    formData.append('searchstring', searchString);

    return makeApiRequest<GetAuctionResponse[]>('/auction_search', formData);
  } catch (error) {
    throw error;
  }
};

export const getMakerAuctions = async (id: string, rarity: string, searchString: string): Promise<GetAuctionResponse[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('rarity', rarity);
    // formData.append('race', race);
    formData.append('searchstring', searchString);
    formData.append('seller_id', "1");

    return makeApiRequest<GetAuctionResponse[]>('/auction_search', formData);
  } catch (error) {
    throw error;
  }
};

export const getBlueGemsLog = async (id: string): Promise<TransactionLog[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<TransactionLog[]>('/get_blue_gem_log', formData);
  } catch (error) {
    throw error;
  }
};

export const getPlayerReferals = async (id: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<TransactionLog[]>('/get_players_referrals', formData);
  } catch (error) {
    throw error;
  }
};

export const getRedGemsLog = async (id: string, hide_adverts: number): Promise<TransactionLog[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hide_adverts', hide_adverts as unknown as string);

    return makeApiRequest<TransactionLog[]>('/get_red_gem_log', formData);
  } catch (error) {
    throw error;
  }
};

export const getScrapLog = async (id: string): Promise<TransactionLog[]> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<TransactionLog[]>('/get_scrap_log', formData);
  } catch (error) {
    throw error;
  }
};

export const getKktValueInDollars = async (id: string): Promise<KktValueInDollars> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<KktValueInDollars>('/get_kkt_value', formData);
  } catch (error) {
    throw error;
  }
};

export const getItemData = async (): Promise<ItemsData[]> => {
  try {
    const formData = new FormData();

    return makeApiRequest<ItemsData[]>('/get_item_data', formData);
  } catch (error) {
    throw error;
  }
};
export const wallets = async (id: string): Promise<AxiosResponse<any>> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<any>('/wallets', formData);
  } catch (error) {
    throw error;
  }
};

export const register = async (ipaddress: string, email: string, username: string, password: string, referral_code: string): Promise<AxiosResponse<any>> => {
  try {
    const formData = new FormData();

    formData.append('ipaddress', ipaddress);
    formData.append('email', email);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('referral_code', referral_code);

    return makeApiRequest<any>('/register', formData);
  } catch (error) {
    throw error;
  }
};

export const forgetPassword = async (email: string): Promise<ForgetPasswordResponse> => {
  try {
    const formData = new FormData();
    formData.append('email', email);

    return makeApiRequest<ForgetPasswordResponse>('/forgot', formData);
  } catch (error) {
    throw error;
  }
};

export const pioneerQuest = async (id: string): Promise<AxiosResponse<any>> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<any>('/get_pioneer', formData);
  } catch (error) {
    throw error;
  }
};

export const kkPvp = async (): Promise<KKPvpResponse> => {
  try {
    const formData = new FormData();

    return makeApiRequest<KKPvpResponse>('/kk_pvp', formData);
  } catch (error) {
    throw error;
  }
};

export const kkPvpSearch = async (name: string): Promise<KKPvpSearchResponse> => {
  try {
    const formData = new FormData();
    formData.append('name', name);

    return makeApiRequest<KKPvpSearchResponse>('/kk_pvp_search', formData);
  } catch (error) {
    throw error;
  }
};

export const vaultInfo = async (id: string): Promise<VaultInfoResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<VaultInfoResponse>('/vault_info', formData);
  } catch (error) {
    throw error;
  }
};

export const vaultDeposit = async (id: string, hash: string, session: string, amount: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);
    formData.append('amount', amount);

    return makeApiRequest<any>('/vault_deposit', formData);
  } catch (error) {
    throw error;
  }
};

export const changeName = async (id: string, hash: string, session: string, new_name: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);
    formData.append('new_name', new_name);

    return makeApiRequest<any>('/change_name', formData);
  } catch (error) {
    throw error;
  }
};

export const selectMidas = async (id: string, hash: string, session: string, midas_table_id: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);
    formData.append('midas_table_id', midas_table_id);

    return makeApiRequest<any>('/midas_select', formData);
  } catch (error) {
    throw error;
  }
};

export const buyMidas = async (id: string, hash: string, session: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);

    return makeApiRequest<any>('/midas_buy', formData);
  } catch (error) {
    throw error;
  }
};

export const vaultWithdraw = async (id: string, hash: string, session: string, amount: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);
    formData.append('amount', amount);

    return makeApiRequest<any>('/vault_withdraw', formData);
  } catch (error) {
    throw error;
  }
};

export const buyKyc = async (id: string, session: string, hash: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('hash', hash);
    formData.append('session', session);

    return makeApiRequest<any>('/buy_kyc', formData);
  } catch (error) {
    throw error;
  }
};

export const updateKycStatus = async (id: string, session: string, hash: string, kyc_status: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('kyc_status', kyc_status);

    return makeApiRequest<any>('/update_kyc_status', formData);
  } catch (error) {
    throw error;
  }
};

export const buyRedGems = async (id: string, session: string, amount: string): Promise<LogoutRepsponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('amount', amount);

    return await makeApiRequest<LogoutRepsponse>('/buy_red_gems', formData);
  } catch (error) {
    throw error;
  }
};

// nft

export const getCoffins = async (id: string): Promise<GetCoffinsResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    return makeApiRequest<GetCoffinsResponse>('/get_coffins', formData);
  } catch (error) {
    throw error;
  }
};

export const nftMint = async (id: string, session: string, hash: string, walletaddress: string, gamereference: string, is_a_hero: string, nft_id: string): Promise<NftMintResponse> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('walletaddress', walletaddress);
    formData.append('gamereference', gamereference);
    formData.append('is_a_hero', is_a_hero);
    formData.append('nft_id', nft_id);

    return makeApiRequest<NftMintResponse>('/nft_mint', formData);
  } catch (error) {
    throw error;
  }
};

export const openVampBox = async (id: string, session: string, hash: string, walletaddress: string, key_number: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('session', session);
    formData.append('hash', hash);
    formData.append('walletaddress', walletaddress);
    formData.append('key_number', key_number);

    return makeApiRequest<any>('/open_vamp_box', formData);
  } catch (error) {
    throw error;
  }
};