import React, { } from 'react';
import './Modal.scss';
import isotopicLogoWhite from '@assets/isotopicLogoWhite.png';
import seddona2 from '@assets/seddona2.png';
import '../pages/Account.scss';
import { Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import IconLink from './IconLink';
import { faAndroid, faApple, faSteam } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%"
        }
    }
}));
interface PlayModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const PlayModal: React.FC<PlayModalProps> = ({ isOpen, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            open={isOpen}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Play Kingdom Karnage for FREE across any of these platforms:"}
            </DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                    <IconLink icon={faSteam} link='https://store.steampowered.com/app/1305000/Kingdom_Karnage/' text='Steam' />
                    <IconLink icon={faAndroid} link='https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnage' text='Android' />
                    <IconLink icon={faApple} link='https://apps.apple.com/us/app/kingdom-karnage/id6443679225' text='Apple' />
                    <Link href={"https://isotopic.io/game/?game=Kingdom_Karnage"} target="_blank" style={{ display: "flex", flexDirection: "column", margin: "20px", width: "50px", justifyContent: "center", alignItems: "center" }}>
                        <img src={isotopicLogoWhite} color='white' style={{ height: "48px", width: "48px" }} />
                        <label>Isotopic</label>
                    </Link>
                    <Link href={"https://www.seddona.com/games/KingdomKarnage"} target="_blank" style={{ display: "flex", flexDirection: "column", margin: "20px", width: "50px", justifyContent: "center", alignItems: "center" }}>
                        <img src={seddona2} color='white' style={{ height: "48px", width: "48px" }} />
                        <label>Seddona</label>
                    </Link>
                </div>

            </DialogContent>
        </Dialog>
    )
};

export default PlayModal;
