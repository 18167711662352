import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { SelectOption } from '@interfaces/types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiSelect-select": {
        backgroundColor: "#26282F",
        backgroundImage: "none",
        boxShadow: "none",
        display: "flex",
        justifyContent: 'flex-start'
      }
    }
  }));

interface AccordionProps {
    label: string;
    items: SelectOption[];
    value: string;
    handleChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void);
}

const SelectComponent: React.FC<AccordionProps> = ({ label, items, value, handleChange }) => {
    const theme = useTheme();

    const classes = useStyles();

    return (
        <div>
            <InputLabel style={{width: "100%", display: "flex", justifyContent: 'flex-start'}}>{label}</InputLabel>
            <Select
                value={value}
                style={{width: "100%", backgroundColor: "#171A39"}}
                onChange={handleChange}
                className={classes.root}
            >
                {items.map((item) => (
                    <MenuItem
                        style={{width: "100%"}}
                        key={item.label}
                        value={item.value}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default SelectComponent;
