import { useState, useEffect } from 'react';

const useMediaQuery = () => {
  const [isForDesktop, setIsForDesktop] = useState(true);

  const handleMediaQuery = (mediaQuery: MediaQueryListEvent | MediaQueryList) => {
    setIsForDesktop(!mediaQuery.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    handleMediaQuery(mediaQuery);

    mediaQuery.addEventListener('change', handleMediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQuery);
    };
  }, []);

  return isForDesktop;
};

export default useMediaQuery;
