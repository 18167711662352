import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { useAuth } from '../AuthContext';
import { useAccount, useBalance } from 'wagmi';
import TokenTransferForm from '@components/TokenTransferForm';
import { getAccount, changeName, getKktBalance, linkedWallets, transactions, getCoffins } from '@api/account';
import TransactionTable from '@components/TransactionTable';
import { KktTransactions, LinkedWalletsRespone, Account } from '@interfaces/types';
import WithdrawTokenForm from '@components/WithdrawTokenForm';
import './Account.scss';
import Loader from '@components/Loader';
import ChangeNickModal from '@components/ChangeNickModal';
import { toast } from 'react-toastify';
import { Button, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BlacklistWrapper from '@hoc/BlacklistWrapper';



const AccountPage: React.FC = () => {
  const { accountData, userID, logout } = useAuth();
  const navigate = useNavigate();
  const { isConnected, address } = useAccount();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isWalletEmpty, setIsWalletEmpty] = useState<boolean>(false);
  const [userData, setUserData] = useState<Account | null>(accountData);
  const [kktTransactions, setKktTransactions] = useState<KktTransactions>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNickChanged, setIsNickChanged] = useState<boolean>(false);
  const [linkedWalletsResponse, setLinkedWalletsResponse] = useState<LinkedWalletsRespone[]>([]);

  const [activeItem, setActiveItem] = useState<string | null>('Withdraw');

  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  const listItems: string[] = ['Deposit', 'Withdraw'];


  const { data, isError } = useBalance({
    address: address,
    token: '0xe64017bdacbe7dfc84886c3704a26d566e7550de',
  })

  const onChangeUsername = (): void => {
    setIsNickChanged(true);
  };

  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const bla = localStorage.getItem('userID')

      const [transactionsResponse, accountResponse, linkedWalletsResponse] = await Promise.all([
        transactions(accountData ? accountData.id : ""),
        getAccount(userID ? userID : ""),
        linkedWallets(accountData ? accountData.id : "")
      ]);

      setKktTransactions(transactionsResponse);
      const modifiedObject: Account | null = {
        ...accountData,
        displayname: accountResponse.displayname
    } as Account | null;
    setUserData(modifiedObject);
      setLinkedWalletsResponse(linkedWalletsResponse)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getPlayersAssetsData = async () => {
      setIsLoading(true);
      if (accountData) {
          try {
              if (accountData) {
                  // const response = await getCoffins("13570")
                  const response = await getCoffins(accountData.id)

                  if (response.response === "success") {
                      if(response.coffins.length === 0 && response.keys.length === 0 && response.lilith_nfts.length === 0 && response.satrina_nfts.length === 0){
                        setIsWalletEmpty(true);
                      }
                  } else {
                      console.error('Error fetching data');
                  }
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          } finally {
              setIsLoading(false);
              
          }
      }
  }

  getPlayersAssetsData();
}, []);


  useEffect(() => {
    fetchData();
  }, [isNickChanged]);

  const changeNick = async (newNick: string) => {
    if (accountData) {
      try {
        const response = await changeName(
          accountData.id,
          accountData?.hash,
          accountData.session,
          newNick
        );

        if (response.response === "success") {
          closeModal();
          onChangeUsername();
          toast.success("Username changed.")
        } else if (response.response === "session and hash dont match with server") {
          toast.error("Oops! It looks like you need to log in again to complete your purchase.");
          closeModal();
        } else if (response.response === "username taken") {
          closeModal();
          toast.error("Username taken.");
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error on changing username.")
      }
    }
  };

  const renderTokenTransferForm = () => {
    return isConnected ? <TokenTransferForm /> : <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}><w3m-button balance='hide' /></div>;
  };

  const renderWithdrawTokenForm = () => {
    return isConnected ? <WithdrawTokenForm /> : <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}><w3m-button balance='hide' /></div>;
  };

  const renderTransactionTable = () => {
    return <TransactionTable transactions={kktTransactions ? kktTransactions.transactions : []} />;
  };

  const generateAccordionItems = () => {
    return isConnected
      ? [
        {
          title: (
            <div className='accordion-content-div'>
              <label className='accordion-title-label'>Deposit</label>
            </div>
          ),
          content: isConnected && <TokenTransferForm />
        },
        {
          title: (
            <div className='accordion-content-div'>
              <label className='accordion-title-label'>Withdraw KKT</label>
            </div>
          ),
          content: isConnected && <WithdrawTokenForm />
        },
        {
          title: (
            <div className='accordion-content-div'>
              <label className='accordion-title-label'>Transactions</label>
            </div>
          ),
          content: <TransactionTable transactions={kktTransactions ? kktTransactions.transactions : []} />
        }
      ]
      : [
        {
          title: (
            <div className='accordion-content-div'>
              <label className='accordion-title-label'>Transactions</label>
            </div>
          ),
          content: <TransactionTable transactions={kktTransactions ? kktTransactions.transactions : []} />
        }
      ];
  };

  // const renderContent = () => {
  //   return (
  //     <>
  //       <Accordion items={generateAccordionAccountInfo()} />
  //       {isForDesktop && (
  //         <div className='transactions-wrapper' style={{ marginTop: "-4%" }}>
  //           {renderTokenTransferForm()}
  //           {renderWithdrawTokenForm()}
  //         </div>
  //       )}

  //       {!isForDesktop && (
  //         <div className='account-accordion-wrapper'>
  //           <Accordion items={generateAccordionItems()} />
  //         </div>
  //       )}

  //       {isForDesktop && renderTransactionTable()}
  //     </>
  //   );
  // };

  const renderContent = () => {
    return (
      <div style={{ display: "flex", width: "90%", justifyContent: "center", flexWrap: "wrap" }}>
        <div style={{ display: "flex", margin: "20px", width: "400px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", display: "flex" }}>
              <TextField label="Username" style={{ width: "100%", marginRight: "20px" }} type='text' defaultValue={userData?.displayname} inputProps={
                { readOnly: true, }
              } />
              <Button variant="contained" style={{ width: "200px" }} onClick={openModal}>Change</Button>
            </div>

            <TextField label="Email" margin="normal" style={{ width: "100%" }} type='text' defaultValue={userData?.email} inputProps={
              { readOnly: true, }
            } />
            <TextField label="Referal code" margin="normal" style={{ width: "100%" }} type='text' defaultValue={userData?.referral_code} inputProps={
              { readOnly: true, }
            } />
            {linkedWalletsResponse.map((linkedWallet, index) => (
              <div key={index}>
                {Object.entries(linkedWallet).map(([address, value]) => (

                  <TextField label={(value === "1" ? "Active Linked Wallet" : "Linked Wallet") + (value === "1"  ? (isWalletEmpty ? ", NFTs not detected" : ", NFTs detected") : "")} key={index} margin="normal" style={{ width: "100%" }} defaultValue={address} inputProps={
                    { readOnly: true, }
                  } />
                ))}
              </div>
            ))}
          </div>
        </div>
        <BlacklistWrapper isBlacklisted={userData?.blacklist === "1"}>
          <Paper elevation={24} style={{ display: "flex", height: "400px", flexDirection: "column", margin: "20px", width: "400px" }}>
            <div style={{ display: "flex", width: "100%" }}>
              <ul style={{ display: 'flex', listStyle: 'none', lineHeight: '50px', width: "100%" }}>
                {listItems.map((item) => (
                  <li
                    key={item}
                    style={{
                      width: "100%",
                      background: activeItem !== item ? '#242958' : 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>

            <div style={{ display: "flex", width: "100%", height: "100%", marginTop: "20px" }}>

              {activeItem === "Deposit" && renderTokenTransferForm()}
              {activeItem === "Withdraw" && renderWithdrawTokenForm()}
            </div>
          </Paper>
        </BlacklistWrapper>
      </div>
    );
  };

  return (
    <div className='page-content'>
      <div className='page-title-container'>
        <label className='page-title'>Account</label>
      </div>

      <ChangeNickModal isOpen={isOpen} onClose={closeModal} changeUsername={changeNick} />

      {isLoading ? <Loader isLoading={isLoading} /> : renderContent()}
    </div>
  );
};

export default BackgroundTemplate(AccountPage);
