import React, { ReactNode } from 'react';
import { TransactionLog } from '@interfaces/types';
import { toLocalDateTime } from '../helpers';
import useMediaQuery from '@hooks/useMediaQuery';
import { Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

export interface TableColumn {
  key: string;
  label: string;
  width?: string;
  render?: (item: TransactionLog) => ReactNode;
}

export interface InGameTransactionTableProps {
  columns: TableColumn[];
  data: TransactionLog[];
  currentPage: number;
  totalPages: number;
  handlePageChange: (newPage: number) => void;
}

const InGameTransactionTable: React.FC<InGameTransactionTableProps> = ({ columns, data, currentPage, totalPages, handlePageChange }) => {
  const isForDesktop = useMediaQuery();

  return (
    <>
      {isForDesktop && (data.length !== 0 ? <div className='table-wrapper' style={{ marginBottom: "30px", marginTop: "30px" }}>

        <TableContainer component={Paper} elevation={24}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className='pvp-table-body'>
              {data.map((item, index) => (
                <TableRow key={index} style={{ height: "34px" }}>
                  {columns.map((column) => (
                    <TableCell key={column.key}>
                      {column.render ? column.render(item) : (item as any)[column.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        : <div style={{ marginTop: "30px"}}>
          <p>No data...</p>
        </div>)
      }


      <>
        {!isForDesktop && (data.length !== 0 ? <div className='table-wrapper'>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px', width: '90%' }}>
            {data.map((transaction, index) => (
              <div key={index} className='mobile-table'>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column" }}>
                  {columns.map((column) => (
                    <div key={column.key} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', fontSize: '13px', }}>
                      <p>{column.label}:</p>
                      <p>{column.render ? column.render(transaction) : (transaction as any)[column.key]}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div> : <div style={{ marginTop: "30px"}}>
          <p>No data...</p>
        </div>)}
      </>

      {data.length !== 0 && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: "20px" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, newPage) => handlePageChange(newPage)}
        />
      </div>}
    </>

  );
};

export default InGameTransactionTable;
