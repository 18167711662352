import React, { useState } from 'react';
import './Dropdown.scss';
import dropdownArrow from '@assets/dropdownArrow.webp';
import { Link } from 'react-router-dom';

interface MenuItem {
    label: string;
    link: string;
    isForPlay?: boolean;
}

interface DropdownProps {
    title: string;
    content: MenuItem[];
}

const Dropdown: React.FC<DropdownProps> = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleLink = (isForPlay: boolean | undefined, link: string, label: string, index: number) => {

        if(!isForPlay){
            return <Link key={index} to={link}>{label}</Link>
        }

        return  <a key={index} href={link} target="_blank" rel="noopener noreferrer">{label}</a>
    }

    return (
        <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <button className="dropbtn">
                {title}
                {<img src={dropdownArrow} alt="Custom Icon" className="custom-icon" />}
            </button>
            {isOpen && (
                <div className="dropdown-content">
                    {content.map((item, index) => (
                        handleLink(item.isForPlay, item.link, item.label, index)
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
