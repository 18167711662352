import React from 'react';
import Icon_Deck_Uncommon from '@assets/Icon_Deck_Uncommon.webp';
import Icon_Deck_Rare from '@assets/Icon_Deck_Rare.webp';
import '../fonts.scss';
import { PioneerQuest } from '@interfaces/types';
import './PioneerClaimsCard.scss';
import { useAuth } from '../AuthContext';

interface PioneerClaimsCardProps {
    quest: PioneerQuest;
}

const PioneerClaimsCard: React.FC<PioneerClaimsCardProps> = (props: PioneerClaimsCardProps) => {

  const { accountData } = useAuth();

    const displayPrize = () => {
        if (props.quest.prize === "1x Uncommon Pack") {
            return (<p className='pioneer-claim-regular-text' style={{textAlign: "start", color: "#00ff00" }}>{props.quest.prize}</p>)
        }

        return (<p className='pioneer-claim-regular-text' style={{ textAlign: "start", color: "#00d4ff" }}>{props.quest.prize}</p>)
    }

    const displayProgress = () => {
        if (props.quest.prize === "1x Uncommon Pack" && props.quest.progress as unknown as number > 20) {
            return (<p className='pioneer-claim-regular-text' style={{ textAlign: "start", color: "#00ff00" }}>Progress: {props.quest.progress}</p>)
        }
        else if (props.quest.prize === "1x Uncommon Pack" && props.quest.progress as unknown as number < 20) {
            return (<p className='pioneer-claim-regular-text' style={{ textAlign: "start", color: "red" }}>Progress: {props.quest.progress}</p>)
        }

        if (props.quest.prize === "1x Rare Pack" && props.quest.progress as unknown as number > 50) {
            return (<p className='pioneer-claim-regular-text' style={{ textAlign: "start", color: "#00ff00" }}>Progress: {props.quest.progress}</p>)
        }
        else if (props.quest.prize === "1x Rare Pack" && props.quest.progress as unknown as number < 50) {
            return (<p className='pioneer-claim-regular-text' style={{ textAlign: "start", color: "red" }}>Progress: {props.quest.progress}</p>)
        }
    }

    return (
        <div className='pioneer-claim-card-container'>
            <div className='pioneer-claim-card-image-container'>
                {props.quest.prize === "1x Uncommon Pack" && <img
                    src={Icon_Deck_Uncommon}
                    style={{ width: "100%" }}
                    alt={`Icon_Deck_Uncommon`} />}
                {props.quest.prize === "1x Rare Pack" && <img
                    src={Icon_Deck_Rare}
                    style={{ width: "100%" }}
                    alt={`Icon_Deck_Rare`} />}

            </div>
            <div style={{ width: "50%" }}>
                <p style={{ textAlign: "start", fontFamily: "PassionOneRegular", fontSize: "1.5rem", marginBottom: "5px" }}>{props.quest.month}</p>
                {displayPrize()}
                <p className='pioneer-claim-regular-text' style={{textAlign: "start"}}>{props.quest.quest}</p>
                {accountData && displayProgress()}
            </div>
        </div>
    );
};

export default PioneerClaimsCard;
