import React from 'react';
import './Accordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from '@mui/material';

interface IconLinkProps {
    icon: IconProp;
    link: string;
    text: string;
    size?: SizeProp;
}

const IconLink: React.FC<IconLinkProps> = ({ icon, link, text, size }) => {
  return (
    <Link href={link} style={{display: "flex", flexDirection: "column", margin: "20px", width: "50px", justifyContent: "center", alignItems: "center"}} target="_blank">
       <FontAwesomeIcon icon={icon} color='white' size={size ? size  :'3x'} />
        <label>{text}</label>
    </Link>
  );
};

export default IconLink;
