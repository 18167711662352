import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CardQuality, MarketItemDataNew, SelectOption } from '@interfaces/types';
import './ShopOld.scss';
import MarketItem from '@components/MarketItem';
import { useAuth } from '../AuthContext';
import './MarketBuy.scss';
import { getAuctions } from '../api/account';
import { useMyContext } from '../MyContext';
import { fillCardsBuy, fillItemsBuy } from '../helpers/marketItems';
import SelectComponent from '@components/SelectComponent';
import { Button, SelectChangeEvent } from '@mui/material';
import InputComponent from '@components/InputComponent';
import useMediaQuery from '@hooks/useMediaQuery';

interface MarketBuyProps {
    characterFromCardLibrary?: string;
  }
const MarketBuy: React.FC<MarketBuyProps> = ({characterFromCardLibrary}) => {

    const itemsPerPage = 15;
    const isForDesktop = useMediaQuery();
    const { accountData } = useAuth();
    const { characterData, itemsData } = useMyContext();
    const [playerCardAssets, setPlayerCardAssets] = useState<MarketItemDataNew[]>([]);
    const [selectedQuality, setSelectedQuality] = useState<CardQuality>(() => {
        return (localStorage.getItem('selectedQuality') as CardQuality) || CardQuality.All;
    });
    const [searchTerm, setSearchTerm] = useState(() => {
        if(characterFromCardLibrary === undefined)
            return localStorage.getItem('searchTerm') || '';

            return characterFromCardLibrary;
    });
    const [currentPage, setCurrentPage] = useState(1);

    const handleQualityChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const selectedValue = event.target.value;
        setSelectedQuality(selectedValue as CardQuality);
        setCurrentPage(1);
        localStorage.setItem('selectedQuality', selectedValue);
    };

    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(1);
        localStorage.setItem('searchTerm', value);
    };

    const handleClearFilterButtonClick = () => {
        setSelectedQuality(CardQuality.All);
        setSearchTerm("");
        localStorage.setItem('selectedQuality', CardQuality.All);
        localStorage.setItem('searchTerm', "");
    };


    useEffect(() => {
        const getPlayersAssetsData = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        const response = await getAuctions(accountData ? accountData.id : "", selectedQuality.toLowerCase(), searchTerm);

                        const notMyItems = response.filter(item => item.sellers_id !== accountData.id);

                        const cards = fillCardsBuy(response, characterData)
                        const items = fillItemsBuy(response, itemsData);
                        setPlayerCardAssets([...cards, ...items]);
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        getPlayersAssetsData();

    }, [characterData, itemsData, selectedQuality, searchTerm])

    const handlePageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    const totalPages = Math.ceil(playerCardAssets.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = useMemo(() => playerCardAssets.slice(startIndex, endIndex), [playerCardAssets, startIndex, endIndex]);

    const mapEnumToSelectOptions = (enumObj: any): SelectOption[] => {
        const enumOptions = Object.keys(enumObj).map((key) => ({
            value: enumObj[key],
            label: key,
        }));
        return enumOptions;
    };
    return (
        <div className='market-sell-container'>
            <div className='market-sell-filter-wrapper'>
                {isForDesktop && <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "250px" }}>
                            <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />
                        </div>
                        <div style={{ marginLeft: "20px", width: "250px" }}>
                            <InputComponent label='Search' value={searchTerm} handleChange={handleSearchTermChange} />
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </div>}

                {!isForDesktop && <>
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                        <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />

                    </div>
                    <InputComponent label='Search' value={searchTerm} handleChange={handleSearchTermChange} />
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </>}

            </div>
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", justifyContent: "center" }}>
                {currentItems.length > 0 && currentItems.map((x, index) => (
                    <MarketItem key={index} item={x} isCardItem={true} pricePerItem={x.price ? x.price : 0} isForSale={false} />
                ))}
                {currentItems.length === 0 && <div style={{ height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}><label style={{ fontFamily: "Ruda", fontSize: "large" }}>No items!</label></div>}
            </div>

            {currentItems.length > 0 && <div className="pagination" style={{ marginBottom: "50px" }}>
                <button
                    className='pagination-button'
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &laquo;
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className='pagination-button'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    &raquo;
                </button>
            </div>}
        </div>
    );
};


export default MarketBuy;