import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { CardQuality, ItemsData } from '@interfaces/types';
import './ShopOld.scss';
import { MarketItemProps } from '@components/MarketItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMyContext } from '../MyContext';
import { CharacterData } from '@interfaces/types';
import { abilityDescriptions } from '@constants/constants';
import IncrementDecrementInput from '@components/IncrementDecrementInput';
import KKTicker from '@assets/KKTicker.webp';
import './SellItem.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { auctionCreate } from '@api/account';
import ImageButton from '@components/ImageButton';
import Button_Blank2_Toggled from '@assets/Button_Blank2_Toggled.webp';
import Button_Blank2 from '@assets/Button_Blank2.webp';
import { toast } from 'react-toastify';
import BlacklistWrapper from '@hoc/BlacklistWrapper';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { formatNumber } from '../helpers/formating';
import SessionAndHashDialog from '@components/SessionAndHashDialog';


const SellItem: React.FC = () => {

    const isForDesktop = useMediaQuery();
    const navigate = useNavigate();
    const { accountData, logout } = useAuth();

    const location = useLocation();
    const state = location.state as MarketItemProps;
    const { characterData, itemsData } = useMyContext();

    const [specificCharacter, setSpecificCharacter] = useState<CharacterData[]>();
    const [specificItem, setSpecificItem] = useState<ItemsData[]>();
    const [uniqueAbilities, setUniqueAbilities] = useState<string[]>();
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [isSessionAndHashDialogOpen, setIsSessionAndHashDialogOpen] = useState<boolean>(false);

    const handleConfirmationDialogClose = () => {
        setIsConfirmationDialogOpen(false);
    };


    const [item, setItem] = useState({
        id: state.item.asset_id,
        amount: 1,
        cost: 0
    });

    const escapedTitle = encodeURIComponent(state.item.image_name);

    const handleIncrement = (id: string) => {
        setItem((prevItem) => ({ ...prevItem, amount: prevItem.amount + 1, cost: (prevItem.amount + 1) * parseInt(costPerItem !== "" ? costPerItem : "0", 10) }));
    };

    const handleDecrement = (id: string) => {
        if (item.amount > 0) {
            setItem((prevItem) => ({ ...prevItem, amount: prevItem.amount - 1, cost: (prevItem.amount - 1) * parseInt(costPerItem !== "" ? costPerItem : "0", 10) }));
        }
    };

    const handleMaxButtonClicked = () => {
        setItem((prevItem) => ({ ...prevItem, amount: state.item.amount }))
        setItem((prevItem) => ({ ...prevItem, amount: state.item.amount, cost: (state.item.amount) * parseInt(costPerItem !== "" ? costPerItem : "0", 10) }));
    };


    const [count, setCount] = useState(1);
    const [costPerItem, setCostPerItem] = useState("");

    const handleCountChange = (newCount: number) => {
        setCount(newCount);
    };

    useEffect(() => {
        getCharacterAbilities();
    }, []);

    const getRarityColor = () => {
        switch (state.item.quality) {
            case CardQuality.Common:
                return { color: "#A4A4A4" };
            case CardQuality.Uncommon:
                return { color: "#68DF2F" };
            case CardQuality.Rare:
                return { color: "#33C4F1" };
            case CardQuality.Epic:
                return { color: "#7C4FFF" };
            case CardQuality.Legendary:
                return { color: "#E26D00" };
            default:
                return { color: "#A4A4A4" };
        }
    }

    const getCharacterAbilities = () => {
        const transformedString = state.item.game_reference?.replace(/\s/g, '').toLowerCase();

        if (state.item.asset_type === "card") {
            const filteredArray = characterData && characterData.filter((item) => {
                return item.GameReference === transformedString;
            });

            setSpecificCharacter(filteredArray ? filteredArray : []);

            const combinedAbilitiesSet: Set<string> = new Set();

            filteredArray && filteredArray.forEach((character) => {
                combinedAbilitiesSet.add(character.Ability1);
                combinedAbilitiesSet.add(character.Ability2);
                combinedAbilitiesSet.add(character.Ability3);
            });

            const uniqueAbilities: string[] = Array.from(combinedAbilitiesSet);
            setUniqueAbilities(uniqueAbilities);
        }
        if (state.item.asset_type === "item") {
            const filteredArray = itemsData && itemsData.filter((item) => {
                return item.gamereference === transformedString;
            });

            setSpecificItem(filteredArray ? filteredArray : []);

            const combinedAbilitiesSet: Set<string> = new Set();

            filteredArray && filteredArray.forEach((item) => {
                combinedAbilitiesSet.add(item.ability1);
                combinedAbilitiesSet.add(item.ability2);
                combinedAbilitiesSet.add(item.ability3);
            });

            const uniqueAbilities: string[] = Array.from(combinedAbilitiesSet);
            setUniqueAbilities(uniqueAbilities);
        }
    }

    const renderUniqueAbilities = (ulStyles: React.CSSProperties) => (<ul style={{ ...ulStyles }}>
        {uniqueAbilities?.map((ability, index) => (
            abilityDescriptions[ability] !== "" && <li
                style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
                key={index}
            >
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    <span
                        style={{
                            borderBottom: '1px solid #0091BF',
                            display: 'inline',
                        }}
                    >
                        {ability}:
                    </span>
                </span>
                <span>
                    {abilityDescriptions[ability]}
                </span>
            </li>
        ))}
    </ul>);

    const renderItemAbilities = (ulStyles: React.CSSProperties) => (<ul style={{ ...ulStyles }}>
        {uniqueAbilities?.map((ability, index) => (
            abilityDescriptions[ability] !== "" && <li
                style={{
                    marginBottom: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                }}
                key={index}
            >
                <span style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                    <span
                        style={{
                            borderBottom: '1px solid #0091BF',
                            display: 'inline',
                        }}
                    >
                        {ability === "blank" ? "" : ability}
                    </span>
                </span>
            </li>
        ))}
    </ul>);

    const sell = async () => {

        if (accountData) {
            try {
                if (accountData) {
                    const response = await auctionCreate(accountData.id,
                        accountData.session,
                        accountData?.hash,
                        state.item.asset_type,
                        state.item.asset_id,
                        item.amount as unknown as string,
                        state.item.token_index,
                        state.item.game_reference,
                        costPerItem,
                        state.item.quality);

                    if (response.response === "success") {
                        navigate('/shop');
                        toast.success(`Lvl.${state.item.level} ${state.item.title} x${item.amount as unknown as string} will be listed in 5 minutes!`)
                    } else if (response.response === "session and hash dont match with server") {
                        setIsSessionAndHashDialogOpen(true);
                    } else {
                        toast.error("Unable to list asset on the market.")
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Unable to list asset on the market.")
            }
        }
    };

    const renderForDesktop = () => (<div className='desktop-container'>
        <div className='desktop-card-details-container'>

            <div className='desktop-image-div' style={{ border: "2px solid #0091BF", padding: "5px" }}>
                <img src={`/assets/${escapedTitle}.webp`} alt="Description" className="desktop-image" />
            </div>

            <div style={{ width: "370px" }}>
                {state.item.asset_type === "card" && specificCharacter ? (
                    <div className='card-power-wrapper'>
                        <p className="font-Ruda card-power-text"><span className='card-power-title'>Attack:</span> {specificCharacter[0].Damage}</p>
                        <p className="font-Ruda card-power-text"><span className='card-power-title'>Health:</span> {specificCharacter[0].Health}</p>
                        <p className="font-Ruda card-power-text"><span className='card-power-title'>Count:</span> {specificCharacter[0].Countdown}</p>
                    </div>
                ) : ""}
                {state.item.asset_type === "card" && (uniqueAbilities
                    ? renderUniqueAbilities({ listStyle: 'none', padding: 0 })
                    : <p>No abilities available.</p>)}
                {state.item.asset_type === "item" && (uniqueAbilities
                    ? renderItemAbilities({ listStyle: 'none', padding: 0 })
                    : <p>No abilities available.</p>)}
            </div>
        </div>

        <div style={{ display: "flex" }}>

            <div className='desktop-sell-manipulation-div'>
                <div className='desktop-price-per-unit-container '>
                    <span className='desktop-price-per-unit-text '>Price per unit: </span>

                    <input
                        type="text"
                        value={costPerItem === null ? '' : costPerItem}
                        className='sell-item-input'
                        onChange={(e) => {
                            const newValue = e.target.value;
                            if (/^\d*$/.test(newValue) || newValue === '') {
                                setCostPerItem(newValue === null ? "" : newValue);
                                setItem((prevItem) => ({ ...prevItem, cost: (prevItem.amount) * parseInt(newValue !== "" ? newValue : "0", 10) }));

                            }
                        }} />
                </div>

                <div className='desktop-increment-decrement-wrapper'>
                    <div>
                        <IncrementDecrementInput
                            onCountChange={handleCountChange}
                            incrementItem={handleIncrement}
                            decrementItem={handleDecrement}
                            amount={item.amount}
                            maxAmount={state.item.amount}
                            id={state.item.title} />
                    </div>

                    <div style={{ marginLeft: "30px" }}>
                        <ImageButton src={Button_Blank2} handleItemClick={() => handleMaxButtonClicked()} style={{ width: "100px", height: "50px" }} alt={"Button_Blank2"} text='MAX' />
                    </div>
                </div>
            </div>

            <div className='desktop-sell-wrapper'>
                <div className='ddsktop-sell-amount-wrapper'>
                    <p className='desktop-sell-amount-text '>{formatNumber(item.cost as unknown as string)} <img style={{ height: "3.4rem" }} src={KKTicker} alt={`${KKTicker}`} /></p>
                </div>
                <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                    <ImageButton src={Button_Blank2_Toggled} handleItemClick={() => setIsConfirmationDialogOpen(true)} style={{ width: "120px", height: "50px" }} alt={"Button_Blank2_Toggled"} text='SELL' />
                </BlacklistWrapper>

            </div>
        </div>
    </div>);

    const renderForMobile = () => (<div className='mobile-container'>
        <div className='mobile-image-wrapper'>
            <img src={`/assets/${escapedTitle}.webp`} alt="Description" className='mobile-image' />
        </div>

        <div className='mobile-items-to-sell-wrapper'>
            <div className='mobile-items-to-sell-container'>
                <div>
                    <IncrementDecrementInput
                        onCountChange={handleCountChange}
                        incrementItem={handleIncrement}
                        decrementItem={handleDecrement}
                        amount={item.amount}
                        maxAmount={state.item.amount}
                        id={state.item.title} />
                </div>

                <div style={{ marginLeft: "30px" }}>
                    <ImageButton src={Button_Blank2} handleItemClick={() => handleMaxButtonClicked()} style={{ width: "100px", height: "50px" }} alt={"Button_Blank2"} text='MAX' />
                </div>
            </div>
        </div>

        <div className='mobile-price-per-unit-container'>
            <span className='mobile-price-per-unit-text'>Price per unit: </span>

            <input
                type="text"
                value={costPerItem === null ? '' : costPerItem}
                className='sell-item-input'
                onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^\d*$/.test(newValue) || newValue === '') {
                        setCostPerItem(newValue === null ? "" : newValue);
                        setItem((prevItem) => ({ ...prevItem, cost: (prevItem.amount) * parseInt(newValue !== "" ? newValue : "0", 10) }));

                    }
                }} />
        </div>

        <div className='mobile-sell-ampunt-div'>
            <p className='mobile-sell-amount-text'>{formatNumber(item.cost as unknown as string)} <img style={{ height: "3.4rem" }} src={KKTicker} alt={`${KKTicker}`} /></p>
        </div>

        <div className='mobile-sell-button-wrapper'>
            <BlacklistWrapper isBlacklisted={accountData?.blacklist === "1"}>
                <ImageButton src={Button_Blank2_Toggled} handleItemClick={() => setIsConfirmationDialogOpen(true)} style={{ width: "120px", height: "50px" }} alt={"Button_Blank2_Toggled"} text='SELL' />
            </BlacklistWrapper>
        </div>

        <div className='mobile-card-details-container'>
            {state.item.asset_type === "card" && specificCharacter ? (
                <div className='card-power-wrapper'>
                    <p className="font-Ruda card-power-text"><span className='card-power-title'>Attack:</span> {specificCharacter[0].Damage}</p>
                    <p className="font-Ruda card-power-text"><span className='card-power-title'>Health:</span> {specificCharacter[0].Health}</p>
                    <p className="font-Ruda card-power-text"><span className='card-power-title'>Count:</span> {specificCharacter[0].Countdown}</p>
                </div>
            ) : ""}
            {state.item.asset_type === "card" && uniqueAbilities
                ? renderUniqueAbilities({ listStyle: 'none', padding: 0, display: "flex", width: "100%", flexDirection: "column", flexWrap: "wrap" })
                : <p>No abilities available.</p>}
            {state.item.asset_type === "item" && (uniqueAbilities
                ? renderItemAbilities({ listStyle: 'none', padding: 0 })
                : <p>No abilities available.</p>)}
        </div>
    </div>);

    return (
        <div className="page-content">
            <div className='page-title-container'>
                {(Number.isNaN(state.item.level)) && <label className='page-title' style={getRarityColor()}>{state.item.title}</label>}
                {(!Number.isNaN(state.item.level))  && <label className='page-title' style={getRarityColor()}>Lvl {state.item.level} - {state.item.title}</label>}
            </div>

            <SessionAndHashDialog isOpen={isSessionAndHashDialogOpen}/>

            <Dialog
                open={isConfirmationDialogOpen}
                onClose={handleConfirmationDialogClose}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">
                    Please confirm the sale:
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <p>{`${item.amount as unknown as string} x ${state.item.title}  (Level ${state.item.level})`}</p>
                            <p>{`Price per item = ${formatNumber(costPerItem)} KKT`}</p>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={sell} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {isForDesktop ? renderForDesktop() : renderForMobile()}
        </div>
    );
};


export default BackgroundTemplate(SellItem);