import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CardQuality, MarketItemDataListed, SelectOption } from '@interfaces/types';
import './ShopOld.scss';
import { useAuth } from '../AuthContext';
import './MarketSell.scss';
import { auctionUnlist, getMyAuctions } from '../api/account';
import { useMyContext } from '../MyContext';
import { fillCardsMyAuction, fillItemsMyAuction } from '../helpers/marketItems';
import MarketItemListed from '@components/MarketItemListed';
import { toast } from 'react-toastify';
import useMediaQuery from '@hooks/useMediaQuery';
import SelectComponent from '@components/SelectComponent';
import { Button, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SessionAndHashDialog from '@components/SessionAndHashDialog';

const assetOptions: SelectOption[] = [
    { value: 'Cards', label: 'Cards' },
    { value: 'Items', label: 'Items' },
];

const MarketListed: React.FC = () => {

    const itemsPerPage = 15;
    const isForDesktop = useMediaQuery();
    const navigate = useNavigate();
    const { accountData, logout } = useAuth();
    const { characterData, itemsData } = useMyContext();
    const [playerCardAssets, setPlayerCardAssets] = useState<MarketItemDataListed[]>([]);
    const [playerItemAssets, setPlayerItemAssets] = useState<MarketItemDataListed[]>([]);
    const [selectedQuality, setSelectedQuality] = useState<CardQuality>(() => {
        return (localStorage.getItem('marketListedSelectedQuality') as CardQuality) || CardQuality.All;
    });
    const [selectedAsset, setSelectedAsset] = useState<string>(() => {
        return localStorage.getItem('marketListedSelectedAsset') || 'Cards';
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [isSessionAndHashDialogOpen, setIsSessionAndHashDialogOpen] = useState<boolean>(false);

    const handleQualityChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const selectedValue = event.target.value;
        setSelectedQuality(selectedValue as CardQuality);
        setCurrentPage(1);
        localStorage.setItem('marketListedSelectedQuality', selectedValue);
    };

    const handleAssetChange = (event: SelectChangeEvent<string>, child: ReactNode) => {
        const selectedValue = event.target.value;
        setSelectedAsset(selectedValue);
        setCurrentPage(1);
        localStorage.setItem('marketListedSelectedAsset', selectedValue);
    };

    const handleClearFilterButtonClick = () => {
        setSelectedQuality(CardQuality.All);
        setSelectedAsset("Cards");
        localStorage.setItem('marketListedSelectedQuality', CardQuality.All);
        localStorage.setItem('marketListedSelectedAsset', "Cards");
    };


    const getPlayersAssetsData = async () => {
        if (accountData) {
            try {
                const response = await getMyAuctions(accountData.id, accountData.session, accountData.hash);
    
                if (typeof response === 'string') {
                    if (response === "session and hash dont match with server") {
                        console.error(response);
                        setIsSessionAndHashDialogOpen(true);
                    }
                } else {
                    const cards = fillCardsMyAuction(response, characterData);
                    const items = fillItemsMyAuction(response, itemsData);
                    setPlayerCardAssets(cards);
                    setPlayerItemAssets(items);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    useEffect(() => {
        getPlayersAssetsData();

    }, [characterData, itemsData, selectedAsset])

    const handleUnlist = async (auction_id: string) => {
        if (accountData) {
            try {
                const response = await auctionUnlist(accountData ? accountData.id : "", accountData ? accountData.session : "", accountData ? accountData?.hash : "", auction_id);

                if (response.response === "success") {
                    toast.success("Asset delisted successfully.")
                    getPlayersAssetsData();
                } else if (response.response === "session and hash dont match with server") {
                    toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                    logout();
                    navigate('/login');
                } else {
                    toast.error("Unable to delist asset.")
                }

            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Unable to delist asset.")
            }
        }
    }

    const filteredCardsByQuality = selectedQuality === CardQuality.All
        ? playerCardAssets
        : playerCardAssets.filter(item => item.quality === selectedQuality);

    const filteredItemsByQuality = selectedQuality === CardQuality.All
        ? playerItemAssets
        : playerItemAssets.filter(item => item.quality === selectedQuality);

    let totalPages: number = 0;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let currentItems: MarketItemDataListed[] = [];

    switch (selectedAsset) {
        case "Cards":
            currentItems = useMemo(() => filteredCardsByQuality.slice(startIndex, endIndex), [filteredCardsByQuality, startIndex, endIndex]);
            totalPages = Math.ceil(filteredCardsByQuality.length / itemsPerPage);
            break;
        case "Items":
            currentItems = useMemo(() => filteredItemsByQuality.slice(startIndex, endIndex), [filteredItemsByQuality, startIndex, endIndex]);
            totalPages = Math.ceil(filteredItemsByQuality.length / itemsPerPage);
            break;
        default:
            break;
    }

    const handlePageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    const mapEnumToSelectOptions = (enumObj: any): SelectOption[] => {
        if (!enumObj || typeof enumObj !== 'object') {
            return [];
        }

        return Object.keys(enumObj).map((key) => ({
            value: enumObj[key],
            label: key,
        }));
    };

    return (
        <div className='market-sell-container'>
            <div className='market-sell-filter-wrapper'>
                <SessionAndHashDialog isOpen={isSessionAndHashDialogOpen}/>
                {isForDesktop && <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "250px" }}>
                            <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />
                        </div>
                        <div style={{ marginLeft: "20px", width: "250px" }}>
                            <SelectComponent items={assetOptions} label='Asset type' value={selectedAsset} handleChange={handleAssetChange} />
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </div>}

                {!isForDesktop && <>
                    <div style={{ width: "100%", marginBottom: "10px" }}>
                        <SelectComponent items={mapEnumToSelectOptions(CardQuality)} label='Rarity' value={selectedQuality} handleChange={handleQualityChange} />
                    </div>
                    <SelectComponent items={assetOptions} label='Asset type' value={selectedAsset} handleChange={handleAssetChange} />
                    <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    </div>
                </>}
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px", justifyContent: "center" }}>
                {selectedAsset === "Cards" && currentItems.map((x, index) => (
                    <MarketItemListed key={index} item={x} handleUnlist={() => handleUnlist(x.auction_id)} />
                ))}
                {selectedAsset === "Items" && currentItems.map((x, index) => (
                    <MarketItemListed key={index} item={x} handleUnlist={() => handleUnlist(x.auction_id)} />
                ))}
            </div>

            {currentItems.length > 0 && <div className="pagination" style={{ marginBottom: "50px" }}>
                <button
                    className='pagination-button'
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    &laquo;
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    className='pagination-button '
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    &raquo;
                </button>
            </div>}
        </div>

    );
};

export default MarketListed;