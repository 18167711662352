import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { ShopItem } from '@interfaces/types';
import { paymentOptions } from '@constants/constants';
import './ShopBasket.scss';
import ShopItemCard from '@components/ShopItemCard';
import FilterSelect from '@components/FilterSelect';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { addToCart, decrementItem, incrementItem } from '@redux/store';
import useMediaQuery from '@hooks/useMediaQuery';

interface ShopBasketProps {
    cartItems: ShopItem[];
    addToCart: (item: ShopItem) => void;
    incrementItem: (id: string) => void;
    decrementItem: (id: string) => void;
}

const ShopBasket: React.FC<ShopBasketProps> = ({ cartItems, addToCart, incrementItem, decrementItem }) => {

    const isForDesktop = useMediaQuery();

    const [selectedPaymentOption, setSelectedPaymentOption] = useState<string>('');

    const calculateTotalPrice = (items: ShopItem[]): number => {
        const totalPrice = items.reduce((total, item) => {
            const discountedPrice = item.discountInPercentage
                ? item.price - (item.price * item.discountInPercentage) / 100
                : item.price;

            return total + discountedPrice * (item.count || 1);
        }, 0);

        return totalPrice;
    };

    const handlePaymentOptionSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedPaymentOption(event.target.value);
    };
    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title'>Basket</label>
            </div>
            <div className='shop-basket-container'>
                <div className='shop-basket-items-container'>
                    {cartItems.map((x, index) => (
                        <ShopItemCard handleAddToBasket={() => { }} key={index} item={x} enableAddToBasket={false} incrementItem={() => incrementItem(x.id)} decrementItem={() => decrementItem(x.id)} />
                    ))}
                </div>
                <div className='shop-basket-amount-sum'>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Subtotal:</label>
                        <label>${calculateTotalPrice(cartItems)}</label>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '4%' }}>
                        <label style={{ fontFamily: 'PassionOneRegular', fontSize: '27px' }}>Total:</label>
                        <label style={{ fontFamily: 'PassionOneRegular', fontSize: '27px' }}>${calculateTotalPrice(cartItems)}</label>
                    </div>

                    <div className='shop-basket-buttons'>
                        <FilterSelect
                            id="mySelect1"
                            value={selectedPaymentOption}
                            onChange={handlePaymentOptionSelectChange}
                            options={paymentOptions}
                            placeholder="Payment Option..."
                            className={"shop-basket-filter-select"}
                        />

                        <button className='checkout-button'
                            onClick={() => { }}
                        >
                            Proceed to checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: { shop: ShopItem[] }) => {
    return {
        cartItems: state.shop,
    };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addToCart: (item: ShopItem) => dispatch(addToCart(item)),
        incrementItem: (id: string) => dispatch(incrementItem({ id })),
        decrementItem: (id: string) => dispatch(decrementItem({ id })),
    };
};

export default BackgroundTemplate(connect(mapStateToProps, mapDispatchToProps)(ShopBasket));