import React, { } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './Events.scss';
import useMediaQuery from '@hooks/useMediaQuery';
import { Paper, Typography } from '@mui/material';

const Eula: React.FC = () => {
    const isForDesktop = useMediaQuery();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >End-User License Agreement</label>
            </div>

            {isForDesktop && <Paper style={{ width: "70%", height: "100%", marginBottom: "40px", display: "flex", flexDirection: "column", padding: "20px", justifyContent: "flex-start" }} elevation={24}>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Last updated: 19/1/2024
                    <br />
                    Please read this End-User License Agreement ("Agreement") carefully before downloading or playing Kingdom Karnage("Application").
                    <br />

                    By downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.
                    <br />

                    If you do not agree to the terms of this Agreement, do not download or use the Application.
                </Typography>
                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    License
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Restrictions
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    You agree not to, and you will not permit others to:
                    <br />
                    a) license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Modifications to Application
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Exploitation
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Taking advantage of any possible exploit rather than reporting such a bug to Kepithor Studios is strictly prohibited and a breach of these terms.

                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Automation of Play
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Automating play by any means is strictly prohibited and a breach of these terms.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Multiple Accounts
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Creating multiple accounts or operating multiple accounts at the same time is strictly prohibited and a breach of these terms.
                    Accounts flagged as multi-accounting will have 4 weeks to complete KYC.
                    If not completed within that time the account will be closed and any KKT added to the following seasons prizepool.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Inactive Email Addresses
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Accounts made with inactive email addresses may be deleted and any associated assets forfeited.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Withdrawals
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios reserves the right to deny withdrawal features for you if Kepithor Studios believes you are breaching any of these terms. Any assets you have on account are thus account bound and virtual only.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Account Banning
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios reserves the right to ban players and/or deny features to anyone breaching any of these terms. You understand that breaching these terms may result in your assets being made account bound.
                </Typography>


                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Inappropriate Player Names
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios reserves the right to change the username of any account with an inappropriate username. Inappropriate usernames include usernames which are: - Obscene/racist. - Advertising other products/services. - Exposing real-life contact details.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Competitions
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios frequently holds promotional competitions and reserves the right to cancel them at any time Kepithor Studios deems necessary. Players that competed in cancelled competitions are not entitled to compensation, however Kepithor Studios may choose to provide compensation at their discretion.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Term and Termination
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    This Agreement shall remain in effect until terminated by you or Kepithor Studios.
                    <br />
                    Kepithor Studios may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
                    <br />

                    This Agreement will terminate immediately, without prior notice from Kepithor Studios, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your desktop.
                    <br />

                    Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your mobile device or from your desktop.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Severability
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Amendments to this Agreement
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    Kepithor Studios reserves the right, at its sole discretion, to modify or replace this Agreement at any time and do not need to provide prior notice to any new terms taking effect.
                </Typography>

                <Typography variant="h6" textAlign={'start'} gutterBottom fontFamily={"Ruda"} fontWeight={'900'}>
                    Contact Information
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'15px'}>
                    If you have any questions about this Agreement, please contact us.
                </Typography>

            </Paper>}

            {!isForDesktop && <Paper style={{ width: "95%", height: "100%", display: "flex", flexDirection: "column", padding: "20px", marginBottom: "40px" }} elevation={24}>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Last updated: 19/1/2024
                    <br />
                    Please read this End-User License Agreement ("Agreement") carefully before downloading or playing Kingdom Karnage("Application").
                    <br />

                    By downloading or using the Application, you are agreeing to be bound by the terms and conditions of this Agreement.
                    <br />

                    If you do not agree to the terms of this Agreement, do not download or use the Application.
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    License
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Restrictions
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    You agree not to, and you will not permit others to:
                    <br />
                    a) license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application or make the Application available to any third party.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Modifications to Application
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Exploitation
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Taking advantage of any possible exploit rather than reporting such a bug to Kepithor Studios is strictly prohibited and a breach of these terms.

                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Automation of Play
                </Typography>
                <Typography component={'span'} textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Automating play by any means is strictly prohibited and a breach of these terms.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Multiple Accounts
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Creating multiple accounts or operating multiple accounts at the same time is strictly prohibited and a breach of these terms.
                    Accounts flagged as multi-accounting will have 4 weeks to complete KYC.
                    If not completed within that time the account will be closed and any KKT added to the following seasons prizepool.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Inactive Email Addresses
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Accounts made with inactive email addresses may be deleted and any associated assets forfeited.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Withdrawals
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios reserves the right to deny withdrawal features for you if Kepithor Studios believes you are breaching any of these terms. Any assets you have on account are thus account bound and virtual only.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Account Banning
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios reserves the right to ban players and/or deny features to anyone breaching any of these terms. You understand that breaching these terms may result in your assets being made account bound.
                </Typography>


                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Inappropriate Player Names
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios reserves the right to change the username of any account with an inappropriate username. Inappropriate usernames include usernames which are: - Obscene/racist. - Advertising other products/services. - Exposing real-life contact details.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Competitions
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios frequently holds promotional competitions and reserves the right to cancel them at any time Kepithor Studios deems necessary. Players that competed in cancelled competitions are not entitled to compensation, however Kepithor Studios may choose to provide compensation at their discretion.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Term and Termination
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    This Agreement shall remain in effect until terminated by you or Kepithor Studios.
                    <br />
                    Kepithor Studios may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
                    <br />

                    This Agreement will terminate immediately, without prior notice from Kepithor Studios, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your mobile device or from your desktop.
                    <br />

                    Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your mobile device or from your desktop.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Severability
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Amendments to this Agreement
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    Kepithor Studios reserves the right, at its sole discretion, to modify or replace this Agreement at any time and do not need to provide prior notice to any new terms taking effect.
                </Typography>

                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'17px'} fontWeight={'900'}>
                    Contact Information
                </Typography>
                <Typography paragraph textAlign={'start'} fontFamily={"Ruda"} fontSize={'13px'}>
                    If you have any questions about this Agreement, please contact us.
                </Typography>

            </Paper>}
        </div>
    );
};

export default BackgroundTemplate(Eula);
