import React, {  } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './Events.scss';
import useMediaQuery from '@hooks/useMediaQuery';

const Faq: React.FC = () => {

    const isForDesktop = useMediaQuery();
    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >Frequently Asked Questions</label>
            </div>
            <div style={{ width: "80%", marginBottom: "50px" }}>
                {question("What is Kingdom Karnage?", "Kingdom Karnage is a cross-platform, animated combat, trading card game. It's available to play on PC and Mobile.")}
                {question("Is Kingdom Karnage a blockchain game?", "Kingdom Karnage has been built so that all the major parts of the game can be played virtually with no blockchain wallet required. To access some advanced features like NFT rentals and reaching max level characters, a blockchain wallet is required. We can help players with recommendations of wallets they can sign up to with social logins like Google.")}
                {question("What blockchain is Kingdom Karnage on?", "Kingdom Karnage is currently on Binance Smart Chain. We will be adding more chains later this year.")}
                {question("Can I buy and sell assets?", "Whether virtual or NFT, all assets can be traded. The game features an in-game auction house. Epic and Legendary characters are never sold in card packs, protecting their value for player sales.")}
                {question("Can I earn money playing?", "Kingdom Karnage has its own native cryptocurrency KKT. You can earn KKT in various ways such as selling loots or completing dungeon bounties for other players. KKT can be used in its virtual form as a premium currency or if you link a blockchain wallet can be withdrawn and traded on Bitmart or decentralised exchanges such as PancakeSwap.")}
                {question("Who can play Kingdom Karnage?", "Kingdom Karnage is free to play and available on PC and mobile devices worldwide. As long as you have a compatible device, you are welcome to play!")}

            </div>
        </div>
    );

    function question(question: string, answer: string) {
        return <div style={{ display: "flex", width: "100%", flexDirection: "column", marginTop: "20px", justifyContent: "flex-start", alignItems: "flex-start", borderBottom: "1px solid #005593" }}>
            <label style={{ fontFamily: "PassionOneRegular", fontSize: isForDesktop ? "35px" : "22px", marginBottom: "16px" }}>{question}</label>
            <label style={{ fontFamily: "Ruda", fontSize: isForDesktop ? "15px" : "13px", marginBottom: "20px", textAlign: "start" }}>{answer}</label>
        </div>;
    }
};

export default BackgroundTemplate(Faq);
