export const toLocalDateTime = (utcDateTimeString: string) => {
    const utcDateTime = new Date(utcDateTimeString + 'Z');

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        hourCycle: 'h23',
      };
      
      return utcDateTime.toLocaleString(undefined, options);
}