import { ShopItem } from '@interfaces/types';
import { configureStore, createSlice } from '@reduxjs/toolkit';

const shopSlice = createSlice({
  name: 'shop',
  initialState: [] as ShopItem[],
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.find((item) => item.id === action.payload.id);

      if (existingItem) {
        const index = state.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state[index].count = (state[index].count || 0) + 1;
        }
      } else {
        state.push({ ...action.payload, count: 1 });
      }
    },
    removeFromCart: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    incrementItem: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state[index].count = (state[index].count || 0) + 1;
      }
    },
    decrementItem: (state, action) => {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        const newCount = (state[index].count || 0) - 1;
        if (newCount <= 0) {
          state.splice(index, 1);
        } else {
          state[index].count = newCount;
        }
      }
    },
  },
});

const store = configureStore({
  reducer: {
    shop: shopSlice.reducer,
  },
});

export const { addToCart, removeFromCart, incrementItem, decrementItem } = shopSlice.actions;

export default store;
