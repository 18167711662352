import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import { Paper } from '@mui/material';
import { useAuth } from '../AuthContext';
import { makeStyles } from "@material-ui/core";
import LogoTextOnly from '@assets/LogoTextOnly.webp';

interface TemporaryDrawerProps {
    isOpen: boolean;
    toggleDrawer: (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none"
        },
        "& .MuiDrawer-paper": {
            backgroundColor: "#26282F",
            backgroundImage: "none"
        }
    }
}));

const TemporaryDrawer: React.FC<TemporaryDrawerProps> = ({ isOpen, toggleDrawer }) => {
    const { accountData, logout } = useAuth();

    const accountSubmenuItems = [
        { label: 'Overview', link: '/account' },
        { label: 'Account settings', link: '/account-settings' },
        { label: 'Transactions log', link: '/transactions-log' },
        { label: 'Midas vault', link: '/midas-vault' },
        { label: 'Manage midas', link: '/midas' },
        { label: 'Vampires', link: '/vampires' },
    ];

    const playSubmenuItems = [
        { label: 'iOS', link: 'https://apps.apple.com/us/app/kingdom-karnage/id6443679225' },
        { label: 'Android', link: 'https://play.google.com/store/apps/details?id=com.KepithorStudios.KingdomKarnage&pli=1' },
        { label: 'Steam', link: 'https://store.steampowered.com/app/1305000/Kingdom_Karnage' },
        { label: 'Isotopic', link: "https://isotopic.io/game/?game=Kingdom_Karnage" },
        { label: 'Seddona', link: "https://www.seddona.com/games/KingdomKarnage" },
    ];

    const learnSubmenuItems = [
        { label: 'FAQs', link: '/faq' },
        { label: 'Card library', link: '/card-library' },
        // { label: 'DYNAMIC CARDS', link: '/dynamic-cards' },
        { label: 'Guide', link: 'https://kepithorstudios.gitbook.io/kingdom-karnage' },
    ];

    const newsSubmenuItems = [
        { label: 'Updates', link: 'https://kepithorstudios.gitbook.io/kingdom-karnage/patch-notes' },
        { label: 'Events', link: '/events' },
        { label: 'Media', link: 'https://drive.google.com/drive/folders/1TUpaZosehIDdVCvXKw3E-KvLEkqEudtA' },
    ];

    const legalSubmenuItems = [
        { label: 'Privacy policy', link: '/privacypolicy' },
        { label: 'Terms and conditions', link: '/terms' },
        { label: 'Eula', link: '/eula' },
    ];

    const shopSubmenuItems = [
        { label: 'Auction house', link: '/shop' },
        { label: 'Kingdom Karnage Shop', link: '/kingdom-karnage-shop' },
    ];

    const supportSubmenuItems = [
        { label: 'Links', link: '/links' },
        // { label: 'CONTACT US', link: '/contact' },
    ];

    const logoutUser = () => {
        logout();
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <Toolbar style={{ height: "70px" }} >
                    <img src={LogoTextOnly} alt='LogoTextOnly' style={{ width: "110px" }} />
                </Toolbar>
                <ListItem button component={Link} to="/" key="home">
                    <ListItemText primary="Home" />
                </ListItem>
                {!accountData && <>
                    <ListItem button component={Link} to="/login" key="login">
                        <ListItemText primary="Login" />
                    </ListItem>
                </>}
                {accountData && <>
                    <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">ACCOUNT</Divider>
                    {accountSubmenuItems.map((x, index) => (
                        <ListItem button component={Link} to={x.link} key={`account-${index}`}>
                            <ListItemText primary={x.label} />
                        </ListItem>
                    ))}
                </>}
                {/* <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">PLAY</Divider>
                {playSubmenuItems.map((x, index) => (
                    <ListItem button component={Link} to={x.link} key={`play-${index}`}>
                        <ListItemText primary={x.label} />
                    </ListItem>
                ))} */}

                {accountData && <>
                    <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">MARKETPLACE</Divider>
                    {shopSubmenuItems.map((x, index) => (
                        <ListItem button component={Link} to={x.link} key={`shop-${index}`}>
                            <ListItemText primary={x.label} />
                        </ListItem>
                    ))}
                </>}
                <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">LEARN</Divider>
                {learnSubmenuItems.map((x, index) => (
                    <ListItem button component={Link} to={x.link} key={`learn-${index}`}>
                        <ListItemText primary={x.label} />
                    </ListItem>
                ))}
                <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">SUPPORT</Divider>
                {supportSubmenuItems.map((x, index) => (
                    <ListItem button component={Link} to={x.link} key={`news-${index}`}>
                        <ListItemText primary={x.label} />
                    </ListItem>
                ))}
                <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">NEWS</Divider>
                {newsSubmenuItems.map((x, index) => (
                    <ListItem button component={Link} to={x.link} key={`news-${index}`}>
                        <ListItemText primary={x.label} />
                    </ListItem>
                ))}

                <Divider style={{ color: "rgb(107, 122, 144)", fontSize: "0.5rem", fontWeight: "700", letterSpacing: "0.08rem" }} textAlign="left">LEGAL</Divider>
                {legalSubmenuItems.map((x, index) => (
                    <ListItem button component={Link} to={x.link} key={`news-${index}`}>
                        <ListItemText primary={x.label} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const classes = useStyles();
    return (
        <div>
            <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)} className={classes.root}>
                <Paper style={{ minWidth: "250px" }}>
                    {list()}
                </Paper>
            </Drawer>
        </div>
    );
};

export default TemporaryDrawer;
