import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { WagmiConfig } from 'wagmi';
import { Chain, arbitrum, mainnet, bsc } from 'viem/chains';

interface Metadata {
  name: string;
  description: string;
  url: string;
  icons: string[];
}

const projectId: string = '4b2479c24edfcf89ca7e4fcc1bfb032f';

const metadata: Metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains: Chain[] = [mainnet, arbitrum, bsc];
const wagmiConfig: any = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

export default wagmiConfig;
