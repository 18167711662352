import React from 'react';
import './ImageButton.scss';

interface ImageButtonProps {
  src: string;
  alt: string;
  text: string;
  handleItemClick: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ImageButton: React.FC<ImageButtonProps> = ({
  src,
  alt,
  text,
  style,
  handleItemClick,
  disabled = false,
}) => {
  
  const handleClick = () => {
    if (!disabled) {
      handleItemClick();
    }
  };

  return (
    <button 
      className="image-button" 
      style={style} 
      onClick={handleClick}
      disabled={disabled}
    >
      <img src={src} alt={alt} style={style} className="button-image" />
      <span className="button-text">{text}</span>
    </button>
  );
};

export default ImageButton;
