import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { KKPvpRanking, KKPvpSearch } from '@interfaces/types';
import { useAuth } from '../AuthContext';
import { kkPvpSearch } from '@api/account';
import InputComponent from './InputComponent';
import useMediaQuery from '@hooks/useMediaQuery';
import KKTicker from '@assets/KKTicker.webp';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from '@mui/material';

interface PaginatedTableProps {
  data: KKPvpRanking[];
  itemsPerPage: number;
}

const PaginatedTable: React.FC<PaginatedTableProps> = ({ data, itemsPerPage }) => {
  const { accountData } = useAuth();
  const isForDesktop = useMediaQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedData, setSearchedData] = useState<KKPvpSearch[]>([]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await kkPvpSearch(searchTerm);
        setSearchedData(response.data);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };
    if (searchTerm !== '') {
      fetchSearchResults();
    } else {
      setSearchedData([]); // Clear search results if search term is empty
    }
  }, [searchTerm]);

  const rankingMap = useMemo(() => new Map(data.map(item => [item.name, item])), [data]);

  const searchedDataInRanking: KKPvpRanking[] = useMemo(() => {
    const filteredData: KKPvpRanking[] = searchedData
      .filter(searchItem => rankingMap.has(searchItem.name))
      .map(searchItem => ({
        position: rankingMap.get(searchItem.name)!.position,
        name: searchItem.name,
        score: searchItem.score,
        prize: rankingMap.get(searchItem.name)!.prize,
      }));

    filteredData.sort((a, b) => {
      if (typeof a.position === "number" && typeof b.position === "number") {
        return a.position - b.position;
      }
      return 0;
    });

    return filteredData;
  }, [searchedData, rankingMap]);

  const dataToShow: KKPvpRanking[] = useMemo(() => {
    if (searchTerm === '') {
      return data;
    } else {
      return searchedDataInRanking.concat(
        searchedData
          .filter(rankingItem => !rankingMap.has(rankingItem.name))
          .map(rankingItem => ({
            position: "-",
            name: rankingItem.name,
            score: rankingItem.score,
            prize: "0",
          }))
      );
    }
  }, [data, searchTerm, searchedDataInRanking, rankingMap]);

  const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  }, []);

  const totalPages = Math.ceil(dataToShow.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = useMemo(() => dataToShow.slice(startIndex, endIndex), [dataToShow, startIndex, endIndex]);

  return (
    <div>
      {isForDesktop && <div style={{ marginBottom: "15px", width: "250px" }}>
        <InputComponent label='Search player' value={searchTerm} handleChange={handleSearchTermChange} />
      </div>}

      {!isForDesktop && <div style={{ marginBottom: "15px" }}><InputComponent label='Search player' value={searchTerm} handleChange={handleSearchTermChange} /></div>}

      <div>
        <Paper elevation={24}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "50px" }}>#</TableCell>
                  <TableCell style={{ width: "200px" }}>Player</TableCell>
                  <TableCell style={{ width: "100px" }}>Score</TableCell>
                  <TableCell style={{ width: "100px" }}>Prize</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.position}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.score}</TableCell>
                    <TableCell style={{display: "flex",  justifyContent: "flex-end", alignItems: "center" }}>{item.prize} <img style={{ height: "2rem" }} src={KKTicker} alt={`${KKTicker}`} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default PaginatedTable;
