import React, { createContext, useContext, ReactNode, useState } from 'react';
import { getAccount, login, login2fa, logout } from './api/account';
import { Account } from './interfaces/types';
import { toast } from 'react-toastify';

interface AuthContextProps {
  login: (ipAddress: string, email: string, password: string, isValidated: string) => Promise<any>;
  login2fa: (ipAddress: string, email: string, password: string, otp: string) => Promise<any>;
  logout: () => void;
  refreshAccountData: () => void;
  accountData: Account | null;
  userID: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [accountData, setAccountData] = useState<Account | null>(() => {
    const storedUserData = localStorage.getItem('user');

    try {
      return storedUserData ? JSON.parse(storedUserData) : null;
    } catch (error) {
      console.error('Error parsing stored user data:', error);
      return null;
    }
  });

  const [userID, setUserID] = useState<string | null>(() => {
    const storedUserData = localStorage.getItem('userID');
    return storedUserData ? storedUserData : null;
  });

  const loginUser = async (ipAddress: string, email: string, password: string, isValidated: string): Promise<any> => {
    try {
      const response = await login(ipAddress, email, password, isValidated);

      if (response.response === "loginSuccessFul") {
        setAccountData(response);
        setUserID(response.id);
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('userID', response.id);
        toast.success("Login succesful.")
      } else if (response.error === 'Unauthorized') {

      } else {
        console.error('Login failed. No data received.');
        toast.error("Login failed.")
      }
      return response;
    } catch (error) {
      console.error('Login failed:', error);
      toast.error("Login failed.")
      throw error;
    }
  };

  const loginUser2fa = async (ipAddress: string, email: string, password: string, otp: string): Promise<any> => {
    try {
      const response = await login2fa(ipAddress, email, password, otp);

      if (response.response === "loginSuccessFul") {
        setAccountData(response);
        setUserID(response.id);
        localStorage.setItem('user', JSON.stringify(response));
        localStorage.setItem('userID', response.id);
        toast.success("Login succesful.")
      } else {
        console.error('Login failed. No data received.');
        toast.error("Login failed.")
      }
      return response;
    } catch (error) {
      console.error('Login failed:', error);
      toast.error("Login failed.")
      throw error;
    }
  };

  const logoutUser = async (): Promise<void> => {
    try {
      if (accountData) {
        const response = await logout(accountData.id, accountData.hash, accountData.session);

        if (response.response === "success") {
          setAccountData(null);
          setUserID(null);
          localStorage.removeItem('user');
          localStorage.removeItem('userID');
          toast.success("Logout succesful.")
        }
      }
    } catch (error) {
      setAccountData(null);
      setUserID(null);
      localStorage.removeItem('user');
      localStorage.removeItem('userID');
      console.error('Logout failed:', error);
      toast.error("Logout failed.")
      throw error;
    }
  };

  const refreshAccountData = async (): Promise<void> => {
    try {
      if (userID) {
        const response = await getAccount(userID);

        const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
        storedUser.is2fa_enabled = response.is2fa_enabled;
        storedUser.blacklist = response.blacklist;
        storedUser.kyc_status = response.kyc_status;
        localStorage.setItem('user', JSON.stringify(storedUser));
        setAccountData(storedUser);

      }
    } catch (error) {
      console.error('Refresh account data failed', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ login: loginUser, login2fa: loginUser2fa, logout: logoutUser, refreshAccountData: refreshAccountData, accountData, userID }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
