import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Frame0 from '@assets/Frame0.webp';
import Frame1 from '@assets/Frame1.webp';
import Frame2 from '@assets/Frame2.webp';
import Frame3 from '@assets/Frame3.webp';
import Frame4 from '@assets/Frame4.webp';
import Frame5 from '@assets/Frame5.webp';
import Frame6 from '@assets/Frame6.webp';
import Frame7 from '@assets/Frame7.webp';
import { Link } from 'react-router-dom';
import './ImageSlider.scss';

const ImageSlider = () => {

  const settings = {
    infinite: true,
    arrows: false,
    dotsClass: "slick-dots slick-thumb",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  return (
    <div
      className='image-slider-wraper'
    >
      <Slider {...settings}>
        <Link to="https://www.youtube.com/watch?v=QQxNAEg4tpw" style={{ width: "100%", height: "100%" }}>
          <img src={Frame0} style={{width: "100%", height: "100%"}}/>
        </Link>

        <img src={Frame1} />
        <img src={Frame2} />
        <img src={Frame3} />
        <img src={Frame4} />
        <img src={Frame5} />
        <img src={Frame6} />
        <img src={Frame7} />
      </Slider>

    </div>

  );
};

export default ImageSlider;
