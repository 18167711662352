import { useCallback, useEffect, useMemo, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import { SelectOption, Transaction, TransactionLog } from '@interfaces/types';
import './CharacterDetails.scss';
import { useMyContext } from '../MyContext';
import { useAuth } from '../AuthContext';
import { getAssetsLog, getBlueGemsLog, getKktLog, getRedGemsLog, getScrapLog, transactions } from '@api/account';
import Loader from '@components/Loader';
import useMediaQuery from '@hooks/useMediaQuery';
import { toLocalDateTime } from '../helpers';
import InGameTransactionTable, { TableColumn } from '@components/InGameTransactionTable';
import { useLocation } from 'react-router-dom';
import TransactionTable from '@components/TransactionTable';
import { Paper, SelectChangeEvent } from '@mui/material';
import SelectComponent from '@components/SelectComponent';

const TransactionsLog = () => {
    const location = useLocation();

    const { activeLog } = location.state || {};

    const itemsPerPage = 15;
    const isForDesktop = useMediaQuery();
    const { characterData, itemsData } = useMyContext();

    const { accountData } = useAuth();
    const [kktWalletTransactions, setKktWalletTransactions] = useState<Transaction[]>([]);
    const [kktLog, setKktLog] = useState<TransactionLog[]>([]);
    const [blueGemsLog, setBlueGemsLog] = useState<TransactionLog[]>([]);
    const [scrapLog, setScrapLog] = useState<TransactionLog[]>([]);
    const [redGemsLog, setRedGemsLog] = useState<TransactionLog[]>([]);
    const [assetsLog, setAssetsLog] = useState<TransactionLog[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [hideAdverts, setHideAdverts] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [activeItem, setActiveItem] = useState<string>(activeLog ? activeLog : 'KKT wallet logs');

    const getCurrentItems = () => {
        switch (activeItem) {
            case 'KKT log':
                return kktLog;
            case 'Blue gem log':
                return blueGemsLog;
            case 'Asset log':
                return assetsLog;
            case 'Scrap log':
                return scrapLog;
            case 'Red gem log':
                return redGemsLog;
            default:
                return [];
        }
    };

    const currentItems = useMemo(() => getCurrentItems().slice(startIndex, endIndex), [getCurrentItems(), startIndex, endIndex]);

    const handleItemClick = (event: SelectChangeEvent<string>) => {
        setActiveItem(event.target.value);
    };

    const handlePageChange = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHideAdverts(event.target.checked);
    };

    const setLogState = (response: TransactionLog[]) => {
        setTotalPages(Math.ceil(response.length / itemsPerPage));

        switch (activeItem) {
            case 'KKT log':
                setKktLog(response);
                break;
            case 'Blue gem log':
                setBlueGemsLog(response);
                break;
            case 'Asset log':
                setAssetsLog(response);
                break;
            case 'Scrap log':
                setScrapLog(response);
                break;
            case "Red gem log":
                setRedGemsLog(response);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (accountData && activeItem) {
                try {
                    setIsLoading(true);

                    if (activeItem === 'KKT wallet logs') {
                        const response = await transactions(accountData.id)
                        setKktWalletTransactions(response.transactions);
                    }
                    else {
                        const response = await getLogData(activeItem, accountData.id);
                        setCurrentPage(1);
                        setLogState(response);
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        if (accountData && activeItem) {
            fetchData();
        };
    }, [activeItem, accountData, hideAdverts]);

    const listItems: SelectOption[] = [
        { value: 'KKT wallet logs', label: 'KKT Wallet Logs' },
        { value: 'KKT log', label: 'KKT Log' },
        { value: 'Blue gem log', label: 'Blue Gem Log' },
        { value: 'Asset log', label: 'Asset Log' },
        { value: 'Scrap log', label: 'Scrap Log' },
        { value: 'Red gem log', label: 'Red Gem Log' },
    ];

    const getLogData = async (logType: string, accountId: string): Promise<TransactionLog[]> => {
        try {
            switch (logType) {
                case 'KKT log':
                    return await getKktLog(accountId);
                case 'Blue gem log':
                    return await getBlueGemsLog(accountId);
                case 'Asset log':
                    return await getAssetsLog(accountId);
                case 'Scrap log':
                    return await getScrapLog(accountId);
                case "Red gem log":
                    return await getRedGemsLog(accountId, hideAdverts ? 1 : 0);
                default:
                    return [];
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };

    const getItemTitle = (type: string, itemId: string) => {
        if (type === "card") {
            const item = characterData?.find(x => x.id === itemId);
            return `${item?.Title} lvl. ${item?.Level}`
        } else if (type === "item") {
            const item = itemsData?.find(x => x.id === itemId);
            return item?.title;
        }
        return "";
    }

    const kktLogsColumns: TableColumn[] = [
        { key: 'datetime', label: 'Date', width: '200px', render: (item) => toLocalDateTime(item.datetime) },
        { key: 'amount', label: 'Amount', width: '100px' },
        { key: 'new_balance', label: 'New balance', width: '150px' },
        { key: 'reason', label: 'Reason' },
    ];

    const blueGemLogsColumns: TableColumn[] = [
        { key: 'datetime', label: 'Date', width: '200px', render: (item) => toLocalDateTime(item.datetime) },
        { key: 'amount', label: 'Amount', width: '60px' },
        { key: 'new_balance', label: 'New balance', width: '150px' },
        { key: 'reason', label: 'Reason' },
    ];

    const redGemLogsColumns: TableColumn[] = [
        { key: 'datetime', label: 'Date', width: '200px', render: (item) => toLocalDateTime(item.datetime) },
        { key: 'amount', label: 'Amount', width: '60px' },
        { key: 'new_balance', label: 'New balance', width: '150px' },
        { key: 'reason', label: 'Reason' },
    ];

    const assetLogsColumns: TableColumn[] = [
        { key: 'datetime', label: 'Date', width: '200px', render: (item) => toLocalDateTime(item.datetime) },
        { key: 'amount', label: 'Amount', width: '60px' },
        { key: 'new_balance', label: 'New balance', width: '150px' },
        { key: 'item', label: 'Item', width: '250px', render: (item) => getItemTitle(item.type, item.asset_id) },
        { key: 'type', label: 'Type', width: '150px' },
        { key: 'reason', label: 'Reason' },
    ];

    const scalpLogsColumns: TableColumn[] = [
        { key: 'datetime', label: 'Date', width: '200px', render: (item) => toLocalDateTime(item.datetime) },
        { key: 'amount', label: 'Amount', width: '60px' },
        { key: 'new_balance', label: 'New balance', width: '150px' },
        { key: 'reason', label: 'Reason' },
    ];

    const forDesktopRender = () => (
        <div className='table-wrapper' style={{ justifyContent: "flex-start", padding: "15px" }}>
            <div style={{ width: "200px" }}>
                <SelectComponent label={'Select log'} items={listItems} value={activeItem} handleChange={handleItemClick} />
            </div>
        </div>);

    const forMobileRender = () => (<>
        <div style={{ display: "flex", width: "80%" }}>
            <SelectComponent label={'Select log'} items={listItems} value={activeItem} handleChange={handleItemClick} />
        </div>
    </>
    )

    const renderLogTable = () => {
        switch (activeItem) {
            case "KKT wallet logs":
                return <TransactionTable transactions={kktWalletTransactions ? kktWalletTransactions : []} />
            case "KKT log":
                return <InGameTransactionTable columns={kktLogsColumns} data={currentItems} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />;
            case "Blue gem log":
                return <InGameTransactionTable columns={blueGemLogsColumns} data={currentItems} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />;
            case "Asset log":
                return <InGameTransactionTable columns={assetLogsColumns} data={currentItems} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />;
            case "Scrap log":
                return <InGameTransactionTable columns={scalpLogsColumns} data={currentItems} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />;
            case 'Red gem log':
                return <InGameTransactionTable columns={redGemLogsColumns} data={currentItems} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />;
            default:
                return null;
        }
    };

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title'>Transactions Log</label>
            </div>
            {isForDesktop && forDesktopRender()}
            {!isForDesktop && forMobileRender()}

            {isLoading
                ? <Loader isLoading={isLoading} />
                : (<>
                    {activeItem === "Red gem log" && <div style={{ width: "80%", display: "flex", alignItems: 'flex-start', marginBottom: "20px" }}>
                        <label>
                            <input
                                type="checkbox"
                                checked={hideAdverts as unknown as boolean}
                                onChange={handleCheckboxChange}
                                style={{ marginRight: "10px" }}
                            />
                            Hide adverts
                        </label>
                    </div>}
                    {renderLogTable()}
                </>)}
        </div>
    );


};

export default BackgroundTemplate(TransactionsLog);