import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './Shop.scss';
import redGemChest1 from '@assets/redGemChest1.webp';
import redGemChest2 from '@assets/redGemChest2.webp';
import redGemChest3 from '@assets/redGemChest3.webp';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { buyRedGems, getKktValueInDollars, getMakerAuctions } from '@api/account';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PurchaseRedGemsModal from '@components/PurchaseRedGemsModal';
import RedGemItem from '@components/RedGemItem';
import Accordion from '@components/Accordion';
import { MarketItemDataNew } from '@interfaces/types';
import { fillItemsBuy } from '../helpers/marketItems';
import { useMyContext } from '../MyContext';
import MarketItem from '@components/MarketItem';

export interface RedGemShopModel {
    option: string;
    amount: string;
    priceInKkt: string;
    priceInDollar: string;
    image: string;
}

const RedGemsShop: React.FC = () => {

    const isForDesktop = useMediaQuery();
    const { accountData, logout } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [playerCardAssets, setPlayerCardAssets] = useState<MarketItemDataNew[]>([]);
    const { characterData, itemsData } = useMyContext();

    const openModal = (): void => {
        setIsOpen(true);
    };

    const closeModal = (): void => {
        setIsOpen(false);
    };

    const [buyingOptions, setBuyingOptions] = useState<RedGemShopModel[]>([
        {
            option: "1",
            amount: "100",
            priceInKkt: "0",
            priceInDollar: "1",
            image: redGemChest1
        },
        {
            option: "2",
            amount: "500",
            priceInKkt: "0",
            priceInDollar: "4.5",
            image: redGemChest2
        },
        {
            option: "3",
            amount: "1000",
            priceInKkt: "0",
            priceInDollar: "8",
            image: redGemChest3
        }
    ]);

    const [selectedOption, setSelectedOption] = useState<RedGemShopModel | null>(null);


    const calculatePriceInKkt = (kktValue: number) => {
        const updatedOptions = buyingOptions.map(option => ({
            ...option,
            priceInKkt: (parseFloat(option.priceInDollar) / kktValue).toFixed(2)
        }));
        setBuyingOptions(updatedOptions);
    };

    const buy = async (amount: string) => {
        if (accountData) {
            closeModal();
            try {
                const response = await buyRedGems(accountData.id, accountData.session, amount);
                if (response.response === "success") {
                    toast.success("Reg gems purchased.")
                } else if (response.response === "session and hash dont match with server") {
                    toast.error("Oops! It looks like you need to log in again to complete your purchase.");
                    logout();
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    useEffect(() => {
        const kktValueInDollars = async () => {
            if (accountData) {
                try {
                    const response = await getKktValueInDollars(accountData ? accountData.id : "");
                    calculatePriceInKkt(parseFloat(response.kkt_value));

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        kktValueInDollars();
    }, []);

    useEffect(() => {
        const getPlayersAssetsData = async () => {
            if (accountData) {
                try {
                    if (accountData) {
                        const response = await getMakerAuctions(accountData ? accountData.id : "", "", "");
                        const items = fillItemsBuy(response, itemsData);
                        setPlayerCardAssets([...items]);
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        getPlayersAssetsData();

    }, [])

    const generateAccordionItems = () => [
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Red Gems
                    </label>
                </div>
            ),
            content: (
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", width: "100%" }}>
                    {buyingOptions.map(option => (
                        <div onClick={() => {
                            openModal();
                            setSelectedOption(option);
                        }}>
                            <RedGemItem bla={option} />
                        </div>

                    ))}
                </div>
            ),
        },
        {
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        In Game Assets
                    </label>
                </div>
            ),
            content: (
                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", width: "100%" }}>
                    {playerCardAssets.length > 0 && playerCardAssets.map((x, index) => (
                        <MarketItem key={index} item={x} isCardItem={true} pricePerItem={x.price ? x.price : 0} isForSale={false} />
                    ))}
                </div>
            ),
        },
    ]

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title'>Kingdom Karnage Shop</label>
            </div>
            {selectedOption && (
                <PurchaseRedGemsModal
                    isOpen={isOpen}
                    onBuy={() => buy(selectedOption.option)}
                    buyingOption={selectedOption}
                    onClose={() => {
                        closeModal();
                        setSelectedOption(null);
                    }}
                />
            )}

            <Accordion items={generateAccordionItems()} activeOne={0} />
        </div>
    );
};


export default BackgroundTemplate(RedGemsShop);