import { ReactNode, useEffect, useRef, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import CardList from '@components/CardList';
import { CharacterData } from '@interfaces/types';
import IconCommonCard from '@assets/IconCommonCard.webp';
import IconUncommonCard from '@assets/IconUncommonCard.webp';
import IconRareCard from '@assets/IconRareCard.webp';
import icnfilter from '@assets/icnfilter.png';
import IconEpicCard from '@assets/IconEpicCard.webp';
import IconLegendaryCard from '@assets/IconLegendaryCard.webp';
import './CardLibrary.scss';
import '../components/Accordion.scss';
import Accordion from '@components/Accordion';
import { abilityOptions, cardLevelOptions, rarityOptions } from '@constants/constants';
import { useMyContext } from '../MyContext';
import useMediaQuery from '@hooks/useMediaQuery';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent } from '@mui/material';
import SelectComponent from '@components/SelectComponent';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            width: "100%"
        }
    }
}));

const CardLibrary = () => {

    const isForDesktop = useMediaQuery();
    const { characterData } = useMyContext();

    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [selectedRarityFilterValue, setSelectedRarityFilterValue] = useState<string>('');
    const [selectedCardLevelFilterValue, setselectedCardLevelFilterValue] = useState<string>('1');
    const [selectedAbilityOptionFilterValue, setSelectedAbilityOptionFilterValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<CharacterData[]>([]);

    const filterPopupRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e: MouseEvent) => {
            if (filterPopupRef.current && !(filterPopupRef.current as HTMLElement).contains(e.target as HTMLElement | null)) {
                setIsFilterOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleSelectChange = ((event: SelectChangeEvent<string>, child: ReactNode) => {
        setSelectedRarityFilterValue(event.target.value);
    });

    const handleCardLevelSelectChange = ((event: SelectChangeEvent<string>, child: ReactNode) => {
        setselectedCardLevelFilterValue(event.target.value);
    });

    const handleAbilitySelectChange = ((event: SelectChangeEvent<string>, child: ReactNode) => {
        setSelectedAbilityOptionFilterValue(event.target.value);
    });

    const handleFilterToggle = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const sortedData = (data: CharacterData[]) => {
        return data.slice().sort((a, b) => {
            const titleA = a.Title.toLowerCase();
            const titleB = b.Title.toLowerCase();
            if (titleA < titleB) {
              return -1;
            }
            if (titleA > titleB) {
              return 1;
            }
            return 0;
          });
    }

    const handleFilterButtonClick = () => {
        setIsFilter(true);
        setIsFilterOpen(false);

        const filteredData = characterData && characterData.filter((character) => {
            if (selectedCardLevelFilterValue !== '' && character.Level !== selectedCardLevelFilterValue) {
                return false;
            }

            if (selectedRarityFilterValue !== '' && character.Quality !== selectedRarityFilterValue) {
                return false;
            }
            if (selectedAbilityOptionFilterValue !== '') {
                if (
                    !character.Ability1.includes(selectedAbilityOptionFilterValue) &&
                    !character.Ability2.includes(selectedAbilityOptionFilterValue) &&
                    !character.Ability3.includes(selectedAbilityOptionFilterValue)
                ) {
                    return false;
                }
            }
            return true;
        });

        setFilteredData(filteredData ? sortedData(filteredData) : []);
    };

    const handleClearFilterButtonClick = () => {
        setIsFilter(false);
        setIsFilterOpen(false);
        setSelectedRarityFilterValue('');
        setselectedCardLevelFilterValue('');
        setSelectedAbilityOptionFilterValue('');
        setFilteredData(characterData ? sortedData(characterData) : []);
    };

    const generateAccordionItems = (data: CharacterData[]) => {
        const qualityTypes = ["Common", "Uncommon", "Rare", "Epic", "Legendary"];

        if (!isFilter) {
            return qualityTypes.map((qualityType, index) => {
                let qualityCard;

                switch (qualityType) {
                    case "Common":
                        qualityCard = IconCommonCard;
                        break;
                    case "Uncommon":
                        qualityCard = IconUncommonCard;
                        break;
                    case "Rare":
                        qualityCard = IconRareCard;
                        break;
                    case "Epic":
                        qualityCard = IconEpicCard;
                        break;
                    case "Legendary":
                        qualityCard = IconLegendaryCard;
                        break;
                    default:
                        break;
                }

                return ({
                    title: (
                        <div className='card-library-accordion-title-wrapper'>
                            <img className='accordion-title-image' src={qualityCard} alt={`${qualityType}`} />
                            <label className='accordion-title-label'>
                                {`${qualityType}`}
                            </label>
                        </div>
                    ),
                    content: (
                        <CardList
                            CharacterData={sortedData(data.filter(
                                (character) => character.Level === '1' && character.Quality === qualityType
                            ))}
                        />
                    ),
                    key: index,
                });
            });
        }
        return ([{
            title: (
                <div className='card-library-accordion-title-wrapper'>
                    <label className='accordion-title-label'>
                        Filtered cards
                    </label>
                </div>
            ),
            content: (
                <>
                    {filteredData.length > 0 && <CardList CharacterData={filteredData} />}
                    {filteredData.length === 0 && <div style={{ height: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}><label style={{ fontFamily: "Ruda", fontSize: "large" }}>No items!</label></div>}
                </>

            )
        }]);
    };

    const items = generateAccordionItems(isFilter ? filteredData : (characterData ? characterData : []));

    const renderFilters = () => (
        <>
            <SelectComponent label={'Card level'} items={cardLevelOptions} value={selectedCardLevelFilterValue} handleChange={handleCardLevelSelectChange} />
            <SelectComponent label={'Card rarity'} items={rarityOptions} value={selectedRarityFilterValue} handleChange={handleSelectChange} />
            <SelectComponent label={'Card ability'} items={abilityOptions} value={selectedAbilityOptionFilterValue} handleChange={handleAbilitySelectChange} />
        </>
    );

    const renderFilterSectionForDesktop = () => (
        <div style={{ display: "flex", width: "700px", flexDirection: "column", justifyContent: "center", padding: "10px" }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                <div style={{ width: "200px" }}>
                    <SelectComponent label={'Card level'} items={cardLevelOptions} value={selectedCardLevelFilterValue} handleChange={handleCardLevelSelectChange} />
                </div>
                <div style={{ width: "200px" }}>
                    <SelectComponent label={'Card rarity'} items={rarityOptions} value={selectedRarityFilterValue} handleChange={handleSelectChange} />
                </div>
                <div style={{ width: "200px" }}>
                    <SelectComponent label={'Card ability'} items={abilityOptions} value={selectedAbilityOptionFilterValue} handleChange={handleAbilitySelectChange} />
                </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                <DialogActions>
                    <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                    <Button onClick={handleFilterButtonClick}>Filter</Button>
                </DialogActions>
            </div>
        </div>
    );

    const classes = useStyles();

    const renderOpenedFilterForMobile = () => (
        <Dialog
            className={classes.root}
            open={isFilterOpen}
            onClose={() => handleFilterToggle()}
            aria-labelledby="alert-dialog-title"
            style={{ width: "100%" }}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Filter Card Library"}
            </DialogTitle>
            <DialogContent>
                {renderFilters()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClearFilterButtonClick}>Clear</Button>
                <Button onClick={handleFilterButtonClick}>Filter</Button>
            </DialogActions>
        </Dialog>
    );


    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title' >Card Library</label>
            </div>
            {!isForDesktop && (
                <div style={{
                    width: "90%", display: "flex", flexDirection: "row-reverse", position: "absolute",
                    top: "105px",
                    right: "20px"
                }}>
                    <button onClick={() => handleFilterToggle()}>
                        <img src={icnfilter} alt='icnfilter' />
                    </button>
                </div>
            )}

            {!isForDesktop && isFilterOpen && renderOpenedFilterForMobile()}
            {isForDesktop && renderFilterSectionForDesktop()}
            <Accordion items={items} activeOne={filteredData.length === 0 ? null: 0}/>
        </div>

    );
};

export default BackgroundTemplate(CardLibrary);
