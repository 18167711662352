import React, { useEffect, useState } from 'react';
import BackgroundTemplate from '../hoc/BackgroundTemplate';
import './Shop.scss';
import MarketSell from './MarketSell';
import MarketListed from './MarketListed';
import MarketBuy from './MarketBuy';
import useMediaQuery from '@hooks/useMediaQuery';
import { useAuth } from '../AuthContext';
import { getKktBalance } from '../api/account';
import KKTicker from '@assets/KKTicker.webp';
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Shop: React.FC = () => {

    const isForDesktop = useMediaQuery();
    const location = useLocation();
    const { escapedTitle, Quality, GameReference } = location.state || {};
    const { accountData, userID, logout } = useAuth();
    const [activeItem, setActiveItem] = useState<string | null>(() => {
        if (escapedTitle === undefined)
            return localStorage.getItem('activeItem') || 'Buy';

        return 'Buy';
    });

    const handleItemClick = (item: string) => {
        setActiveItem(item);
        localStorage.setItem('activeItem', item);
    };

    const listItems: string[] = ['Buy', 'Sell', 'Listed'];
    const [kktBalance, setKktBalance] = useState<any>();

    const fetchData = async () => {
        try {
            if (accountData) {
                const [balanceResponse] = await Promise.all([
                    getKktBalance(accountData ? accountData.id : ""),
                ]);

                setKktBalance(balanceResponse.balance);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getCharacterFromCardLibrary = () => {
        if (escapedTitle !== undefined) {
            const decodedFileName = decodeURIComponent(escapedTitle ? escapedTitle : "");
            return decodedFileName;
        }
        return undefined;
    }

    return (
        <div className="page-content">
            <div className='page-title-container'>
                <label className='page-title'>Market</label>
            </div>

            {isForDesktop && <Paper elevation={24} style={{ display: "flex", width: "80%", justifyContent: "space-between", paddingLeft: "15px" }}>
                <ul style={{ display: 'flex', listStyle: 'none', lineHeight: '40px', alignItems: "center" }}>
                    {listItems.map((item) => (
                        <li
                            key={item}
                            style={{
                                padding: '0 30px',
                                background: activeItem === item ? '#242958' : 'none',
                                border: activeItem === item ? '1px solid #0091BF' : 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleItemClick(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
                <div>
                    <List dense>
                        <ListItem>
                            <ListItemIcon>
                                <img style={{ width: "2.2rem" }} src={KKTicker} alt="KKTicker" />
                            </ListItemIcon>
                            <ListItemText primary="Game balance" secondary={kktBalance} />
                        </ListItem>
                    </List>
                </div>
            </Paper>}

            <div style={{ display: "flex", flexWrap: "wrap", width: "80%", marginTop: "20px" }}>
                {activeItem === "Buy" && <MarketBuy characterFromCardLibrary={getCharacterFromCardLibrary()} />}
                {activeItem === "Sell" && <MarketSell />}
                {activeItem === "Listed" && <MarketListed />}
            </div>

            {!isForDesktop && <div className='shop-mobile-footer'>
                <Paper elevation={24} className='shop-mobile-footer-buttons-wraper'>
                    {listItems.map(item => (
                        <Button style={{ padding: '0 30px', fontFamily: activeItem === item ? "PassionOneRegular" : "Ruda", fontSize: activeItem === item ? "20px" : "15px" }} key={item} onClick={() => handleItemClick(item)}>
                            {item}
                        </Button>
                    ))}
                </Paper>
            </div>}

        </div>
    );
};


export default BackgroundTemplate(Shop);