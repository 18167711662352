import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import { Button, ListItemButton, ListItemIcon, Paper } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import LogoTextOnly from '@assets/LogoTextOnly.webp';
import { useAuth } from '../AuthContext';
import { useAccount, useBalance } from 'wagmi';
import { getAccount, getKktBalance, getKktValueInDollars } from '@api/account';
import KKTicker from '@assets/KKTicker.webp';
import gem from '../assets/gem.webp';
import diamondIcon from '../assets/diamondIcon.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useNetwork, useSwitchNetwork } from 'wagmi';

interface WalletDrawerProps {
    isOpen: boolean;
    toggleDrawer: (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#26282F",
            backgroundImage: "none",
            boxShadow: "none",
            height: "100%"
        },
        // "& .MuiList-root": {
        //     height: "100%"
        // },
        "& .MuiDrawer-paper": {
            backgroundColor: "#26282F",
            backgroundImage: "none"
        }
    }
}));

const WalletDrawer: React.FC<WalletDrawerProps> = ({ isOpen, toggleDrawer }) => {
    const { accountData, userID, logout } = useAuth();
    const { address } = useAccount();

    const [kktBalance, setKktBalance] = useState<any>();
    const [redGemsBalance, setRedGemsBalance] = useState<string>("");
    const [diamondsBalance, setDiamondsBalance] = useState<string>("");
    const [kktInDollars, setKktInDollars] = useState<string>("");
    const { chain } = useNetwork();
  
    const { switchNetwork } = useSwitchNetwork();

    const { data } = useBalance({
        address: address,
        token: '0xe64017bdacbe7dfc84886c3704a26d566e7550de',
    })

    const fetchData = async () => {
        try {
            if (accountData) {
                const [balanceResponse, accountResponse, kktInDollarsResponse] = await Promise.all([
                    getKktBalance(accountData ? accountData.id : ""),
                    getAccount(userID ? userID : ""),
                    getKktValueInDollars(accountData.id)
                ]);

                setKktBalance(balanceResponse.balance);
                setRedGemsBalance(accountResponse.red_gems)
                setDiamondsBalance(accountResponse.diamonds)
                setKktInDollars(kktInDollarsResponse.kkt_value);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    };

    useEffect(() => {
        fetchData();
    }, [isOpen]);

    const logoutUser = () => {
        logout();
    }

    const list = () => (
        <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            style={{ display: "flex", flexDirection: "column" }}
        >
            <List dense>
                <Toolbar style={{ height: "70px" }} >
                    <img src={LogoTextOnly} alt='LogoTextOnly' style={{ width: "110px" }} />
                </Toolbar>

                {chain != undefined && chain?.id != 56 && <ListItem>
                    <Button variant="text" color="error" onClick={() => switchNetwork && switchNetwork(56)}>
                    Switch to BNB Smart Chain
                    </Button>
                </ListItem>}

                <ListItem>
                    <ListItemButton>
                        <w3m-button balance='hide' />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton component={Link} to='/account'>
                        <ListItemIcon>
                            <img style={{ width: "2.2rem" }} src={KKTicker} alt="KKTicker" />
                        </ListItemIcon>
                        <ListItemText primary={"Wallet balance"} secondary={showWalletBalance()} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton component={Link} to='/transactions-log' state={{ activeLog: 'KKT log' }}>
                        <ListItemIcon>
                            <img style={{ width: "2.2rem" }} src={KKTicker} alt="KKTicker" />
                        </ListItemIcon>
                        <ListItemText primary="Game balance" secondary={showGameBalance()} />
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton component={Link} to='/transactions-log' state={{ activeLog: 'Red gem log' }}>
                        <ListItemIcon>
                            <img style={{ width: "2rem" }} src={gem} alt="Gem" />
                        </ListItemIcon>
                        <ListItemText primary="Gem balance" secondary={redGemsBalance}/>
                    </ListItemButton>
                </ListItem>
                <ListItem>
                    <ListItemButton component={Link} to='/transactions-log' state={{ activeLog: 'Blue gem log' }}>
                        <ListItemIcon>
                            <img style={{ width: "2rem" }} src={diamondIcon} alt="diamondIcon" />
                        </ListItemIcon>
                        <ListItemText primary="Diamond balance" secondary={diamondsBalance} />
                    </ListItemButton>
                </ListItem>
            </List>
            <ListItem style={{ bottom: 0, position: "absolute" }}>
                <ListItemButton component={Link} to="/" onClick={logoutUser}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faRightFromBracket} color='white' size='2x' />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItemButton>
            </ListItem>
        </div >
    );

    const classes = useStyles();
    return (
        <div>
            <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)} className={classes.root}>
                <Paper style={{ minWidth: "300px" }}>
                    {list()}
                </Paper>
            </Drawer>
        </div>
    );

    function showGameBalance(): React.ReactNode {
        const balance = kktBalance as unknown as number;
        const inDollars = kktInDollars as unknown as number;
        const calculation = balance * inDollars;
        const rounding = calculation.toFixed(2)
        return <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{kktBalance}</span>
            <span style={{ fontSize: "10px", fontWeight: "normal", color: "rgba(255, 255, 255, 0.7)" }}>${rounding}</span>
        </div>;
    }

    function showWalletBalance(): React.ReactNode {
        const balance = data?.formatted ? parseFloat(data?.formatted).toFixed(2) as unknown as number : 0;
        const inDollars = kktInDollars as unknown as number;
        const calculation = balance * inDollars;
        const rounding = calculation.toFixed(2)
        return <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{data?.formatted ? balance : "Connect wallet!"}</span>
            <span style={{ fontSize: "10px", fontWeight: "normal", color: "rgba(255, 255, 255, 0.7)" }}>{data?.formatted ? "$" : ""}{data?.formatted ? rounding : ""}</span>
        </div>;
    }
};

export default WalletDrawer;
